import { store } from "../../store/index";

export const convertToPhoneMask = (value: string, state: any, key: string) => {
  let replace = value.replace(/[^\d.]+/g, "");
  if (replace.length > 0) {
    if (replace.substring(0, 3) === "358") {
      state[key] = "+358";
      replace =
        replace.substring(3, 6) +
        "-" +
        replace.substring(6, 9) +
        "-" +
        replace.substring(9);
    } else if (replace.substring(0, 2) === "46") {
      state[key] = "+46";
      replace =
        replace.substring(2, 5) +
        "-" +
        replace.substring(5, 8) +
        "-" +
        replace.substring(8);
    } else {
      if (state[key] === "+46") {
        state[key] = "+46";
        replace =
          replace.substring(0, 3) +
          "-" +
          replace.substring(3, 6) +
          "-" +
          replace.substring(6);
      } else if (state[key] === "+358") {
        state[key] = "+358";
        replace =
          replace.substring(0, 3) +
          "-" +
          replace.substring(3, 6) +
          "-" +
          replace.substring(6);
      } else {
        state[key] = "+46";
        replace =
          replace.substring(0, 3) +
          "-" +
          replace.substring(3, 6) +
          "-" +
          replace.substring(6);
      }
    }
    return replace;
  } else {
    if (store.getters.getUserUiLang === "Swedish") state[key] = "+46";
    else state[key] = "+358";
    return "";
  }
};
