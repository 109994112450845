import * as yup from "yup";
// bank_city: yup.string().notRequired();
// .when(['bank_city', 'bank_name', 'correspond_account', 'number'], {
//     is: (bank_city: string, bank_name: string, correspond_account: string, number: string) => {
//         if(bank_city?.length > 0 || bank_name?.length > 0 || correspond_account?.length > 0 || number?.length > 0) {
//             return true
//         }
//     },
//     then: (rule) => rule.min(2).max(55).label('Bank city'),
// }),
export const BankDataComponentValidation = yup.object().shape(
  {
    bank_city: yup
      .string()
      .notRequired()
      .when("bank_city", {
        is: (value: string) => value?.length > 0,
        then: (rule) => rule.min(2).max(55).label("Bank name"),
      }),
    bank_name: yup
      .string()
      .notRequired()
      .when("correspond_account", {
        is: (value: string) => value?.length > 0,
        then: (rule) => rule.min(2).max(55).label("Bank name"),
      }),
    correspond_account: yup
      .string()
      .notRequired()
      .when("correspond_account", {
        is: (value: string) => value?.length > 0,
        then: (rule) => rule.min(6).max(55).label("Corr address"),
      }),
    number: yup
      .string()
      .notRequired()
      .when("number", {
        is: (value: string) => value?.length > 0,
        then: (rule) => rule.min(4).max(55).label("Bank account number"),
      }),
  },
  [
    ["bank_name", "bank_name"],
    ["correspond_account", "correspond_account"],
    ["bank_city", "bank_city"],
    ["number", "number"],
  ]
);
