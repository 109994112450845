import { RegistrationForm } from "@/components/registration/type";
import { ErrorLoginType } from "../../components/login/type";
import { store } from "../../store/index";

type Ref<T> = { value: T };

export const loginValidation = (
  dataInput: Ref<RegistrationForm>,
  error?: Ref<ErrorLoginType>
) => {
  if(error) {
    if (!dataInput.value.email || !/^\S+@\S+\.\S+$/.test(dataInput.value.email as string)) error.value.login = true;
    else error.value.login = false;
    if (!dataInput.value.password) error.value.password = true;
    else error.value.password = false;
    if (!error.value.login && !error.value.password)
      return store.dispatch("userLogin", dataInput.value);
  }else {
    return store.dispatch("userLogin", dataInput.value);
  }
};
