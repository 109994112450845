import {
  AllFilterOrders,
  CreateOrdersType,
  IOrders,
  IOrdersAndUpdateDetails,
  IOrdersDetails,
  IOrdersStatus,
  LoadingOrdersPayload,
} from "./type";
import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { IResultDataSearch, CARS, TRUCK } from "../Search/type";
import { IStore } from "../../types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { store } from "@/store";
import { Module } from "vuex";
import { computed, ref } from "vue";
import { Locations } from "../Locations/type";

export const OrdersTypeOperation: Module<IOrders, IStore> = {
  state: {
    count: 0,
    page: "100",
    selectOrders: null,
    next: null,
    previous: null,
    results: [],
    isLoading: true,
    isError: false,
    selectView: "Orders",
    selectFilter: "Cars",
    status: {
      count: 0,
      next: null,
      previous: null,
      results: [],
      isLoading: true,
      isError: false,
    },
    orders_filter: {},
  },
  getters: {
    getPageOrdersFilter(state) {
      return state.page;
    },
    getOrdersNotFilter(state) {
      return state;
    },
    getOrderDetailsById(state) {
      if (!state.selectOrders) return;

      const orderDetails = state.results.filter(
        (order) => order.id === state.selectOrders
      );

      if (orderDetails?.length <= 0) return;

      return orderDetails[0]?.details?.results;
    },
    getAllOrderStatus(state) {
      return {
        status: state.status.results.map((status) =>
          status.status_name.toString()
        ),
        isLoading: state.isLoading,
      };
    },
    getAllCustomersInOrders(state) {
      return Array.from(
        new Set(state.results.map((result) => result.customer))
      );
    },
    getSelectView(state) {
      return state.selectView;
    },
    getSelectFilter(state) {
      return state.selectFilter;
    },
    getOrdersFilter(state) {
      return state.orders_filter;
    },
  },
  mutations: {
    updateOrdersFilter(state, payload: AllFilterOrders) {
      state.orders_filter = payload;
    },
    updatePageOrdersFilter(state, payload: string) {
      state.page = payload;
    },
    updateSelectOrdersStateView(state, payload: string) {
      state.selectView = payload;
    },
    updateSelectOrdersStateSelectFilter(state, payload: string) {
      state.selectFilter = payload;
    },
    updateOrders(state, payload: IOrdersAndUpdateDetails) {
      state.count = payload.count;
      state.next = payload.next;
      state.previous = payload.previous;
      state.results = payload.results;
      state.isLoading = payload.isLoading;

      if (payload?.updateOrdersDetails)
        payload.results.forEach((order) => {
          store.dispatch("getOrderDetails", order.id);
        });
    },
    updateOrdersDetails(
      state,
      payload: { data: IOrdersDetails; isLoading: boolean; updateOrder: number }
    ) {
      payload.data.results.forEach((details) => {
        details.tyre_pcr_details_loading = true;
        store
          .dispatch("getCarsInOrderDetails", details.tyre_pcr)
          .then(
            (results: {
              data: IResultDataSearch<CARS>;
              isLoading: boolean;
            }) => {
              if (!results) {
                details.tyre_pcr_details_loading = false;
                return;
              }

              details.tyre_pcr_details_loading = results.isLoading;
              details.tyre_pcr_details = results.data;
            }
          )
          .catch(() => {
            details.tyre_pcr_details_loading = false;
          });
        details.tyre_tbr_details_loading = true;
        store
          .dispatch("getTruckInOrderDetails", details.tyre_tbr)
          .then(
            (results: {
              data: IResultDataSearch<TRUCK>;
              isLoading: boolean;
            }) => {
              if (!results) {
                details.tyre_tbr_details_loading = false;
                return;
              }

              details.tyre_tbr_details_loading = results.isLoading;
              details.tyre_tbr_details = results.data;
            }
          )
          .catch(() => {
            details.tyre_tbr_details_loading = false;
          });
      });

      state.results = state.results.map((_state) => {
        if (_state.id === payload.updateOrder) {
          _state.details = payload.data;
          _state.detailsIsloading = payload.isLoading;
        }
        return _state;
      });
    },
    updateOrdersStatus(state, payload: IOrdersStatus) {
      state.status.count = payload.count;
      state.status.next = payload.next;
      state.status.previous = payload.previous;
      state.status.isLoading = payload.isLoading;
      state.status.results = payload.results;
    },
  },
  actions: {
    async createNewOrders(store, payload) {
      return new Promise((resolve, reject) => {
        const selectAddressCart = ref(
          computed<Locations>(() => store.getters.getSelectedAddress)
        );
        const userId = store.getters.getUserData?.id;

        if (!userId) return;

        const shipment_location: CreateOrdersType = {
          shipment_location: {
            full_address: payload.address,
            status: "UNKNOWN",
          },
        };

        if (
          selectAddressCart?.value &&
          payload.address === selectAddressCart?.value?.full_address
        ) {
          shipment_location.shipment_location.status = "OK";
          shipment_location.shipment_location.geo_place_id = selectAddressCart
            .value?.geo_place as number;
          shipment_location.shipment_location.city =
            selectAddressCart.value?.geo_place_name;
          shipment_location.shipment_location.postal_code =
            selectAddressCart.value?.postal_code;
          shipment_location.shipment_location.house =
            selectAddressCart.value?.house;
          shipment_location.shipment_location.street =
            selectAddressCart.value?.street;
          shipment_location.shipment_location.geo_latitude =
            selectAddressCart.value?.geo_latitude;
          shipment_location.shipment_location.geo_longitude =
            selectAddressCart.value?.geo_longitude;
        }

        useFetch<IResultDataSearch<CARS>, IUseFetchDetailError>(
          process.env.VUE_APP_SERVER + `api/users/${userId}/create_order/`,
          "POST",
          true,
          shipment_location
        ).then(({ data, isError, errorMessage, statusError }) => {
          if (!isError.value) {
            resolve(data.value);
          } else {
            reject({
              message: errorMessage.value,
              status: statusError.value,
            });
          }
        });
      });
    },
    async getCarsInOrderDetails({ commit }, payload: number) {
      if (!payload) return false;

      const { isError, data, isLoading } = await useFetch<
        IResultDataSearch<TRUCK>,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/tyre_pcr/" + payload + "/",
        "GET",
        true
      );

      if (!isError.value && data.value)
        return { data: data.value, isLoading: isLoading.value };
    },
    async getTruckInOrderDetails({ commit }, payload: number) {
      if (!payload) return false;

      const { isError, data, isLoading } = await useFetch<
        IResultDataSearch<TRUCK>,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/tyre_tbr/" + payload + "/",
        "GET",
        true
      );

      if (!isError.value && data.value)
        return { data: data.value, isLoading: isLoading.value };
    },
    async getOrdersInLink({ commit }, payload: string) {
      const { isError, data, isLoading } = await useFetch<
        IOrders,
        IUseFetchDetailError
      >(payload, "GET", true);

      if (!isError.value && data.value)
        commit("updateOrders", { ...data.value, isLoading: isLoading.value });
    },
    async getOrders({ commit }, payload: LoadingOrdersPayload) {
      if (payload.updateStatus) {
        const { isError, data, isLoading } = await useFetch<
          IOrdersStatus,
          IUseFetchDetailError
        >(process.env.VUE_APP_SERVER + "api/order_status/", "GET", true);

        if (!isError.value && data.value)
          commit("updateOrdersStatus", {
            ...data.value,
            isLoading: isLoading.value,
          });
      }

      if (payload.url) {
        const { isError, data, isLoading } = await useFetch<
          IOrders,
          IUseFetchDetailError
        >(payload.url, "GET", true);

        if (!isError.value && data.value)
          commit("updateOrders", {
            ...data.value,
            isLoading: isLoading.value,
            updateOrdersDetails: payload.updateOrdersDetails || false,
          });
      } else {
        const { isError, data, isLoading } = await useFetch<
          IOrders,
          IUseFetchDetailError
        >(process.env.VUE_APP_SERVER + "api/orders/", "GET", true);

        if (!isError.value && data.value)
          commit("updateOrders", {
            ...data.value,
            isLoading: isLoading.value,
            updateOrdersDetails: payload.updateOrdersDetails || false,
          });
      }
    },
    async getOrderDetails({ commit }, payload: number) {
      store.state.OrdersTypeOperation.selectOrders = payload;

      if (
        store.getters.getOrderDetailsById &&
        store.getters.getOrderDetailsById?.length > 0
      )
        return;

      const { data, isLoading } = await useFetch<
        IOrdersDetails,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/order_details/?order__id=" + payload,
        "GET",
        true
      );

      commit("updateOrdersDetails", {
        data: data.value,
        isLoading: isLoading.value,
        updateOrder: payload,
      });
    },
  },
};
