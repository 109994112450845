import { ChildSearchType, GeoPlaceUniversal } from "../GeoPlace/type";
import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { IStore } from "../../types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { StockOperation, StockType } from "./type";
import { Module } from "vuex";
import { store } from "@/store";

export const StockTypeOperation: Module<StockOperation, IStore> = {
  state: {
    count: 0,
    next: null,
    previous: null,
    results: [],
    isLoading: true,
    isError: false,
    search: {
      country: {
        data: [],
        loading: false,
      },
      region: {
        data: [],
        loading: false,
      },
      city: {
        data: [],
        loading: false,
      },
    },
    select: {
      country: "",
      region: "",
      city: "",
    },
  },
  getters: {
    getCitySearchStockArray(state) {
      const city = state.search.city.data.map((value) => {
        return { name: value.name, id: value.id };
      });
      return { data: city, loading: state.search.city.loading };
    },
    getRegionSearchStockArray(state) {
      const region = state.search.region.data.map((value) => {
        return { name: value.name, id: value.id };
      });
      return { data: region, loading: state.search.region.loading };
    },
    getCountrySearchStockArray(state) {
      const country = state.search.country.data.map((value) => {
        return { name: value.name, id: value.id };
      });
      return { data: [...country], loading: state.search.country.loading };
    },
    getCountrySearchStock(state) {
      return state.select.country;
    },
    getRegionSearchStock(state) {
      return state.select.region;
    },
    getCitySearchStock(state) {
      return state.select.city;
    },
    getStock(state) {
      return state;
    },
    getStockResults(state) {
      return state.results;
    },
    getStockResultsInSelectedSheldules(state) {
      const stock = state.results.filter(
        (stock) =>
          stock.location === store.getters.getSelectedSchedulesInLocation
      );
      return stock;
    },
  },
  mutations: {
    updateStockOperation(
      state,
      payload: { data: StockOperation; isLoading: boolean }
    ) {
      if (!payload.data) return;

      state.isLoading = payload.isLoading;
      state.count = payload.data.count;
      state.next = payload.data.next;
      state.previous = payload.data.previous;
      state.results = payload.data.results;

      store.dispatch("getAllStockLocations");
    },
    updateStockOperationInUpdate(
      state,
      payload: { data: StockType; isLoading: boolean }
    ) {
      if (!payload.data) return;
      const filter = state.results.filter(
        (stock) => stock?.id !== payload?.data?.id
      );
      state.results = [...filter, payload.data];
      store.commit("updateSelectedSchedulesInLocation", {
        id: payload?.data?.location,
      });
    },
    updateGeoPlaceCountrySearchStock(state, payload: ChildSearchType) {
      state.search = {
        country: {
          data:
            payload.data.length <= 0
              ? state.search.country.data
              : [...payload.data],
          loading: payload.loading,
        },
        city: {
          data: [...state.search.city.data],
          loading: state.search.city.loading,
        },
        region: {
          data: [...state.search.region.data],
          loading: state.search.region.loading,
        },
      };
    },
    updateGeoPlaceCitySearchStock(state, payload: ChildSearchType) {
      state.search = {
        country: {
          data: [...state.search.country.data],
          loading: state.search.country.loading,
        },
        city: {
          data:
            payload.data.length <= 0
              ? state.search.city.data
              : [...payload.data],
          loading: payload.loading,
        },
        region: {
          data: [...state.search.region.data],
          loading: state.search.region.loading,
        },
      };
    },
    updateGeoPlaceRegionSearchStock(state, payload: ChildSearchType) {
      state.search = {
        country: {
          data: [...state.search.country.data],
          loading: state.search.country.loading,
        },
        city: {
          data: [...state.search.city.data],
          loading: state.search.city.loading,
        },
        region: {
          data:
            payload.data.length <= 0
              ? state.search.region.data
              : [...payload.data],
          loading: payload.loading,
        },
      };
    },
    updateCountrySelectStock(state, payload: string) {
      state.select.country = payload;
    },
    updateRegionSelectStock(state, payload: string) {
      state.select.region = payload;
    },
    updateCitySelectStock(state, payload: string) {
      state.select.city = payload;
    },
    deleteStockInSelectedLocations(state, payload: number) {
      state.results = state.results.filter(
        (stock) =>
          stock.location !==
          (store.getters.getSelectedSchedulesInLocation || payload)
      );
    },
  },
  actions: {
    async getStockDetails({ commit, state }) {
      state.isLoading = true;
      const { isError, data, isLoading } = await useFetch<
        StockOperation,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/customer_stocks/", "GET", true);
      state.isLoading = isLoading.value;
      if (!isError.value && data.value)
        commit("updateStockOperation", {
          data: data.value,
          isLoading: isLoading.value,
        });
    },
    async getSearchCityStock(
      { commit },
      payload: { search: string; region: string }
    ) {
      store.state.StockTypeOperation.search.city.loading = true;

      const { data, isError, isLoading } = await useFetch<
        GeoPlaceUniversal,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/geo_place/?level=3&name__icontains=" +
          payload.search +
          "&page_size=10&region=" +
          payload.region,
        "GET",
        true
      );

      if (!isError.value && data.value)
        commit("updateGeoPlaceCitySearchStock", {
          data: data.value.results,
          loading: isLoading.value,
        });
    },
    async getSearchCountryStock({ commit }, payload: string) {
      store.state.StockTypeOperation.search.country.loading = true;

      const { data, isError, isLoading } = await useFetch<
        GeoPlaceUniversal,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/geo_place/?level=1&name__icontains=" +
          payload +
          "&page_size=10",
        "GET",
        true
      );

      if (!isError.value && data.value)
        commit("updateGeoPlaceCountrySearchStock", {
          data: data.value.results,
          loading: isLoading.value,
        });
    },
    async getSearchRegionStock(
      { commit },
      payload: { search: string; country: string }
    ) {
      store.state.StockTypeOperation.search.region.loading = true;

      const { data, isError, isLoading } = await useFetch<
        GeoPlaceUniversal,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/geo_place/?level=2&name__icontains=" +
          payload.search +
          "&page_size=10&country=" +
          payload.country,
        "GET",
        true
      );

      if (!isError.value && data.value)
        commit("updateGeoPlaceRegionSearchStock", {
          data: data.value.results,
          loading: isLoading.value,
        });
    },
    async deleteSelectStock({ commit }) {
      const select = store.getters
        .getStockResultsInSelectedSheldules as StockType[];

      if (!select || select?.length <= 0) return;

      const { data, isError } = await useFetch<
        GeoPlaceUniversal,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/customer_stocks/" +
          select[0].id +
          "/",
        "PATCH",
        true,
        {
          activity_status: "DISABLED",
        }
      );
      if (!isError.value && data.value)
        commit("deleteStockInSelectedLocations");
    },
    async createStock({ commit }, payload) {
      const { data, isError, isLoading } = await useFetch<
        StockType,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/customer_stocks/", "POST", true, {
        comment: payload.comment,
        customer: payload.customerId,
        location: payload.locationStockId,
        phone: payload?.phone
          ? payload?.phone
          : payload?.buyer_phone_stock_start + "-" + payload?.buyer_phone_stock,
        responsible: payload?.responsible,
        responsible_name: payload?.buyer_name_stock,
        schedule: payload?.schedulesId,
        timezone: payload?.timezone,
      });
      if (!isError.value && data.value)
        commit("updateStockOperationInUpdate", { data: data.value, isLoading });
    },
    async updateStock({ commit }, payload) {
      const { data, isError, isLoading } = await useFetch<
        StockType,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/customer_stocks/" + payload?.stockId,
        "PATCH",
        true,
        {
          comment: payload.comment,
          customer: payload.customerId,
          location: payload.locationStockId,
          phone:
            payload?.buyer_phone_stock_start + "-" + payload?.buyer_phone_stock,
          responsible: payload?.responsible,
          responsible_name: payload?.buyer_name_stock,
          schedule: payload?.schedulesId,
          timezone: payload?.timezone,
        }
      );
      if (!isError.value && data.value)
        commit("updateStockOperationInUpdate", { data: data.value, isLoading });
    },
  },
};
