export const SeoTitle = (title: string) => (document.title = title);

export const SeoDescription = (description: string) => {
  const meta = document.querySelector('meta[name="description"]');
  if (!meta) return;
  meta.setAttribute("name", "description");
  meta.setAttribute("content", description);
};

export const SeoChangeLanguage = (language: string) => {
  let lg = "English";
  switch (language.toUpperCase()) {
    case "SWEDISH":
      lg = "sv";
      break;
    case "FINLAND":
      lg = "fi";
      break;
    default:
      lg = "en";
      break;
  }
  document.documentElement.setAttribute("lang", lg);
};
