import './utils/validation/globalValidation/globalValidationConfigure'
import './utils/validation/globalValidation/globalValidation'
import { i18n } from './plugins/i18n'
import { createApp } from 'vue'
import { store } from './store'
import router from './router'
import App from './App.vue'
import './views/type'

createApp(App)
.use(store)
.use(router)
.use(i18n)
.mount('#app')
