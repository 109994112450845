<template>
    <div class="preloader-wrapper">
        <img src="../../assets/logoBig.webp" alt="logo" class="type point"/>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
@import './preloader.scss';
</style>