import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { useFetch } from "@/hook/fetch/fetch";

export const logoutUser = async () => {
  const { data, isError } = await useFetch<any, IUseFetchDetailError>(
    process.env.VUE_APP_SERVER + "api/logout/",
    "GET",
    true
  );

  if (data.value && !isError.value) {
    window.location.reload();
    window.location.href = "/web/auth";
  }
};
