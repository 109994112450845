import {
  AllUsersSearch,
  OpenGlobal,
  UserTypeOperation,
  popupAccountTypeMessage,
} from "./type";
import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { GetUserType } from "@/utils/token/getUserType";
import { IStore } from "../../types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { UserTypeCarts } from "./type";
import { store } from "../../index";
import { computed, ref } from "vue";
import { Module } from "vuex";

export const UserModuleOperation: Module<UserTypeOperation, IStore> = {
  state: {
    id: null,
    job_title: "",
    email: "",
    first_name: "",
    last_name: "",
    title: null,
    photo_path: "",
    phone: "",
    customer: null,
    location: null,
    external_user_id: null,
    isLoading: true,
    remember: false,
    popupAccount: {
      open: false,
      message: [],
    },
    open: {
      value: false,
      name: "",
    },
    userType: [],
    sales_person_name: "",
    allUsers: {
      isError: false,
      count: null,
      next: null,
      previous: null,
      isLoading: false,
      results: [],
    },
    select: -1,
    prevSelect: -1,
    popup: false,
  },
  getters: {
    getAccountPopup(state) {
      return state.popupAccount;
    },
    getPopup(state) {
      return state.popup;
    },
    getUserData(state) {
      return state;
    },
    getUserCustomer(state) {
      return state.customer;
    },
    getUserLoading(state) {
      return state.isLoading;
    },
    getUserOpenElement(state) {
      return state.open;
    },
    getUserRemembeValue(state) {
      return state.remember;
    },
    getUserType(state) {
      return state.userType;
    },
    getAllUsers(state) {
      return state.allUsers.results;
    },
    getSelectUser(state) {
      return state.allUsers?.results?.find((user) => state.select === user.id);
    },
    getUserDataCustomer(state) {
      return state.allUsers?.results?.find(
        (user) => store.getters.userIdGet === user.id
      );
    },
    getSelect(state) {
      return state.select;
    },
    getPrevSelect(state) {
      return state.prevSelect;
    },
  },
  mutations: {
    setPopup(state, payload: boolean) {
      state.popup = payload;
    },
    setPopupAccount(
      state,
      payload: { open: boolean; message: popupAccountTypeMessage[] }
    ) {
      state.popupAccount.open = payload.open;
      state.popupAccount.message = [
        ...state.popupAccount.message,
        ...payload.message,
      ];
    },
    deletePopupAccountMessage(state) {
      state.popupAccount.open = false;
      state.popupAccount.message = [];
    },
    updateUserOpenGlobal(state, payload: OpenGlobal) {
      state.open = payload;
    },
    updateUserCustomerId(state, payload: number) {
      console.log(payload);
      state.customer = payload;
    },
    setUser(state, payload: { data: UserTypeOperation; isLoading: boolean }) {
      state.select = payload.data.id;
      state.prevSelect = payload.data.id;
      state.id = payload.data.id;
      state.job_title = payload.data.job_title;
      state.email = payload.data.email;
      state.first_name = payload.data.first_name;
      state.last_name = payload.data.last_name;
      state.title = payload.data.title;
      state.photo_path = payload.data.photo_path;
      state.phone = payload.data.phone;
      state.customer = payload.data.customer;
      state.location = payload.data.location;
      state.external_user_id = payload.data.external_user_id;
      state.isLoading = payload.isLoading;
      state.userType = GetUserType() || [""];
      store.commit("updateUserLoading", payload.isLoading);
    },
    updateUserInArray(
      state,
      payload: { data: UserTypeOperation; isLoading: boolean }
    ) {
      state.allUsers.results = state.allUsers.results.map((user) => {
        if (user.id === state.select) return (user = { ...payload.data });
        else return user;
      });
    },
    updateUserLoading(state, payload: boolean) {
      state.isLoading = payload;
    },
    changeUserRemembeValue(state, payload: boolean) {
      state.remember = payload;
    },
    updateAlluser(
      state,
      payload: { data: AllUsersSearch; isLoading: boolean }
    ) {
      if (
        payload.data.results.length <= 1 &&
        payload.data.results[0].customer === null
      ) {
        const serverType = ref(
          computed<string>(() => store.getters.getServerType)
        );
        const registrationData: any = {
          firm_name: payload.data.results[0].first_name,
          firm_name2: payload.data.results[0].last_name,
          title: payload.data.results[0].title,
          buyer_phone: payload.data.results[0].phone.replaceAll("-", ""),
          buyer_vat: "",
          fax: "",
          email: payload.data.results[0].email,
          buyer_name: payload.data.results[0].last_name,
          buyer_address: "",
          location: null,
          phone: payload.data.results[0].phone.replaceAll("-", ""),
          creation_time: new Date(),
        };
        if (serverType.value === "retail")
          registrationData.customer_type = "Private";
        store.dispatch("createCustomerNewUser", registrationData);
      }
      state.allUsers = payload.data;
    },
    updateSelectUser(state, payload: number) {
      if (payload !== -1) state.prevSelect = payload;
      state.select = payload;
      console.log(store.getters.getSelectUser);
      store.commit("updateAccountDetailsUserData", store.getters.getSelectUser);
    },
    addNewUser(state, payload: UserTypeOperation) {
      state.select = payload.id;
      state.prevSelect = payload.id;
      state.allUsers.results = [...state.allUsers.results, payload];
      store.commit("updateSelectUser", payload.id);
    },
    deleteUser(state, payload) {
      state.allUsers.results = [
        ...state.allUsers.results.filter((user) => user.id !== payload),
      ];
      store.commit("updateSelectUser", state.allUsers.results[0].id || -1);
    },
  },
  actions: {
    async getAllUsers({ commit }) {
      const { data, isError, errorMessage, isLoading } = await useFetch<
        UserTypeCarts,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/users/", "GET", true);

      if (!isError.value && !errorMessage.value)
        commit("updateAlluser", { data: data.value, isLoading });
    },
    async getDataUser({ commit, dispatch }) {
      return new Promise((resolve) => {
        if (!store.state.TokenModuleOperation.userId) return;

        useFetch<UserTypeCarts, IUseFetchDetailError>(
          process.env.VUE_APP_SERVER +
            "api/users/" +
            store.state.TokenModuleOperation.userId +
            "/",
          "GET",
          true
        ).then(async ({ data, isLoading }) => {
          commit("setUser", { data: data.value, isLoading });
          commit("updateAccountDetailsUserData", data.value);
          commit("updateUserShoppingCart", data.value?.shopping_carts);
          commit("updateOrdersFilter", data.value?.orders_filter);
          commit("updateInitialValuesCarsNewOrders", data.value?.pcr_filter);
          commit("updateInitialValuesTruckNewOrders", data.value?.tbr_filter);
          commit("addUiLanguage", data.value?.ui_language);
          await dispatch("createAllCarts");
          await resolve(data.value);
        });
      });
    },
    async updateDataUser({ commit }, payload) {
      const select = ref(computed<number>(() => store?.getters?.getSelect));
      commit("updateGlobalErrorsLocationsDataComponent", "");
      const { data, isError, errorMessage, isLoading } = await useFetch<
        UserTypeCarts,
        [IUseFetchDetailError]
      >(
        process.env.VUE_APP_SERVER + "api/users/" + select.value + "/",
        "PATCH",
        true,
        {
          email: payload?.email,
          first_name: payload?.first_name,
          last_name: payload?.last_name,
          job_title: payload?.job_title,
          phone: payload?.user_phone_start + "-" + payload?.user_phone,
          customer: payload?.customer || payload?.customerId,
          location: payload?.location || payload?.locationId,
          title: payload?.title || 1,
        }
      );

      if (!isError.value && !errorMessage.value) {
        commit("updateAccountDetailsUserData", data.value);
        commit("updateUserInArray", { data: data.value, isLoading });
        commit("updateUserShoppingCart", data.value?.shopping_carts);
        commit("updateOrdersFilter", data.value?.orders_filter);
        commit("updateInitialValuesCarsNewOrders", data.value?.pcr_filter);
        commit("updateInitialValuesTruckNewOrders", data.value?.tbr_filter);
      } else
        commit("updateGlobalErrorsLocationsDataComponent", errorMessage.value);
      // store.commit("setPopupAccount", {
      //   open: true,
      //   message: [
      //     {
      //       "Contact Details": errorMessage.value,
      //     },
      //   ],
      // });
    },
    async createDataUser({ commit }, payload) {
      commit("updateGlobalErrorsPersonalDataComponent", "");
      const { data, isError, errorMessage } = await useFetch<
        UserTypeOperation,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/users/", "POST", true, {
        email: payload?.email,
        first_name: payload?.first_name,
        last_name: payload?.last_name,
        job_title: payload?.job_title,
        phone: payload?.user_phone_start + "-" + payload?.user_phone,
        customer: payload?.customer || payload?.customerId,
        location: payload?.location || payload?.locationId,
        title: payload?.title || 1,
      });

      if (!isError.value && !errorMessage.value)
        commit("addNewUser", data.value);
      if (errorMessage.value)
        if (isError.value)
          store.commit("setPopupAccount", {
            open: true,
            message: [
              {
                "Contact Details": errorMessage.value,
              },
            ],
          });
    },
    async deleteUser({ commit }, payload: number) {
      const { isError, errorMessage } = await useFetch<
        null,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/users/" + payload + "/",
        "DELETE",
        true
      );
      if (!isError.value && !errorMessage.value) commit("deleteUser", payload);
    },
  },
};
