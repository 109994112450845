import { ChildSearchType, GeoPlaceData, GeoPlaceType, GeoPlaceUniversal } from "./type"

export const mutationGeoPlace = {
    updateGeoPlaceCountry(state: GeoPlaceType, payload: GeoPlaceUniversal) {
        state = {
            bankLocations: state.bankLocations,
            country: {
                isError: false,
                next: payload.next,
                isLoading: payload.isLoading,
                previous: payload.previous, 
                results: [...state.country.results, ...payload.results], 
                count: state.country.count
            },
            city: state.city, 
            region: state.region,
            search: state.search,
            select: {
                country: state.select.country,
                region: state.select.region,
                city: state.select.city,
            }
        }
    },
    updateGeoPlaceCity(state: GeoPlaceType, payload: GeoPlaceUniversal) {
        state = {
            bankLocations: state.bankLocations,
            city: {
                isError: false,
                next: payload.next,
                isLoading: payload.isLoading,
                previous: payload.previous, 
                results: [...state.city.results, ...payload.results], 
                count: state.city.count
            },
            country: state.country, 
            region: state.region,
            search: state.search,
            select: {
                country: state.select.country,
                region: state.select.region,
                city: state.select.city,
            }
        }
    },
    updateGeoPlaceRegion(state: GeoPlaceType, payload: GeoPlaceUniversal) {
        state = {
            bankLocations: state.bankLocations,
            region: {
                isError: false,
                next: payload.next, 
                previous: payload.previous, 
                results: [...state.region.results, ...payload.results], 
                count: state.region.count,
                isLoading: payload.isLoading
            },
            country: state.country, 
            city: state.city,
            search: state.search,
            select: {
                country: state.select.country,
                region: state.select.region,
                city: state.select.city,
            }
        }
    },
    updateGeoPlaceCountrySearch(state: GeoPlaceType, payload: ChildSearchType) {    
        state.search = {
            country: {
                data: payload.data.length <= 0 ? state.search.country.data : [...payload.data],
                loading: payload.loading
            },
            city: {
                data: [...state.search.city.data],
                loading: state.search.city.loading    
            },
            region: {
                data: [...state.search.region.data],
                loading: state.search.region.loading    
            },
        }
    },
    updateGeoPlaceCitySearch(state: GeoPlaceType, payload: ChildSearchType) {
        state.search =  {
            country: {
                data: [...state.search.country.data],
                loading: state.search.country.loading    
            },
            city: {
                data: payload.data.length <= 0 ? state.search.city.data : [...payload.data],
                loading: payload.loading
            },
            region: {
                data: [...state.search.region.data],
                loading: state.search.region.loading    
            },
        }
    },
    updateGeoPlaceRegionSearch(state: GeoPlaceType, payload: ChildSearchType) {
        state.search = {
            country: {
                data: [...state.search.country.data],
                loading: state.search.country.loading    
            },
            city: {
                data: [...state.search.city.data],
                loading: state.search.city.loading    
            },
            region: {
                data: payload.data.length <= 0 ? state.search.region.data : [...payload.data],
                loading: payload.loading
            }
        }
    },
    updateLocationsBank(state: GeoPlaceType, payload: GeoPlaceData){
        state.bankLocations = payload
    },
    updateCountrySelect(state: GeoPlaceType, payload: string) {
        state.select.country = payload
    },
    updateRegionSelect(state: GeoPlaceType, payload: string) {
        state.select.region = payload
    },
    updateCitySelect(state: GeoPlaceType, payload: string) {
        state.select.city = payload
    },
}
