import { IUseFetchDetailError } from "../../../hook/fetch/interface";
import { useFetch } from "../../../hook/fetch/fetch";
import { SearcInputType } from "../GeoPlace/type";
import { LocationsType, Locations } from "./type";
import { UserTypeOperation } from "../User/type";
import { IStore } from "../../types/StoreType";
import { StockOperation } from "../Stock/type";
import { Costumers } from "../Customers/type";
import { computed, ref } from "vue";
import { store } from "../../index";
import { Module } from "vuex";

export const LocationsModuleOperation: Module<LocationsType, IStore> = {
  state: {
    count: 0,
    next: null,
    previous: null,
    results: [],
    isLoading: false,
    isError: false,
    stockLocation: [],
    select: null,
  },
  getters: {
    getLocationLoading(state) {
      return state.isLoading;
    },
    getLocationsData(state) {
      return state;
    },
    getMyLocationsData(state) {
      const myLocation = state.results.filter(
        (location) =>
          location.id ===
          store.state.CostumersModuleOperation.results[0].location
      );
      return myLocation;
    },
    getStockLocations(state) {
      return state.stockLocation;
    },
    getStockLocationsInSelectedAddress(state) {
      const address = state.stockLocation.filter(
        (stok) => stok?.id === store.getters?.getSelectedSchedulesInLocation
      );
      store.commit("updateAccountLocationStock", address[0]);
      return address;
    },
    getStockLocationsLength(state) {
      return state.stockLocation.length;
    },
    getSelectLocationId(state) {
      return state.select;
    },
  },
  mutations: {
    deleteStockLocations(state, payload: number) {
      state.stockLocation = state.stockLocation.filter(
        (location) => location.id !== payload
      );
    },
    updateSelectedLocation(state, payload: number) {
      state.select = payload;
    },
    updateLocations(state, payload: LocationsType) {
      state.count = payload?.count || state.count;
      state.next = payload?.next || state.next;
      state.previous = payload?.previous || state.previous;
      state.results = payload?.results || state.results;
      state.isLoading = false;
    },
    updateOneLocations(state, payload: Locations) {
      const filterResult = state.results.filter(
        (location) => location.id !== payload.id
      );
      state.results = [...filterResult, payload];
    },
    updateStockLocations(state, payload: { data: Locations; update: boolean }) {
      const filterResult = state.stockLocation.filter(
        (location) => location?.id !== payload?.data?.id
      );
      state.stockLocation = [...filterResult, payload.data];

      store.commit("updateSelectedAddressCart", payload.data);

      if (!store.getters.getSelectedSchedulesInLocation || payload.update) {
        store.commit("updateSelectedSchedulesInLocation", {
          id: payload.data.id,
        });
        store.commit("updateAccountSchedulesLocation", payload.data);
        store.commit("updateSelectedLocation", payload.data.id);
      }
    },
  },
  actions: {
    async getDataLocations({ commit }) {
      const { data, isError, errorMessage, isLoading } = await useFetch<
        LocationsType,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/locations/", "GET", true);

      if (!isError.value && !errorMessage.value)
        await commit("updateLocations", {
          ...data.value,
          isLoading: isLoading.value,
        });
    },
    async getAllStockLocations({ state, commit }) {
      const stocks = store.getters.getStock as StockOperation;
      // const locations = store.getters.getLocationsData as LocationsType
      state.isLoading = true;
      if (stocks.results.length === 0) state.isLoading = false;
      stocks.results.forEach(async (stock, i) => {
        // const find = locations.results.some((location) => location.id === stock.location)
        if (stock.location) {
          const { data, isError, errorMessage, isLoading } = await useFetch<
            Locations,
            IUseFetchDetailError
          >(
            process.env.VUE_APP_SERVER + "api/locations/" + stock?.location,
            "GET",
            true
          );

          if (!isError.value && !errorMessage.value)
            await commit("updateStockLocations", {
              data: data.value,
              update: false,
            });
          if (i === stocks.results.length - 1)
            state.isLoading = isLoading.value;
        }
        state.isLoading = false;
      });
    },
    async deleteSelectLocation({ commit }, payload: number) {
      if (!payload) return;

      const { data, isError, errorMessage } = await useFetch<
        Locations,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/locations/" + payload + "/",
        "PATCH",
        true,
        {
          activity_status: 2,
        }
      );

      if (!isError.value && !errorMessage.value)
        await commit("updateStockLocations", {
          data: data.value,
          update: false,
        });

      store.commit("deleteStockLocations", payload);
    },
    async updateLocation({ commit }, payload) {
      const customerDataUser = ref(
        computed<Costumers[]>(() => store?.getters?.getUserCustomerData)
      );
      const userData = ref(
        computed<UserTypeOperation>(() => store.getters.getUserData)
      );
      const locationSelected = ref(
        computed<SearcInputType[] | SearcInputType>(
          () => store.getters.getCitySelectPostalCode
        )
      );
      store.commit("updateGlobalErrorsPersonalDataComponent", "");
      return await useFetch<Locations, IUseFetchDetailError>(
        process.env.VUE_APP_SERVER +
          "api/locations/" +
          (payload?.customerLocationId || payload.id) +
          "/",
        "PATCH",
        true,
        {
          geo_place:
            ((locationSelected?.value || []) as SearcInputType[])[0]?.data
              ?.geo_place ||
            ((locationSelected?.value || []) as SearcInputType).data
              ?.geo_place ||
            payload?.geo_place,
          geo_place_name:
            ((locationSelected?.value || []) as SearcInputType[])[0]?.data
              ?.geo_name ||
            ((locationSelected?.value || []) as SearcInputType).data
              ?.geo_place ||
            payload?.geo_place_name ||
            payload?.geo_name,
          house: payload?.house,
          street: payload?.street,
          full_address: payload?.full_address,
          postal_code: payload?.postal_code,
          geo_latitude: payload?.geo_latitude,
          geo_longitude: payload?.geo_longitude,
        }
      )
        .then(async ({ data, isError, errorMessage }) => {
          if (!isError.value && !errorMessage.value) {
            commit("updateAccountDetailsCustomerLocations", data.value);
            commit("updateCustomerLocationsById", data.value);
            commit("updateCountrySelect", "");
            commit("updateRegionSelect", "");
            commit("updateCitySelect", "");
            return data.value;
          }

          if (errorMessage.value?.detail) throw errorMessage.value.detail;
        })
        .then((data) => {
          return new Promise((resolve) => {
            if (userData.value.customer && !userData.value.location)
              store
                .dispatch("updateCustomer", {
                  ...customerDataUser.value[0],
                  ...payload,
                  location: data?.id,
                  customerId: customerDataUser.value[0]?.id,
                })
                .then(() => {
                  resolve(data);
                });
            else resolve(data);
          });
        })
        .catch((error) => {
          // store.commit("setPopupAccount", {
          //   open: true,
          //   message: [
          //     {
          //       "Company details": error,
          //     },
          //   ],
          // });
          store.commit("updateGlobalErrorsPersonalDataComponent", error);
        });
    },
    async createLocation({ commit }, payload) {
      const customerDataUser = ref(
        computed<Costumers[]>(() => store?.getters?.getUserCustomerData)
      );
      const userData = ref(
        computed<UserTypeOperation>(() => store.getters.getUserData)
      );
      store.commit("updateGlobalErrorsPersonalDataComponent", "");
      const locationSelected = ref(
        computed<SearcInputType[] | SearcInputType>(
          () => store.getters.getCitySelectPostalCode
        )
      );

      return await useFetch<Locations, IUseFetchDetailError>(
        process.env.VUE_APP_SERVER + "api/locations/",
        "POST",
        true,
        {
          geo_place:
            payload.geo_place ||
            (locationSelected.value as SearcInputType[])[0]?.data?.geo_place ||
            (locationSelected.value as SearcInputType)?.data?.geo_place,
          geo_place_name:
            payload.geo_name ||
            payload.geo_place_name ||
            (locationSelected.value as SearcInputType[])[0]?.data?.geo_name ||
            (locationSelected.value as SearcInputType)?.data?.geo_name,
          house: payload?.house,
          street: payload?.street,
          full_address: payload?.full_address,
          postal_code: payload?.postal_code,
          geo_latitude: payload?.geo_latitude,
          geo_longitude: payload?.geo_longitude,
        }
      )
        .then(async ({ data, isError, errorMessage }) => {
          if (!isError.value && !errorMessage.value) {
            const _data = { ...data.value };
            commit("updateAccountDetailsCustomerLocations", _data);
            commit("updateCustomerLocationsById", _data);
            commit("updateLastCreateLocation", _data);
            commit("updateOneLocations", _data);
            commit("updateCountrySelect", "");
            commit("updateRegionSelect", "");
            commit("updateCitySelect", "");
            return data.value;
          }
          if (errorMessage.value?.detail) throw errorMessage.value.detail;
        })
        .then((data) => {
          return new Promise((resolve) => {
            if (userData.value.customer && !userData.value.location)
              store
                .dispatch("updateCustomer", {
                  ...customerDataUser.value[0],
                  ...payload,
                  locationId: data?.id,
                  customerId: customerDataUser.value[0]?.id,
                })
                .then(() => {
                  resolve({ ...data, customerLocationId: data && data.id });
                });
            else resolve({ ...data, customerLocationId: data && data.id });
          });
        })
        .catch((error) => {
          // store.commit("setPopupAccount", {
          //   open: true,
          //   message: [
          //     {
          //       "Company details": error,
          //     },
          //   ],
          // });
          store.commit("updateGlobalErrorsPersonalDataComponent", error);
        });
    },
    async createLocationSchedules({ commit }, payload) {
      const locationSelected = ref(
        computed<SearcInputType[] | SearcInputType>(
          () => store.getters.getCityStockSelectPostalCode
        )
      );
      const locationSelectedCopy = ref(
        computed<SearcInputType[] | SearcInputType>(
          () => store.getters.getPostalcodeSelectPostalCode
        )
      );
      return await useFetch<Locations, IUseFetchDetailError>(
        process.env.VUE_APP_SERVER + "api/locations/",
        "POST",
        true,
        {
          geo_place:
            ((locationSelected?.value || []) as SearcInputType[])[0]?.data
              ?.geo_place ||
            ((locationSelected?.value || []) as SearcInputType).data
              ?.geo_place ||
            payload?.geo_place ||
            (Array.isArray(locationSelectedCopy.value)
              ? locationSelectedCopy.value[0]?.data?.geo_place
              : locationSelectedCopy.value?.data?.geo_place),
          geo_place_name:
            payload?.region_stock ||
            ((locationSelected?.value || []) as SearcInputType[])[0]?.data
              ?.geo_name ||
            ((locationSelected?.value || []) as SearcInputType).data
              ?.geo_place ||
            payload?.geo_place_name ||
            payload?.geo_name,
          house: payload?.house,
          street: payload?.street,
          full_address: payload?.buyer_address_stock,
          postal_code: payload?.postal_code_stock,
          geo_latitude: payload?.latitude,
          geo_longitude: payload?.longitude,
        }
      )
        .then(async ({ data, isError, errorMessage }) => {
          if (!isError.value && !errorMessage.value) {
            commit("updateStockLocations", { data: data.value, update: true });
            commit("updateCountrySelectStock", "");
            commit("updateRegionSelectStock", "");
            commit("updateCitySelectStock", "");
            return data.value;
          }
        })
        .catch((error) => {
          store.commit("setPopupAccount", {
            open: true,
            message: [
              {
                "Chedules details": error,
              },
            ],
          });
        });
    },
    async updateLocationSchedules({ commit }, payload) {
      const locationSelected = ref(
        computed<SearcInputType[] | SearcInputType>(
          () => store.getters.getCityStockSelectPostalCode
        )
      );
      const locationSelectedCopy = ref(
        computed<SearcInputType[] | SearcInputType>(
          () => store.getters.getPostalcodeSelectPostalCode
        )
      );
      return await useFetch<Locations, IUseFetchDetailError>(
        process.env.VUE_APP_SERVER +
          "api/locations/" +
          (payload?.id || payload.locationStockId),
        "PATCH",
        true,
        {
          geo_place:
            ((locationSelected?.value || []) as SearcInputType[])[0]?.data
              ?.geo_place ||
            ((locationSelected?.value || []) as SearcInputType)?.data
              ?.geo_place ||
            payload?.geo_place ||
            (Array.isArray(locationSelectedCopy.value)
              ? locationSelectedCopy.value[0]?.data?.geo_place
              : locationSelectedCopy.value?.data?.geo_place),
          geo_place_name:
            payload?.region_stock ||
            ((locationSelected?.value || []) as SearcInputType[])[0]?.data
              ?.geo_name ||
            ((locationSelected?.value || []) as SearcInputType)?.data
              ?.geo_place ||
            payload?.geo_place_name ||
            payload?.geo_name,
          house: payload?.house,
          street: payload?.street,
          full_address: payload?.buyer_address_stock,
          postal_code: payload?.postal_code_stock,
          geo_latitude: payload?.latitude,
          geo_longitude: payload?.longitude,
        }
      )
        .then(async ({ data, isError, errorMessage }) => {
          if (!isError.value && !errorMessage.value) {
            commit("updateStockLocations", { data: data.value, update: true });
            commit("updateCountrySelectStock", "");
            commit("updateRegionSelectStock", "");
            commit("updateCitySelectStock", "");
            return data.value;
          }
        })
        .catch((error) => {
          store.commit("setPopupAccount", {
            open: true,
            message: [
              {
                "Chedules details": error,
              },
            ],
          });
        });
    },
  },
};
