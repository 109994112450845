import { postalcodeType } from "@/store/modules/Postalcode/type"
import { OptionsType, SearcInputType } from "@/store/modules/GeoPlace/type"

export const searchFunction = (value: string, arrayProperties: SearcInputType[]| OptionsType[] | postalcodeType[], postalcheck = false) => {
    // console.log(value, arrayProperties)
    if(value?.length === 0) return arrayProperties
    if(!value) return arrayProperties
    const inputValue = value?.toLocaleLowerCase().trim()

    if((arrayProperties[0] as OptionsType)?.name){
        return (arrayProperties as OptionsType[]).filter((properties) => {
            const propertyLower = properties.name.toLocaleLowerCase()
            return propertyLower.indexOf(inputValue) > -1
        })
    }else{
        return (arrayProperties as postalcodeType[]).filter((properties) => {
            let propertyLower = ''

            if(!postalcheck) propertyLower = properties.geo_name.toLocaleLowerCase()
            else propertyLower = properties.postal_code.toLocaleLowerCase()

            return propertyLower.indexOf(inputValue) > -1
        })
    }
}