import { IStore } from '../../types/StoreType'
import { mutationGeoPlace } from './mutations'
import { actionsGeoPlace } from './actions'
import { GeoPlaceType } from './type'
import { Module } from 'vuex'

export const GeoPlaceModuleOperation: Module<GeoPlaceType, IStore> = {
    state: {
        bankLocations: {
            id: 0,
            name: '',
            level: 0,
            parent: 0
        },
        city: {
            count: 100,
            next: null,
            previous: null,
            isLoading: true,
            isError: false,
            results: []
        },
        country: {
            count: 100,
            next: null,
            previous: null,
            isLoading: true,
            isError: false,
            results: []
        },
        region: {
            count: 100,
            next: null,
            previous: null,
            isLoading: true,
            isError: false,
            results: []
        },
        search: {
            country: {
                data: [],
                loading: false
            },
            region: {
                data: [],
                loading: false
            },
            city: {
                data: [],
                loading: false
            }
        },
        select: {
            country: '',
            region: '',
            city: ''
        }
    },
    getters: {
        getGeoPlace(state) {
            return state
        },
        getCitySearchArray(state) {
            const city = state.search.city.data.map(value =>{
                return {
                    name: value.name,
                    id: value.id
                }
            })
            return {data: city, loading: state.search.city.loading}
        },
        getRegionSearchArray(state) {
            const region = state.search.region.data.map(value => {
                return {
                    name: value.name,
                    id: value.id
                }
            })
            return {data: region, loading: state.search.region.loading}
        },
        getCountrySearchArray(state) {
            const country = state.search.country.data.map(value => {
                return {
                    name: value.name,
                    id: value.id
                }
            })
            return {data: country, loading: state.search.country.loading}
        },
        getCountrySearch(state) {
            return state.select.country
        },
        getRegionSearch(state) {
            return state.select.region
        },        
        getCitySearch(state) {
            return state.select.city
        },
        getBankLocations(state) {
            return state.bankLocations
        }
    },
    mutations: mutationGeoPlace,
    actions: actionsGeoPlace
}