import { TokenTypeOperation, TokenTypeOperationFetch } from "./type";
import { IUseFetchDetailError } from "../../../hook/fetch/interface";
import { GetUserType } from "../../../utils/token/getUserType";
import { getUserId } from "../../../utils/token/getUserId";
import { IStore } from "../../types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { Module } from "vuex";
import router from "@/router";
import { store } from "@/store";
import { UserTypeCarts } from "../User/type";

export const TokenModuleOperation: Module<TokenTypeOperation, IStore> = {
  state: {
    redirect: null,
    accessToken: null,
    refreshToken: null,
    loading: true,
    error: false,
    message: "",
    accessGroup: [],
    refreshFirst: true,
    username_login: "",
    password_login: "",
    username_registration: "",
    email_address_registration: "",
    password_registration: "",
    password_repeat_registration: "",
  },
  getters: {
    getServerType() {
      return process.env.VUE_APP_TYPE_SERVER;
    },
    accessTokenGet(state) {
      return state.accessToken;
    },
    loadingGet(state) {
      return state.loading;
    },
    errorGet(state) {
      return state.error;
    },
    messageGet(state) {
      return state.message;
    },
    userIdGet(state) {
      return state.userId;
    },
    accessGroupGet(state) {
      return state.accessGroup;
    },
    getUserDataLogin(state) {
      return {
        email: state.username_login,
        password: state.password_login,
      };
    },
    getUserDataRegistration(state) {
      return {
        first_name: state.username_registration,
        email: state.email_address_registration,
        password: state.password_registration,
        password_confirm: state.password_repeat_registration,
      };
    },
    getRedirectPath(state) {
      return state.redirect;
    },
  },
  mutations: {
    setRedirectPath(state, payload: string | null) {
      state.redirect = payload;
    },
    updateUserDataLogin(
      state,
      payload: {
        email: string;
        password: string;
      }
    ) {
      state.username_login = payload.email;
      state.password_login = payload.password;
    },
    accessTokenSet(state, payload: string | null) {
      state.accessToken = payload;
    },
    refreshTokenSet(state, payload: string) {
      state.refreshToken = payload;
    },
    loadingSet(state, payload: boolean) {
      state.loading = payload;
    },
    errorSet(state, payload: boolean) {
      state.error = payload;
    },
    messageSet(state, payload: IUseFetchDetailError) {
      state.message = payload.detail;
    },
    updateUserId(state, payload: number) {
      state.userId = payload;
    },
    accessGroupSet(state, payload: string[]) {
      state.accessGroup = payload;
    },
  },
  actions: {
    updateUserId({ commit }, payload: number) {
      commit("updateUserId", payload);
    },
    accessGroupSet({ commit }, payload: string[]) {
      commit("accessGroupSet", payload);
    },
    refreshTokenSet({ commit }, payload) {
      commit("refreshTokenSet", payload);
    },
    accessTokenSet({ commit }, payload) {
      commit("accessTokenSet", payload);
    },
    async updateRefreshTokenAndAccessToken({ commit, dispatch }) {
      if (store.state.TokenModuleOperation.refreshFirst) {
        await commit("loadingSet", true);
        await commit("updateLoadingCustomerLocations", true);
      }

      await useFetch<TokenTypeOperationFetch, IUseFetchDetailError>(
        process.env.VUE_APP_SERVER + "api/token/refresh/",
        "POST",
        false
      ).then(async ({ data, isError, errorMessage, isLoading }) => {
        if (store.state.TokenModuleOperation.refreshFirst) {
          store.state.TokenModuleOperation.refreshFirst = false;
          store.state.TokenModuleOperation.loading = isLoading.value;
        } else store.state.TokenModuleOperation.loading = false;

        await commit("errorSet", isError.value);
        await commit("loadingSet", isLoading.value);

        if (!errorMessage.value?.detail && !isError.value) {
          commit("accessTokenSet", data.value?.access);
          commit("accessGroupSet", GetUserType());
          await commit("updateUserId", getUserId());
          await dispatch("getDataUser").then(
            async (userInformation: UserTypeCarts) => {
              await dispatch("getDataCustomersById", userInformation?.customer);
              await dispatch("getAllUsers");
              await dispatch(
                "getLocationsCustomersById",
                store.getters.getCustomersLocationId
              );
              dispatch("getDataBank");
              dispatch("getAllCurrencies");
              return data.value?.access;
            }
          );
          // await dispatch('getGeoPlaceCountry')
        } else {
          commit("updateLoadingCustomerLocations", false);
          store.commit("updateUserLoading", isLoading.value);
          commit("accessTokenSet", null);
        }
      });
    },
    async userLogin({ commit, dispatch }, payload) {
      await commit("updateLoadingCustomerLocations", true);

      const { data, isError, errorMessage, isLoading } = await useFetch<
        TokenTypeOperationFetch,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/token/", "POST", false, payload);

      store.state.TokenModuleOperation.loading = isLoading.value;

      await commit("errorSet", isError.value);
      await commit("loadingSet", false);
      await commit("updateLoadingCustomerLocations", false);
      if (
        isError.value &&
        (errorMessage.value as IUseFetchDetailError).detail
      ) {
        await commit("loadingSet", false);
        await commit("updateLoadingCustomerLocations", false);
        commit("messageSet", errorMessage.value);
      } else if (data.value && data.value?.access) {
        commit("accessTokenSet", data.value?.access);
        if (payload.replace) {
          router.replace(payload.replace);
        } else {
          router.replace("/orders");
        }
        commit("accessGroupSet", GetUserType());
        commit("updateUserId", getUserId());
        await dispatch("getDataUser").then(
          async (userInformation: UserTypeCarts) => {
            await dispatch("getDataCustomersById", userInformation?.customer);
            await dispatch("getAllUsers");
            await dispatch(
              "getLocationsCustomersById",
              store.getters.getCustomersLocationId
            );
            dispatch("getDataBank");
            dispatch("getAllCurrencies");
            // await dispatch('getGeoPlaceCountry')
          }
        );
      }
    },
  },
};
