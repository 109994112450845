import { IUseFetchDetailError } from "../../../hook/fetch/interface";
import { useFetch } from "../../../hook/fetch/fetch";
import { ISchedules } from "../Schedules/type";
import { IStore } from "../../types/StoreType";
import { Locations } from "../Locations/type";
import { StockType } from "../Stock/type";
import { CostumersType } from "./type";
import { ref, computed } from "vue";
import { Costumers } from "./type";
import { store } from "@/store";
import { Module } from "vuex";

export const CostumersModuleOperation: Module<CostumersType, IStore> = {
  state: {
    count: 10,
    next: null,
    customer_type: null,
    firm_status: null,
    previous: null,
    results: [],
    isLoading: true,
    isError: false,
    locations: [],
    select: null,
    lastCreatetionLocation: [],
  },
  getters: {
    getCustomerLocationLoading(state) {
      return state.isLoading;
    },
    getCustomerData(state) {
      return state;
    },
    getUserCustomerData(state) {
      const id = store.getters.getUserCustomer;
      if (!id) return;

      return [state.results.find((customer) => customer.id === id)];
    },
    getUserCustomerFirmStatus(state) {
      if (state.firm_status !== null) return state.firm_status;

      const id = store.getters.getUserCustomer;

      if (!id) return;

      const customers = state.results.filter((customer) => customer.id === id);

      if (customers.length <= 0) return;

      state.firm_status = customers[0]?.firm_status;

      return customers[0]?.firm_status;
    },
    getUserCustomerTypeData(state) {
      if (state.customer_type !== null) return state.customer_type;

      const id = store.getters.getUserCustomer;

      if (!id) return;

      const customers = state.results.filter((customer) => customer.id === id);

      if (customers.length <= 0) return;

      state.customer_type = customers[0]?.customer_type;

      return customers[0]?.customer_type;
    },
    getCustomersLocationId(state) {
      const id = store.getters.getUserCustomer;

      if (!id) return;

      const filterCustomers = state.results.find(
        (customer) => customer.id === id
      );

      return filterCustomers?.location;
    },
    getLocationsCustomerById(state) {
      const locations = state.locations.find(
        (location) => (location.id = store.getters.getCustomersLocationId)
      );

      if (!locations) return [];

      store.commit("updateAccountDetailsCustomerLocations", locations);

      return [locations];
    },
    getCostumerInSelectedAddress(state) {
      const stockFilter = store.getters
        .getStockResultsInSelectedSheldules as StockType[];

      if (stockFilter.length <= 0) return [];

      return [
        state.results.find(
          (costumer) => costumer.id === stockFilter[0].customer
        ),
      ];
    },
    getCustomerSelectId(state) {
      return state.select;
    },
    getLastCreationLocation(state) {
      return state.lastCreatetionLocation;
    },
  },
  mutations: {
    updateLastCreateLocation(state, payload: Locations[]) {
      state.lastCreatetionLocation = payload;
    },
    updateLoadingCustomerLocations(state, payload: boolean) {
      state.isLoading = payload;
    },
    updateCustomer(state, payload: CostumersType) {
      state.count = payload.count;
      state.next = payload.next;
      state.previous = payload.previous;
      state.results = payload.results;
      state.isLoading = payload.isLoading;
    },
    updateCustomerSelect(state, payload: number) {
      state.select = payload;
    },
    updateCustomerInUpdate(state, payload: Costumers) {
      let filter = state.results.filter(
        (customer) => customer.id !== payload.id
      );
      filter = [...filter, payload];
      console.log(payload, filter);
      state.results = [...filter];
      store.commit("updateAccountDetailsUserCustomer", payload);
      store.commit("updateLastCreateLocation", payload);
    },
    updateCustomerInUpdateStock(
      state,
      payload: { data: Costumers; update: boolean }
    ) {
      let filter = state.results.filter(
        (customer) => customer.id !== payload.data.id
      );
      filter = [...filter, payload.data];
      state.results = filter;

      if (payload.update) store.commit("updateCustomerSelect", payload.data.id);
      store.commit("updateAccountDetailsUserCustomer", payload.data);
    },
    updateCustomerInUpdateLocation(state, payload: Costumers) {
      let filter = state.results.filter(
        (customer) => customer.id !== payload.id
      );
      filter = [...filter, payload];
      state.results = filter;
    },
    updateCustomerById(state, payload: Costumers) {
      if (state.results.length <= 0) {
        state.results = [
          ...state.results.filter((element) => element.id !== payload.id),
          payload,
        ];
        store.commit("updateAccountDetailsUserCustomer", payload);
      } else {
        state.results = [payload];
        store.commit("updateAccountDetailsUserCustomer", payload);
      }
    },
    updateCustomerLocationsById(state, payload: Locations) {
      state.isLoading = false;
      const filter = state.locations.filter(
        (location) => location.id !== payload.id
      );
      state.locations = [...filter, payload];
    },
  },
  actions: {
    async getDataCustomers({ commit }) {
      const { data, isError, errorMessage, isLoading } = await useFetch<
        CostumersType,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/customers/", "GET", true);

      if (!isError.value && !errorMessage.value)
        await commit("updateCustomer", {
          ...data.value,
          isLoading: isLoading.value,
        });
    },
    async getDataCustomersById({ commit }, id: number | null) {
      if (!id) return;

      const { data, isError, errorMessage } = await useFetch<
        Costumers,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/customers/" + id, "GET", true);

      if (!isError.value && !errorMessage.value)
        await commit("updateCustomerById", data.value);
    },
    async getLocationsCustomersById({ commit }, id: number | null) {
      if (!id) return;

      const { data, isError, errorMessage } = await useFetch<
        Locations,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/locations/" + id, "GET", true);

      if (!isError.value && !errorMessage.value)
        await commit("updateCustomerLocationsById", data.value);
    },
    async getSchedulesCostumers({ commit }) {
      const stockFilter = store.getters
        .getStockResultsInSelectedSheldules as StockType[];

      if (stockFilter.length <= 0) return;

      const costumersResult = store.state.CostumersModuleOperation?.results;

      if (!costumersResult) return;

      if (
        costumersResult.some(
          (customer) => customer?.id === stockFilter[0]?.customer
        )
      )
        return;

      if (!stockFilter[0].customer) return;

      const { data, isError } = await useFetch<
        ISchedules,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/customers/" +
          stockFilter[0].customer +
          "/",
        "GET",
        true
      );

      if (!isError.value && data.value)
        commit("updateCustomerById", data.value);
    },
    async updateCustomer({ commit }, payload) {
      commit("updateGlobalErrorsLocationsDataComponent", "");
      return await useFetch<Costumers, IUseFetchDetailError>(
        process.env.VUE_APP_SERVER +
          "api/customers/" +
          payload.customerId +
          "/",
        "PATCH",
        true,
        {
          firm_name: payload?.firm_name + ' ' + payload?.firm_name2,
          buyer_phone:
            payload?.buyer_phone_start +
            payload?.buyer_phone?.replaceAll("-", ""),
          buyer_vat: (payload?.buyer_vat_start || "FI") + payload?.buyer_vat,
          fax: payload?.fax,
          email: payload?.buyer_email,
          buyer_name: payload?.buyer_name,
          buyer_address: payload?.buyer_address,
          location:
            payload?.location ||
            payload?.customerLocationId ||
            payload?.locationId,
          manager: payload?.manager,
          firm_status: payload?.firm_status || "NULL",
          phone: payload?.phone?.replaceAll("-", ""),
          currency: payload?.currency,
          creation_time: payload?.creation_time || new Date(),
        }
      )
        .then(({ data, isError, errorMessage }) => {
          if (!isError.value && data.value) {
            commit("updateAccountDetailsUserCustomer", data.value);
            return data.value;
          }
          if (isError.value) throw errorMessage.value;
        })
        .catch((error) => {
          // store.commit("setPopupAccount", {
          //   open: true,
          //   message: [
          //     {
          //       "Contact Details": error,
          //     },
          //   ],
          // });
          commit("updateGlobalErrorsLocationsDataComponent", error);
        });
    },
    async createCustomerNewUser({ commit }, payload) {
      commit("updateGlobalErrorsLocationsDataComponent", "");
      const { data, isError, errorMessage } = await useFetch<
        Costumers,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/customers/", "POST", true, {
        ...payload,
        firm_name: payload?.firm_name + ' ' + payload?.firm_name2,
        firm_name2: '',
      });
      if (!isError.value && data.value) {
        console.log((data.value))
        await store.dispatch("createStock", {
          customerId: data.value.id,
          phone: data.value.buyer_phone,
          buyer_name_stock: data.value.firm_name || data.value.buyer_name,
        });
        store.commit("updateUserCustomerId", data.value.id);
        store.commit("updateCustomerInUpdate", data.value);
        store.dispatch("updateDataUser", {
          ...store.getters.getUserData,
          customer: data.value.id,
          user_phone_start: "",
          user_phone: data.value.phone,
        });
      }
      if (isError.value) throw errorMessage.value;
    },
    async createCustomer({ commit }, payload) {
      commit("updateGlobalErrorsLocationsDataComponent", "");
      const serverType = ref(
        computed<string>(() => store.getters.getServerType)
      );
      if (serverType.value === "retail") payload.customer_type = "Private";
      return await useFetch<Costumers, IUseFetchDetailError>(
        process.env.VUE_APP_SERVER + "api/customers/",
        "POST",
        true,
        {
          customer_type: payload.customer_type,
          firm_name: payload?.firm_name + ' ' + payload?.firm_name2,
          firm_name2: '',
          buyer_phone:
            payload?.buyer_phone_start +
            payload?.buyer_phone?.replaceAll("-", ""),
          buyer_vat: (payload?.buyer_vat_start || "FI") + payload?.buyer_vat,
          fax: payload?.fax,
          email: payload?.buyer_email,
          buyer_name: payload?.buyer_name,
          buyer_address: payload?.buyer_address,
          location: payload?.location || payload?.customerLocationId,
          manager: payload?.manager,
          firm_status: payload?.firm_status || "NULL",
          phone: payload?.phone?.replaceAll("-", ""),
          currency: payload?.currency,
          creation_time: payload?.creation_time || new Date(),
        }
      )
        .then(({ data, isError, errorMessage }) => {
          if (!isError.value && data.value) {
            /// ???? ///
            commit("updateCustomerInUpdateLocation", data.value);
            /// ???? ///
            commit("updateAccountDetailsUserCustomer", data.value);

            return data.value;
          }
          if (isError.value) throw errorMessage.value;
        })
        .catch((error) => {
          // store.commit("setPopupAccount", {
          //   open: true,
          //   message: [
          //     {
          //       "Contact Details": error,
          //     },
          //   ],
          // });
          commit("updateGlobalErrorsLocationsDataComponent", error);
        });
    },
    async createOrUpdateCustomerInNavision(store, payload) {
      console.log(payload);
      const { isLoading, isError, errorMessage, data } = await useFetch<
        Costumers,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/customers/" +
          payload.customerId +
          "/send_to_navision/",
        "PATCH",
        true,
        {
          firm_name: payload?.firm_name + ' ' + payload?.firm_name2,
          firm_name2: '',
          buyer_phone: payload?.buyer_phone?.replaceAll("-", ""),
          buyer_vat: payload?.buyer_vat,
          fax: payload?.fax,
          email: payload?.buyer_email,
          buyer_name: payload?.buyer_name,
          buyer_address: payload?.buyer_address,
          location: payload?.location || payload?.customerLocationId,
          manager: payload?.manager,
          firm_status: payload?.firm_status || "NULL",
          phone: payload?.phone?.replaceAll("-", ""),
          currency: payload?.currency,
          creation_time: payload?.creation_time || new Date(),
        }
      );
      const popupData = {
        open: true,
        message: [
          {
            Navision: { Response: ["Successfully"] },
          },
        ],
      };
      if (!isError.value) {
        store.commit("setPopupAccount", popupData);
      } else {
        popupData.message = [
          {
            Navision: { Response: ["Error on the server"] },
          },
        ];
        store.commit("setPopupAccount", popupData);
      }
      store.commit("setPopup", false);
      return { isLoading, isError, errorMessage, data };
    },
  },
};
