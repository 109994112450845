import { checkToken } from "@/utils/token/checkToken";
import { store } from "../../store/index";
import { IUseFetch } from "./interface";
import { reactive, toRefs } from "vue";
import router from "@/router";
import axios from "axios";

/// hook useFetch
export const useFetch = async <D, E = any, B = any>(
  url: string,
  method = "POST",
  Authorization = false,
  body?: B,
  file = false
) => {
  /// state
  const state = reactive<IUseFetch<D, E>>({
    isLoading: true,
    isError: false,
    errorMessage: null,
    data: null,
    statusError: null,
  });
  /// fetch
  const fetchData = async () => {
    /// header
    const headers: any = {};
    headers["Accept"] = "application/json, text/plain, */*";
    /// need auth
    if (Authorization) {
      const tokenCheck = (await checkToken()) || true;
      tokenCheck
        ? (headers["Authorization"] = "Bearer " + store.getters.accessTokenGet)
        : router.replace({ path: "/" });
    }
    await axios({ method, url, data: body, headers, withCredentials: true })
      .then((response) => {
        if (response.status >= 400 && response.status !== 409) return Promise.reject(response.data);
        state.data = { ...response.data };
      })
      .catch((error) => {
        const typedError = error?.response?.data || "";
        state.isError = true;
        state.errorMessage = typedError;
        state.statusError = error?.response?.status;
        if (error?.response?.status >= 500) {
          return store.commit("setPopupAccount", {
            open: true,
            message: [
              {
                "Server error": ["Sorry! The server is temporarily down ;("],
              },
            ],
          });
        }
        switch (error?.response?.status) {
          case 400:
            if (
              (method === "POST" || method === "PATCH") &&
              error?.response?.status === 400
            )
              return;
            return store.commit("setPopupAccount", {
              open: true,
              message: [
                {
                  "Server error": ["Sorry! The server is temporarily down ;("],
                },
              ],
            });
          default: {
            if (typeof typedError?.detail === "string") {
              if (typedError?.code) {
                if (typedError?.code === "token_not_valid") return;
                return store.commit("setPopupAccount", {
                  open: true,
                  message: [
                    {
                      [typedError?.code?.toUpperCase()?.replaceAll("_", " ")]: [
                        typedError?.detail,
                      ],
                    },
                  ],
                });
              } else {
                if (
                  typedError?.detail ===
                  "No active account found with the given credentials"
                )
                  return;
                return store.commit("setPopupAccount", {
                  open: true,
                  message: [
                    {
                      "Server error": ["Error on the server."],
                    },
                  ],
                });
              }
            }
            if (
              typeof typedError?.detail !== "string" &&
              Array.isArray(typedError?.detail)
            )
              return store.commit("setPopupAccount", {
                open: true,
                message: [
                  {
                    "Server error": [typedError?.detail],
                  },
                ],
              });
            else
              return store.commit("setPopupAccount", {
                open: true,
                message: [
                  {
                    "Server error": [typedError],
                  },
                ],
              });
          }
        }
      })
      .finally(() => (state.isLoading = false));
  };

  await fetchData();

  return {
    ...toRefs(state),
  };
};
