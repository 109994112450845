import { store } from "../../store/index";

export const convertToVatMask = (value: string, state: any, key: string) => {
  let replace = value;
  if (replace.length > 0) {
    if (replace.substring(0, 2) === "FI") {
      state[key] = "FI";
      replace =
        replace.substring(2)
    } else if (replace.substring(0, 2) === "SE") {
      state[key] = "SE";
      replace =
        replace.substring(2)
    } else {
      state[key] = "SE";
      replace =
      replace.substring(2)
    }
    return replace;
  } else {
    if (store.getters.getUserUiLang === "Swedish") state[key] = "SE";
    else state[key] = "FI";
    return "";
  }
};
