import { AccountDetailsModuleOperation } from "./modules/accountDetails/accountDetails";
import { PostalCodeStockModuleOperation } from "./modules/Postalcode/postalcodeStock";
import { AdvertisingTypeOperation } from "./modules/advertising/advertising";
import { PostalCodeModuleOperation } from "./modules/Postalcode/postalcode";
import { CurrencyiesModuleOperation } from "./modules/Currency/Currency";
import { NewOrdersModuleOperation } from "./modules/newOrders/newOrders";
import { CostumersModuleOperation } from "./modules/Customers/Customers";
import { LocationsModuleOperation } from "./modules/Locations/Locations";
import { ComponentsTypeOperation } from "./modules/Component/Component";
import { SchedulesTypeOperation } from "./modules/Schedules/Schedules";
import { CallbackModuleOperation } from "./modules/Callback/Callback";
import { OrdersTypeOperation } from "./modules/Orders/OrdersOperation";
import { TokenModuleOperation } from "./modules/Token/TokenOperation";
import { GeoPlaceModuleOperation } from "./modules/GeoPlace/GeoPlace";
import { LanguageTypeOperation } from "./modules/Lang/LangOperation";
import { StockTypeOperation } from "./modules/Stock/StockOperation";
import { SearchTypeOperation } from "./modules/Search/Search";
import { ChangeTypeOperation } from "./modules/Change/Change";
import { BankModuleOperation } from "./modules/Bank/Bank";
import { UserModuleOperation } from "./modules/User/User";
import { CartTypeOperation } from "./modules/Cart/Cart";
import { IStore } from "./types/StoreType";
import { createStore } from "vuex";
/// store operations
export const store = createStore<IStore>({
  modules: {
    CallbackModuleOperation,
    AdvertisingTypeOperation,
    CurrencyiesModuleOperation,
    PostalCodeStockModuleOperation,
    NewOrdersModuleOperation,
    PostalCodeModuleOperation,
    AccountDetailsModuleOperation,
    CartTypeOperation,
    SearchTypeOperation,
    SchedulesTypeOperation,
    StockTypeOperation,
    BankModuleOperation,
    LocationsModuleOperation,
    CostumersModuleOperation,
    UserModuleOperation,
    TokenModuleOperation,
    LanguageTypeOperation,
    ComponentsTypeOperation,
    ChangeTypeOperation,
    GeoPlaceModuleOperation,
    OrdersTypeOperation,
  },
});
