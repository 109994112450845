import { phoneValidation } from "./universalMethods";
import * as yup from "yup";

yup.addMethod(yup.mixed, "phoneValidation", phoneValidation);

export const LocationDataComponentValidation = yup.object().shape(
  {
    first_name: yup.string().nullable().min(1).required().label("First name"),
    last_name: yup.string().nullable().min(1).required().label("Last name"),
    user_phone: (yup.mixed() as any).phoneValidation("Phone number is invalid"),
    email: yup
      .string()
      .required()
      .email()
      .label("Please change email address, "),
  },
  [
    ["first_name", "first_name"],
    ["last_name", "last_name"],
    ["job_title", "job_title"],
    ["email", "email"],
  ]
);
