import AccountDetailsViewVue from "@/views/AccountDetailsView/AccountDetailsView.vue";
import UpdatePasswordView from "@/views/UpdatePasswordView/UpdatePasswordView.vue";
import AuthorizationView from "../views/AuthorizationView/AuthorizationView.vue";
import PrivatePolicy from "../views/PrivatePolicyView/PrivatePolicyView.vue";
import OrderViewTbr from "../views/OrderView/OrderViewTbr/OrderViewTbr.vue";
import OrderViewPcr from "@/views/OrderView/OrderViewPcr/OrderViewPcr.vue";
import NewOrdersView from "../views/NewOrders/searchComponent.vue";
import NotFoundView from "../views/NotFoundPage/NotFoundView.vue";
import AccountView from "../views/AcccountView/AccountView.vue";
import ContactUse from "../views/ContactUs/ContactUseView.vue";
import OrdersView from "../views/OrdersView/OrdersView.vue";
import StartView from "../views/StartView/StartView.vue";
import ResetView from "../views/ResetView/ResetView.vue";
import CartView from "../views/CartView/CartView.vue";
import ProductView from "../views/ProductView.vue";
import ResultView from "../views/ResultView.vue";
import SearchView from "../views/SearchView.vue";

import TruckComponent from "@/views/NewOrders/truckComponent/truckComponent.vue";
import CarsComponent from "@/views/NewOrders/carsComponent/carsComponent.vue";

/// array of paths
export const routerArray = [
  {
    /// home page
    path: "/",
    name: "start",
    component: StartView,
    meta: { guest: true },
  },
  {
    path: "/callback",
    name: "contact_us",
    component: ContactUse,
  },
  {
    /// orders page
    path: "/orders",
    name: "orders",
    component: OrdersView,
    meta: {
      requiresAuth: true,
      typeInactive: true,
    },
  },
  {
    /// new orders page
    path: "/new_orders",
    name: "new_orders",
    redirect: "/new_orders/cars",
    component: NewOrdersView,
    meta: {
      requiresAuth: process.env.VUE_APP_TYPE_SERVER === "retail" ? false : true,
      typeInactive: true,
    },
    children: [
      {
        path: "cars",
        name: "cars",
        component: CarsComponent,
        meta: {
          requiresAuth:
            process.env.VUE_APP_TYPE_SERVER === "retail" ? false : true,
          typeInactive: true,
        },
      },
      {
        path: "truck",
        name: "truck",
        component: TruckComponent,
        meta: {
          requiresAuth:
            process.env.VUE_APP_TYPE_SERVER === "retail" ? false : true,
          typeInactive: true,
        },
      },
    ],
  },
  {
    /// orders page pcr
    path: "/orders/pcr/:id",
    name: "ordersDetailsPcr",
    component: OrderViewPcr,
    meta: {
      requiresAuth: process.env.VUE_APP_TYPE_SERVER === "retail" ? false : true,
      typeInactive: true,
    },
  },
  {
    /// orders page tbr
    path: "/orders/tbr/:id",
    name: "ordersDetailsTbr",
    component: OrderViewTbr,
    meta: {
      requiresAuth: process.env.VUE_APP_TYPE_SERVER === "retail" ? false : true,
      typeInactive: true,
    },
  },
  {
    /// auth page (login/registration/change password)
    path: "/auth",
    name: "auth",
    component: AuthorizationView,
    meta: { guest: true },
  },
  {
    path: "/private_policy",
    name: "private_policy",
    component: PrivatePolicy,
  },
  {
    /// user's personal account
    path: "/account",
    name: "account",
    // component: AccountView,
    component: AccountDetailsViewVue,
    meta: { requiresAuth: true },
  },
  // {
  //   /// account detail page
  //   path: '/details',
  //   name: 'accountDetails',
  //   component: AccountDetailsViewVue,
  //   meta: { requiresAuth: true }
  // },
  {
    path: "/update",
    name: "updatePassword",
    component: UpdatePasswordView,
    meta: {
      requiresAuth: true,
      typeInactive: true,
    },
  },
  {
    /// user account
    path: "/account/:id",
    name: "accountUser",
    component: AccountView,
    meta: {
      requiresAuth: true,
      typeInactive: true,
    },
  },
  {
    /// search page
    path: "/search",
    name: "search",
    component: SearchView,
    meta: {
      requiresAuth: true,
      typeInactive: true,
    },
    children: [
      {
        /// results page
        path: "result",
        name: "result",
        component: ResultView,
      },
    ],
  },
  {
    /// product page/{id - product}
    path: "/product/:id",
    name: "product",
    component: ProductView,
    meta: {
      requiresAuth: process.env.VUE_APP_TYPE_SERVER === "retail" ? false : true,
      typeInactive: true,
    },
  },
  {
    /// cart page
    path: "/cart",
    name: "cart",
    component: CartView,
    meta: {
      requiresAuth: process.env.VUE_APP_TYPE_SERVER === "retail" ? false : true,
      typeInactive: true,
    },
  },
  {
    /// password change page
    path: "/reset",
    name: "reset",
    component: ResetView,
    meta: { guest: true },
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: NotFoundView,
  },
];
