<template>
    <div class="ml-5 pl-5 mt-5 pt-2">
        <div class="ml-4">
           <h1 class="title-big t-blue uppercase">{{ $tc('my_account') }}</h1>
           <div class="pr-5">

                <div class="d-flex">

                    <div class="wrapper-link mr-5 mt-5">
                        <div class="bb-solid b-dark mb-3 pb-3">
                            <router-link  :to="{name: 'start'}" class="t-dark text-raleway-20 text-decoration-none">{{ $tc('order_history_and_tracking') }}</router-link>
                        </div>
                        <div class="bb-solid b-dark mb-3 pb-3">
                            <router-link  :to="{name: 'start'}" class="t-dark text-raleway-20 text-decoration-none">{{ $tc('vishicles') }}</router-link>
                        </div>
                        <div class="bb-solid b-dark mb-3 pb-3">
                            <router-link  :to="{name: 'accountDetails'}" class="t-dark text-raleway-20 text-decoration-none">{{ $tc('account_details') }}</router-link>
                        </div>
                    </div>

                    <div class="d-flex ml-5 pl-4 direction-column">

                        <h2 class="text-raleway-32-bold t-dark">{{ $tc('order_tracking') }}</h2>

                        <div class="d-flex mt-5">
                            
                            <div class="input-wrapper mb-2 mr-2">
                                <p class="uppercase text-raleway-13">order number</p>
                                <input type="text" name="order_number" class="input"/>
                            </div>

                            <div class="input-wrapper mb-2 ml-2">
                                <p class="uppercase text-raleway-13">Billing ZIP/Postal Code:</p>
                                <input type="text" name="postal_code" class="input"/>
                            </div>

                        </div>

                        <span class="text text-raleway-13 pointer">{{ $tc('need_order_number') }}</span>

                        <div class="mt-4">
                            <button class="btn btn-default">{{ $tc('track') }}</button>
                        </div>

                    </div>

                </div>
           </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
</script>  

<style type="scss">
@import './account.scss';
</style>