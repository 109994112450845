import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { IStore } from "@/store/types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { IAdvertising } from "./type";
import { Module } from "vuex";

export const AdvertisingTypeOperation: Module<IAdvertising, IStore> = {
  state: {
    count: null,
    next: null,
    previous: null,
    isLoading: true,
    isError: false,
    results: [],
  },
  getters: {
    getAllAdvertising(state) {
      return state.results;
    },
  },
  mutations: {
    setAdvertising(state, payload: IAdvertising) {
      state.count = payload.count;
      state.next = payload.next;
      state.previous = payload.previous;
      state.isLoading = false;
      state.results = payload.results;
    },
  },
  actions: {
    async getAdvertising(store) {
      //   const type = store.getters.getUserCustomerTypeData;
      const url = process.env.VUE_APP_SERVER + "api/advertising/";
      //   if (type) url += ("?customer_type=" + type);
      const { data, isError, isLoading } = await useFetch<
        IAdvertising,
        IUseFetchDetailError
      >(url, "GET", true);

      if (isError.value) return (store.state.isError = true);
      if (!isLoading.value && data.value)
        return store.commit("setAdvertising", data.value);
    },
  },
};
