import { EmailNotification, EmailType } from '../../../components/changePassword/type'
import { IUseFetchDetailError, IUseFetchDetailErrorEmail } from '../../../hook/fetch/interface'
import { useFetch } from '../../../hook/fetch/fetch'
import { IStore } from '../../types/StoreType'
import { ChangeTypeOperations } from './type'
import { Module } from "vuex"

export const ChangeTypeOperation: Module<ChangeTypeOperations, IStore> = {
    state: {
        data: '',
        notification: false,
        loading: true,
        error: false,
        message: ''
    },
    getters: {
        dataGetChange(state) {
            return state.data;
        },
        notificationGetChange(state) {
            return state.notification
        },
        loadingGetChange(state) {
            return state.loading
        },
        errorGetChange(state) {
            return state.error
        },
        messageGetChange(state) {
            return state.message
        },
    },
    mutations: {
        dataSetChange(state, payload: string) {
            state.data = payload
        },
        notificationSetChange(state, payload: boolean) {
            state.notification = payload
        },
        loadingSetChange(state, payload: boolean) {
            state.loading = payload
        },
        errorSetChange(state, payload: boolean) {
            state.error = payload
        },
        messageSetChange(state, payload: string) {
            state.message = payload
        }
    },
    actions: {
        async changePassword({ commit }, payload) {
            const {data, isError, errorMessage, isLoading } = await useFetch<EmailNotification, IUseFetchDetailErrorEmail | IUseFetchDetailError>(process.env.VUE_APP_SERVER + 'api/users/reset_password/', 'PUT', false, payload)
            
            commit('errorSetChange', isError.value)
            commit('loadingSetChange', isLoading.value)

            if(isError.value && ((errorMessage.value as IUseFetchDetailErrorEmail)?.email || (errorMessage.value as IUseFetchDetailError)?.detail)){
                commit('messageSetChange', ((errorMessage.value as IUseFetchDetailErrorEmail)?.email || (errorMessage.value as IUseFetchDetailError)?.detail))
            }else{
                commit('dataSetChange', data.value?.success ?? '')
                commit('notificationSetChange', true)
            }
        },
    }
}
