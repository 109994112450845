import { phoneValidation } from "../accauntDetailsValidation/universalMethods";
import * as yup from "yup";

yup.addMethod(yup.mixed, "phoneValidation", phoneValidation);

export const RegistrationDataComponentValidation = yup.object().shape(
  {
    first_name: yup.string().required().label("First name"),
    password: yup.string().required(),
    password_confirm: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
    private: yup.boolean().isTrue().label(""),
    last_name: yup.string().required().label("Last name"),
    user_phone: (yup.mixed() as any).phoneValidation("Phone number is invalid"),
    email: yup.string().required().email().label("Please change email address"),
  },
  [
    ["password_confirm", "password"],
    ["password", "password_confirm"],
  ]
);
