import { IUseFetchDetailError } from "../../../hook/fetch/interface";
import { useFetch } from "../../../hook/fetch/fetch";
import { GeoPlaceUniversal, OptionsType } from "../GeoPlace/type";
import { IStore } from "../../types/StoreType";
import { Bank, BankType } from "./type";
import { Module } from "vuex";
import { store } from "@/store";
import { computed, ref } from "vue";
import { UserTypeOperation } from "../User/type";

export const BankModuleOperation: Module<BankType, IStore> = {
  state: {
    count: 0,
    next: "",
    previous: "",
    isLoading: false,
    isError: false,
    results: [],
    search: {
      count: 0,
      next: "",
      previous: "",
      isLoading: false,
      isError: false,
      results: [],
    },
  },
  getters: {
    getBankData(state) {
      return state;
    },
    getBankSearchCity(state) {
      if (state.search.results.length < 0) return { data: [] };
      return { data: state.search.results, loading: state.search.isLoading };
    },
    getBankDataByUserId(state) {
      return state.results.filter(
        (bank) => bank.customer === store.getters.getUserCustomer
      );
    },
  },
  mutations: {
    updateBank(state, payload: BankType) {
      state.count = payload.count || state.count;
      state.next = payload.next || state.next;
      state.previous = payload.previous || state.previous;
      state.isLoading = payload.isLoading || state.isLoading;
      state.results = [...state.results, ...payload.results];
      store.commit("updateAccountDetailsBankData", payload.results[0]);
    },
    updateBankInUpdate(state, payload: Bank) {
      const filerBank = state.results.filter((bank) => bank.id !== payload.id);
      state.results = [...filerBank, payload];
      store.commit("updateAccountDetailsBankData", payload);
    },
    updateGeoPlaceCitySearchBank(
      state,
      payload: { data: GeoPlaceUniversal; isLoading: boolean }
    ) {
      state.search.isLoading = payload.isLoading;
      state.search.count = payload.data.count;
      state.search.next = payload.data.next;
      state.search.previous = payload.data.previous;
      state.search.results =
        payload.data.results.length <= 0
          ? state.search.results
          : payload.data.results;
    },
  },
  actions: {
    async getDataBank({ commit }) {
      const { data, isError, errorMessage, isLoading } = await useFetch<
        BankType,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/accounts/", "GET", true);

      if (!isError.value && !errorMessage.value)
        await commit("updateBank", { ...data.value, isLoading });
    },
    async getSearchCityBank({ commit }, payload: string) {
      const { data, isError, isLoading } = await useFetch<
        GeoPlaceUniversal,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/geo_place/?level=3&name__icontains=" +
          payload +
          "&page_size=10",
        "GET",
        true
      );

      if (!isError.value && data.value)
        commit("updateGeoPlaceCitySearchBank", {
          data: data.value,
          isLoading: isLoading.value,
        });
    },
    async createBank({ commit, state }, payload) {
      commit("updateGlobalErrorsBankDataComponent", "");
      const cityData = store.getters.getBankSearchCity as {
        data: OptionsType[];
      };
      const city = cityData.data.filter(
        (city) => city.name === payload?.bank_city
      );
      console.log(city[0]?.id);
      const userData = ref(
        computed<UserTypeOperation>(() => store.getters.getUserData)
      );
      const { data, isError, errorMessage, isLoading } = await useFetch<
        Bank,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/accounts/", "POST", true, {
        customer: payload?.customerId || userData.value.customer,
        is_main: payload?.is_main,
        number: payload?.number,
        bank_name: payload?.bank_name,
        correspond_account: payload?.correspond_account,
        bank_city: city[0]?.id,
      });

      if (!isError.value && !errorMessage.value) {
        store.commit(
          "updateAccountDetailsBankDataCityName",
          payload?.bank_name
        );
        await commit("updateBankInUpdate", { ...data.value, isLoading });
        return;
      }
      // if (isError.value)
      //   store.commit("setPopupAccount", {
      //     open: true,
      //     message: [
      //       {
      //         "Bank details": errorMessage.value,
      //       },
      //     ],
      //   });
      if (errorMessage.value)
        commit("updateGlobalErrorsBankDataComponent", errorMessage.value);
    },
    async updateBank({ commit }, payload) {
      commit("updateGlobalErrorsBankDataComponent", "");
      const cityData = store.getters.getBankSearchCity as {
        data: OptionsType[];
      };
      const city = cityData.data.filter(
        (city) => city.name === payload?.bank_city
      );
      console.log(cityData, city);
      const { data, isError, errorMessage, isLoading } = await useFetch<
        Bank,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/accounts/" + payload.bankId + "/",
        "PATCH",
        true,
        {
          customer: payload?.customer,
          is_main: payload?.is_main,
          number: payload?.number,
          bank_name: payload?.bank_name,
          correspond_account: payload?.correspond_account,
          bank_city:
            payload?.bank_city?.length > 0
              ? payload?.bankLocation || city[0]?.id
              : null,
        }
      );

      if (!isError.value && !errorMessage.value) {
        store.commit(
          "updateAccountDetailsBankDataCityName",
          payload?.bank_city
        );
        await commit("updateBankInUpdate", { ...data.value, isLoading });
      }
      if (isError.value)
        store.commit("setPopupAccount", {
          open: true,
          message: [
            {
              "Bank details": errorMessage.value,
            },
          ],
        });
    },
  },
};
