import { MessageLocaleType } from "./locales/type";
import { createI18n } from "vue-i18n";
import { store } from "@/store";

const loadLocalMessages = () => {
  const locales = require.context("./locales", true, /\.json$/i);
  const messages: MessageLocaleType = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/[A-Za-z0-9_]+/i);
    if (matched) {
      const locale = matched[0];
      store.dispatch("addLanguage", locale);
      messages[locale] = locales(key);
    }
  });
  return messages;
};

const messages = loadLocalMessages();

export const i18n = createI18n({
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || "English",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "English",
  messages,
});
