import { IUseFetchDetailError } from '@/hook/fetch/interface'
import { IStore } from '../../types/StoreType'
import { useFetch } from '@/hook/fetch/fetch'
import { Currency, ICurrency } from './type'
import { Module } from 'vuex'
import { Costumers } from '../Customers/type'

export const CurrencyiesModuleOperation: Module<ICurrency, IStore> = {
    state: {
        count: 0,
        next: null,
        previous: null,
        isLoading: true,
        isError: false,
        results: [],
        selectedCurrency: null,
    },
    getters: {
        getAllCurrencies(state) {
            return state.results
        },
        getSelectedCurrency(state) {
            return state.selectedCurrency
        },
        getCurrencyLoading(state) {
            return state.isLoading
        }
    },
    mutations: {
        updateAllCurrencies(state, payload: ICurrency) {
            state.count = payload.count
            state.next = payload.next
            state.previous = payload.previous
            state.isLoading = payload.isLoading
            state.results = [...payload.results]
        },
        updateSelectedCurrency(state, payload: number) {
            state.selectedCurrency = state.results.find((curr) => curr.id === payload)?.code || null
        },
        updateSelectCurrency(state, payload: string){
            state.selectedCurrency = payload;
        }
    },
    actions: {
        async getAllCurrencies(store) {

            await useFetch<ICurrency, IUseFetchDetailError>(process.env.VUE_APP_SERVER + 'api/currencies/', 'GET', true)
            .then(({ data, isError, isLoading }) => {

                if(!isError.value && data.value) {

                    const customerId = store.getters.getUserCustomer
                    
                    store.commit('updateAllCurrencies', {...data.value, isLoading: isLoading.value})

                    if(store.getters.getUserCustomer) store.commit('updateSelectedCurrency', customerId)
                }
            })
        },
        async updatedCurrenciesSelected(store, payload: string) {

            const customerId = store.getters.getUserCustomer

            const findCurrencyId = (store.getters.getAllCurrencies as Currency[]).find((curr) => curr.code === payload)?.id

            if(!customerId && !findCurrencyId) return
            
            await useFetch<Costumers, IUseFetchDetailError>(process.env.VUE_APP_SERVER + 'api/customers/' + customerId + '/', 'PATCH', true, {
                currency: findCurrencyId,
            }).then(() => {
                store.commit('updateSelectCurrency', payload)
            })
        },
    }
}