import {
  IBrand,
  IBrandCars,
  IBrandTruck,
  CARS,
  ISearchWheel,
  TRUCK,
  PayloadSearch,
  IResultSearchStore,
} from "./type";
import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { IStore } from "../../types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { store } from "@/store";
import { Module } from "vuex";

export const SearchTypeOperation: Module<ISearchWheel, IStore> = {
  state: {
    cars: {
      count: 0,
      next: null,
      previous: null,
      isLoading: true,
      isError: false,
      results: [],
      search: true,
    },
    truck: {
      count: 0,
      next: null,
      previous: null,
      isLoading: true,
      isError: false,
      results: [],
      search: true,
    },
    brand: {
      cars: {
        count: 0,
        next: null,
        previous: null,
        isLoading: true,
        isError: false,
        results: [],
      },
      truck: {
        count: 0,
        next: null,
        previous: null,
        isLoading: true,
        isError: false,
        results: [],
      },
    },
    axisTruck: {
      count: 0,
      next: null,
      previous: null,
      isLoading: true,
      isError: false,
      results: [],
    },
    cameraTruck: {
      count: 0,
      next: null,
      previous: null,
      isLoading: true,
      isError: false,
      results: [],
    },
  },
  getters: {
    getSearchStateCars(state) {
      return state.cars.search;
    },
    getSearchStateTruck(state) {
      return state.truck.search;
    },
    getSearchCars(state) {
      return state.cars;
    },
    getSearchTruck(state) {
      return state.truck;
    },
    getBrands(state) {
      return state.brand;
    },
    getBrandsArrayInCars(state) {
      const result = state?.brand?.cars?.results.map((brand) => {
        return { id: brand?.id, name: brand?.name };
      });

      const sort = result.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return sort;
    },
    getBrandsArrayInTruck(state) {
      const result = state?.brand?.truck?.results.map((brand) => {
        return { id: brand?.id, name: brand?.name };
      });

      const sort = result.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return sort;
    },
    getAxisTruck(state) {
      return state.axisTruck;
    },
    getAxisTruckArray(state) {
      return state.axisTruck.results.map((axis) => {
        return { id: axis.id, name: axis.name };
      });
    },
    // getCameraTruck(state) {
    //     return state.cameraTruck
    // },
    // getCameraTruckArray(state){
    //     return state.cameraTruck.results.map((camera) => {
    //         return {id: camera.id, name: camera.name}
    //     })
    // },
  },
  mutations: {
    setSearchStateCars(state, payload = !state.cars.search) {
      state.cars.search = payload;
    },
    setSearchStateTruck(state, payload = !state.truck.search) {
      state.truck.search = payload;
    },
    updateSearchCars(
      state,
      payload: { data: IResultSearchStore<CARS>; isLoading: boolean }
    ) {
      state.cars.isError = false;
      state.cars.count = payload.data.count;
      state.cars.isLoading = payload.isLoading;
      state.cars.next = payload.data.next;
      state.cars.previous = payload.data.previous;
      state.cars.results = [...payload.data.results];
    },
    updateSearchTruck(
      state,
      payload: { data: IResultSearchStore<TRUCK>; isLoading: boolean }
    ) {
      state.truck.isError = false;
      state.truck.count = payload.data.count;
      state.truck.isLoading = payload.isLoading;
      state.truck.next = payload.data.next;
      state.truck.previous = payload.data.previous;
      state.truck.results = [...payload.data.results];
    },
    updateBrandCars(state, payload: { data: IBrandCars; isLoading: boolean }) {
      state.brand.cars.count = payload.data.count;
      state.brand.cars.isLoading = payload.isLoading;
      state.brand.cars.next = payload.data.next;
      state.brand.cars.previous = payload.data.previous;

      const filterData =
        state.brand.cars.results.filter((data_1) =>
          payload.data.results.some(
            (data_2) => data_1.id === data_2.id && data_1.name !== null
          )
        ) || [];

      state.brand.cars.results = [
        ...filterData,
        ...payload.data.results.filter((data) => data.name !== null),
        { id: -1, name: "All" },
      ];
    },
    updateBrandTruck(
      state,
      payload: { data: IBrandTruck; isLoading: boolean }
    ) {
      state.brand.cars.count = payload.data.count;
      state.brand.cars.isLoading = payload.isLoading;
      state.brand.cars.next = payload.data.next;
      state.brand.cars.previous = payload.data.previous;

      const filterData =
        state.brand?.truck?.results.filter((data_1) =>
          payload?.data?.results.some((data_2) => data_1.id === data_2.id)
        ) || [];

      state.brand.truck.results = [
        ...filterData,
        ...payload.data.results.filter((data) => data.name !== null),
        { id: -1, name: "All" },
      ];
    },
    // updateAxisTruck(state, payload: {data: IAxisTruck, isLoading: boolean}) {
    //     state.axisTruck.count = payload.data.count
    //     state.axisTruck.isLoading = payload.isLoading
    //     state.axisTruck.next = payload.data.next
    //     state.axisTruck.previous = payload.data.previous

    //     const filterData = state.axisTruck.results.filter((data_1) => payload.data.results.some((data_2) => data_1.id === data_2.id))

    //     state.axisTruck.results = [...filterData, ...payload.data.results]
    // },
    // updateCameraTruck(state, payload: {data: ICameraTruck, isLoading: boolean}) {
    //     state.cameraTruck.count = payload.data.count
    //     state.cameraTruck.isLoading = payload.isLoading
    //     state.cameraTruck.next = payload.data.next
    //     state.cameraTruck.previous = payload.data.previous

    //     const filterData = state.cameraTruck.results.filter((data_1) => payload.data.results.some((data_2) => data_1.id === data_2.id))

    //     state.cameraTruck.results = [...filterData, ...payload.data.results]
    // },
  },
  actions: {
    //////////////////////// CARS //////////////////////////////

    async GetCars({ commit }, payload: PayloadSearch) {
      store.state.SearchTypeOperation.cars.next = "";
      store.state.SearchTypeOperation.cars.isLoading = true;

      const { data, isError, errorMessage, isLoading } = await useFetch<
        CARS,
        IUseFetchDetailError
      >(payload.url, "GET", !!store.getters.accessTokenGet);

      store.state.SearchTypeOperation.cars.isError = isError.value;

      if (
        !isError.value &&
        !errorMessage.value &&
        data.value &&
        !isLoading.value
      )
        commit("updateSearchCars", {
          data: data.value,
          isLoading: isLoading.value,
        });
    },

    async GetBrandCars({ commit }) {
      if (store.getters.getBrandsArrayInCars.length > 0) return;

      const { data, isError, errorMessage, isLoading } = await useFetch<
        IBrand,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/brands/tyres/?tyre_type=pcr&ordering=name",
        "GET",
        !!store.getters.accessTokenGet
      );

      if (
        !isError.value &&
        !errorMessage.value &&
        data.value &&
        !isLoading.value
      )
        commit("updateBrandCars", {
          data: data.value,
          isLoading: isLoading.value,
        });
    },

    //////////////////////// TRUCK /////////////////////////////

    async GetTruck({ commit }, payload: PayloadSearch) {
      store.state.SearchTypeOperation.truck.next = "";
      store.state.SearchTypeOperation.truck.isLoading = true;

      const { data, isError, errorMessage, isLoading } = await useFetch<
        TRUCK,
        IUseFetchDetailError
      >(payload.url, "GET", !!store.getters.accessTokenGet);

      store.state.SearchTypeOperation.truck.isError = isError.value;
      if (
        !isError.value &&
        !errorMessage.value &&
        data.value &&
        !isLoading.value
      )
        commit("updateSearchTruck", {
          data: data.value,
          isLoading: isLoading.value,
        });
    },

    async GetBrandTruck({ commit }) {
      if (store.getters.getBrandsArrayInTruck.length > 0) return;

      const { data, isError, errorMessage, isLoading } = await useFetch<
        IBrand,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/brands/tyres/?tyre_type=tbr&ordering=name",
        "GET",
        !!store.getters.accessTokenGet
      );

      if (
        !isError.value &&
        !errorMessage.value &&
        data.value &&
        !isLoading.value
      )
        commit("updateBrandTruck", {
          data: data.value,
          isLoading: isLoading.value,
        });
    },

    // async GetAxisTruck({commit}) {

    //     if(store.getters.getAxisTruck.results.length > 0) return

    //     const {data, isError, errorMessage, isLoading } = await useFetch<IAxisTruck, IUseFetchDetailError>(process.env.VUE_APP_SERVER + 'api/tyre_tbr/unique/?field=axis', 'GET', true)

    //     if(!isError.value && !errorMessage.value && data.value && !isLoading.value) commit('updateAxisTruck', {data: data.value, isLoading: isLoading.value})
    // },

    // async GetCameraTruck({commit}) {

    //     if(store.getters.getCameraTruck.results.length > 0) return

    //     const {data, isError, errorMessage, isLoading } = await useFetch<ICameraTruck, IUseFetchDetailError>(process.env.VUE_APP_SERVER + 'api/tyre_tbr/unique/?field=camera', 'GET', true)

    //     if(!isError.value && !errorMessage.value && data.value && !isLoading.value) commit('updateCameraTruck', {data: data.value, isLoading: isLoading.value})
    // },
  },
};
