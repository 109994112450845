import { CARS, IResultSearchStore, TRUCK } from "../Search/type";
import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { IStore } from "../../types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { Locations } from "../Locations/type";
import { ICartCars, TypeCart } from "./type";
import { computed, ref } from "vue";
import { store } from "@/store";
import { Module } from "vuex";

export const CartTypeOperation: Module<TypeCart, IStore> = {
  state: {
    results: [],
    sort: "",
    selectedAddress: null,
    initialValue: {
      address: "",
      discount: "",
    },
    margin: "percent",
    loading: false,
    error: "",
  },
  getters: {
    getDiscountCart(state) {
      return state.results.reduce((discount, element) => {
        if (!element.discount_unit && !isNaN(Number(element.discount_unit)))
          return 0 + Number(discount);

        return (
          Number(element.discount_unit) * element.amount + Number(discount)
        );
      }, 0);
    },
    getLoadingCart(state) {
      return state.loading;
    },
    getInitinalValueCart(state) {
      return state.initialValue;
    },
    getCartAll(state) {
      const sortByStockName = (
        data: ICartCars[],
        _key: keyof ICartCars,
        type: string
      ) => {
        if (typeof data === "undefined") return;
        const newSortData = data.sort((a: any, b: any) => {
          if (a.stock_name && b.stock_name) {
            if (a.stock_name === b.stock_name) {
              if (type === "top") return a[_key] - b[_key];
              else return b[_key] - a[_key];
            } else {
              return a.stock_name.localeCompare(b.stock_name);
            }
          }
          return 0;
        });

        return newSortData;
      };

      switch (state.sort) {
        case "external_product_id":
          return sortByStockName(
            state.results,
            "external_product_id",
            "bottom"
          );
        case "-external_product_id":
          return sortByStockName(state.results, "external_product_id", "top");
        case "full_name":
          return sortByStockName(state.results, "full_name", "bottom");
        case "-full_name":
          return sortByStockName(state.results, "full_name", "top");
        case "amount":
          return sortByStockName(state.results, "amount", "bottom");
        case "-amount":
          return sortByStockName(state.results, "amount", "top");
        case "price":
          return sortByStockName(state.results, "price", "bottom");
        case "-price":
          return sortByStockName(state.results, "price", "top");
        default:
          return sortByStockName(state.results, "full_name", "bottom");
      }
    },
    getMargin(state) {
      return state.margin;
    },
    getCart(state) {
      const token = ref(computed<string>(() => store.getters.accessTokenGet));
      if (!token.value) {
        if (localStorage.getItem("cart_user"))
          state.results = [
            ...JSON.parse(localStorage.getItem("cart_user") as string),
          ];
        return state.results;
      }

      return state.results.map((element) => {
        if (typeof element.amount === "string")
          element.amount = ~~element.amount;
        return element;
      });
    },
    getAllPriceCurrentPrice(state) {
      return [...state.results].reduce((price, element) => {
        if (element?.local_total || element?.total_price)
          return +(
            Number(element?.local_total || element?.total_price) + Number(price)
          ).toFixed(2);
        else
          return +(
            Number(
              element?.price_without_vat || element.local_price || element.price
            ) *
              Number(element.amount) +
            Number(price)
          ).toFixed(2);
      }, 0);
    },
    getAllPriceCurrentPriceVat(state) {
      return [...state.results].reduce((price, element) => {
        if (element?.local_total_with_vat || element?.total_inc_vat)
          return +(
            Number(element?.local_total_with_vat || element?.total_inc_vat) +
            Number(price)
          ).toFixed(2);
        else
          return +(
            Number(
              element?.price_with_vat || element.local_price || element.price
            ) *
              Number(element.amount) +
            Number(price)
          ).toFixed(2);
      }, 0);
    },
    getAllCountInCart() {
      return store.getters.getCartAll.reduce(
        (acc: number, obj: ICartCars) => acc + Number(obj.amount),
        0
      );
    },
    getFilerCart(state) {
      return state.sort;
    },
    getCartAllInServer(state) {
      return {
        ...[...state.results].map((element) => {
          return {
            product: element.id,
            amount: element.amount,
            user: store.getters.getUserData?.id,
            price: element.price,
          };
        }),
      };
    },
    getSelectedAddress(state) {
      state.initialValue.address = state.selectedAddress?.full_address || "";
      return state.selectedAddress;
    },
  },
  mutations: {
    setLoadingCart(state, payload: boolean) {
      state.loading = payload;
    },
    updateSelectedAddressCart(state, payload: Locations | null) {
      state.selectedAddress = payload;
    },
    updateUserShoppingCart(state, payload: ICartCars[]) {
      state.results = payload.map((cartElement) => {
        cartElement.updateId = cartElement.id;
        cartElement.id = cartElement.product;
        return cartElement;
      });
    },
    updateUserShoppingCartInUpdate(state, payload: ICartCars) {
      const token = ref(computed<string>(() => store.getters.accessTokenGet));
      const serverType = ref(
        computed<string>(() => store.getters.getServerType)
      );
      payload.updateId = payload.id;
      const find = state.results.findIndex(
        (value) => value.updateId === payload.updateId
      );
      if (token.value) {
        payload.id = payload.product;
        if (find > -1) {
          state.results.splice(find, 1, payload);
        } else state.results.push(payload);
      }
      if (!token.value && serverType.value === "retail") {
        if (find >= 0) state.results.splice(find, 1, payload);
        else state.results.push(payload);
        localStorage.setItem("cart_user", JSON.stringify(state.results));
      }
    },
    addOrDeleteCartLocal(
      state,
      payload: { cars: ICartCars; amount: number; stock: number }
    ) {
      let localData;
      payload.cars.stock = payload.stock;
      switch (payload.cars.classificator) {
        case "PCR": {
          const carSearchResults = ref<IResultSearchStore<CARS>>(
            store?.getters?.getSearchCars
          );
          localData = carSearchResults.value.results.find(
            (car) =>
              +car.product_tyre.id === +payload.cars.id &&
              +car.stock_id === +payload.cars.stock
          );
          break;
        }
        case "TBR": {
          const carSearchResults = ref<IResultSearchStore<TRUCK>>(
            store?.getters?.getSearchTruck
          );
          localData = carSearchResults.value.results.find(
            (car) =>
              +car.product_tyre.id === +payload.cars.id &&
              +car.stock_id === +payload.cars.stock
          );
          break;
        }
        default: {
          const carSearchResults = ref<IResultSearchStore<CARS>>(
            store?.getters?.getSearchCars
          );
          localData = carSearchResults.value.results.find(
            (car) =>
              +car.product_tyre.id === +payload.cars.id &&
              +car.stock_id === +payload.cars.stock
          );
          break;
        }
      }
      payload.cars.stock = payload.stock;
      payload.cars.amount = ~~payload.amount;
      payload.cars.type = "cars";
      if (localData?.stock_id) payload.cars.stock = localData?.stock_id;
      if (localData?.product_photo_path)
        payload.cars.product_photo_path =
          process.env.VUE_APP_SERVER && localData?.product_photo_path
            ? process.env.VUE_APP_SERVER + localData?.product_photo_path
            : "";
      if (localData?.stock_name)
        payload.cars.stock_name = localData?.stock_name || null;
      if (localData?.stock_delivery_time)
        payload.cars.delivery_time = localData?.stock_delivery_time || null;
      payload.cars.price_without_vat = Number(
        payload.cars?.price_without_vat ||
          localData?.price_without_vat ||
          payload.cars?.price_without_vat
      ).toString();
      payload.cars.price_with_vat = Number(
        payload.cars?.price_with_vat ||
          localData?.price_with_vat ||
          payload.cars?.price_with_vat
      ).toString();
      payload.cars.total_price = (
        payload.cars.amount * Number(payload.cars?.price_without_vat)
      ).toString();
      const find = state.results.find(
        (car) => car.id === payload.cars.id && car.stock === payload.cars.stock
      );
      if (payload.cars.amount === 0) {
        if (find)
          store.commit("deleteInCart", { id: find.id, stock: find.stock });
        return;
      }
      if (!find?.id) {
        store.commit("updateUserShoppingCartInUpdate", payload.cars);
      } else {
        find.amount = payload.amount;
        find.total_price = payload.cars.total_price;
        find.discount_unit = payload.cars.discount_unit;
        store.commit("updateUserShoppingCartInUpdate", find);
      }
    },
    updateCartInNoAuthUser(state) {
      const token = ref(computed<string>(() => store.getters.accessTokenGet));
      if (localStorage.getItem("cart_user"))
        state.results = [
          ...JSON.parse(localStorage.getItem("cart_user") as string),
        ];
    },
    setMargin(state, payload: string) {
      state.margin = payload;
    },
    addOrDeleteCart(
      state,
      payload: { cars: ICartCars; amount: number; stock: number }
    ) {
      payload.cars.amount_old = payload.cars.amount;
      payload.cars.amount = ~~payload.amount;
      payload.cars.type = "cars";
      payload.cars.stock = payload.stock;
      payload.cars.total_price =
        payload.cars?.total_price ||
        (payload.cars.amount * Number(payload.cars.price)).toString();
      const find = state.results.find((car) => {
        return +car.id === +payload.cars.id && +car.stock === +payload.stock;
      });
      if (payload.cars.amount === 0) {
        if (find) store.dispatch("dropCartInServerById", find);
        else
          state.results = [
            ...state.results.filter(
              (car) =>
                car.id !== payload.cars.id || +car.stock !== +payload.stock
            ),
          ];
        return;
      }
      if (!find?.id) {
        store.dispatch("createCartInServerById", payload);
      } else {
        find.amount_old = find.amount;
        find.amount = payload.cars.amount;
        find.discount_unit = payload.cars.discount_unit;
        store.dispatch("updateCartInServerById", find);
      }
    },
    updatePriceById(
      state,
      payload: { id: number; vat: boolean; stock: number }
    ) {
      const findCar = state.results.find(
        (cars) => cars.id === payload.id && +cars.stock === payload.stock
      );
      if (findCar)
        findCar.total_price = (
          Number(findCar?.price) * Number(findCar.amount)
        ).toString();
      // !payload.vat
      //     ? (
      //         Number(findCar?.price) * Number(findCar.amount) +
      //         ((Number(findCar?.price) * Number(findCar.amount)) / 100) *
      //           Number(findCar.vat)
      //       ).toFixed(2)
      //     : (Number(findCar?.price) * Number(findCar.amount)).toString();
    },
    deleteInCart(state, payload: { id: number; stock: number }) {
      const token = ref(computed<string>(() => store.getters.accessTokenGet));
      const serverType = ref(
        computed<string>(() => store.getters.getServerType)
      );
      if (!(!token.value && serverType.value === "retail")) {
        const find = state.results.find(
          (car) =>
            Number(car.id) === Number(payload.id) &&
            Number(car.stock) === Number(payload.stock)
        );
        store.dispatch("dropCartInServerById", find);
      }
      if (!token.value && serverType.value === "retail") {
        state.results = state.results.filter(
          (car) =>
            Number(car.id) !== Number(payload.id) ||
            Number(car.stock) !== Number(payload.stock)
        );
        localStorage.setItem(
          "cart_user",
          JSON.stringify(
            state.results.filter(
              (element) =>
                Number(element.id) !== Number(payload.id) ||
                Number(element.stock) !== Number(payload.stock)
            )
          )
        );
      }
    },
    dropAllCart(state) {
      const token = ref(computed<string>(() => store.getters.accessTokenGet));
      const serverType = ref(
        computed<string>(() => store.getters.getServerType)
      );
      if (!token.value && serverType.value === "retail") {
        localStorage.setItem("cart_user", JSON.stringify([]));
        return (state.results = []);
      }
      store.dispatch("dropCartInServer").then(() => [(state.results = [])]);
    },
    changeSortCart(state, payload: string) {
      state.sort = payload;
    },
    dropAllCartBeforeCreate(state) {
      state.results = [];
    },
  },
  actions: {
    async createCartInServerById(
      store,
      payload: { cars: CARS | TRUCK; amount: number; stock: string }
    ) {
      store.state.loading = true;
      store.commit("updateGlobalErrorsLocationsDataComponent", "");
      useFetch<ICartCars, IUseFetchDetailError>(
        process.env.VUE_APP_SERVER + "api/shopping_carts/",
        "POST",
        true,
        {
          product: payload?.cars?.id,
          amount: ~~payload?.amount,
          user:
            store.getters.getUserData?.id ||
            store.getters.getUserData?.external_user_id,
          stock: payload.stock,
        }
      )
        .then(({ data }) => {
          return store.commit("updateUserShoppingCartInUpdate", data.value);
        })
        .catch(({ errorMessage, isLoading }) => {
          const key = `Cart element ${payload.cars.full_name} - create`;
          store.commit("setPopupAccount", {
            open: true,
            message: [
              {
                [key]: { detail: [errorMessage.value?.detail] },
              },
            ],
          });
          store.commit(
            "updateGlobalErrorsLocationsDataComponent",
            errorMessage.value
          );
          return isLoading;
        })
        .finally(() => (store.state.loading = false));
    },
    async updateCartInServerById(store, payload: ICartCars) {
      store.state.loading = true;
      store.commit("updateGlobalErrorsLocationsDataComponent", "");
      const { data, isLoading, isError, errorMessage } = await useFetch<
        ICartCars,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/shopping_carts/" +
          payload.updateId +
          "/",
        "PATCH",
        true,
        {
          product: payload?.id,
          amount: ~~payload?.amount,
          user:
            store.getters.getUserData?.id ||
            store.getters.getUserData?.external_user_id,
        }
      );
      store.state.loading = isLoading.value;
      if (isError.value) {
        const key = `Cart element ${payload?.full_name} - update`;
        store.commit("setPopupAccount", {
          open: true,
          message: [
            {
              [key]: { detail: [errorMessage.value?.detail] },
            },
          ],
        });
        store.commit(
          "updateGlobalErrorsLocationsDataComponent",
          errorMessage.value
        );
        return (payload.amount = payload.amount_old || payload.amount);
      }
      if (data.value && !isLoading.value)
        store.commit("updateUserShoppingCartInUpdate", data.value);
    },
    async dropCartInServer(store) {
      return await Promise.all([
        store.state.results.map(async (elements) => {
          await useFetch<ICartCars, IUseFetchDetailError>(
            process.env.VUE_APP_SERVER +
              "api/shopping_carts/" +
              elements.updateId +
              "/",
            "DELETE",
            true
          );
        }),
      ]);
    },
    async dropCartInServerById({ state }, payload) {
      state.loading = true;
      store.commit("updateGlobalErrorsLocationsDataComponent", "");
      const { isLoading, isError, errorMessage } = await useFetch<
        ICartCars,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER +
          "api/shopping_carts/" +
          payload.updateId +
          "/",
        "DELETE",
        true
      );
      state.loading = isLoading.value;
      if (isError.value) {
        const key = `Cart element ${payload?.full_name} - delete`;
        store.commit("setPopupAccount", {
          open: true,
          message: [
            {
              [key]: { detail: [errorMessage.value?.detail] },
            },
          ],
        });
        store.commit(
          "updateGlobalErrorsLocationsDataComponent",
          errorMessage.value
        );
        return (payload.amount = payload.amount_old || payload.amount);
      }
      state.results = state.results.filter(
        (car) =>
          Number(car.id) !== Number(payload.id) ||
          Number(car.stock) !== Number(payload.stock)
      );
    },
    async createAllCarts(store) {
      if (localStorage.getItem("cart_user")) {
        let new_array = JSON.parse(
          localStorage.getItem("cart_user") as string
        ) as ICartCars[];
        const map_array = await Promise.all(
          new_array.map((wheel: ICartCars) => {
            return {
              cars: wheel,
              amount: wheel.amount,
              stock: wheel.stock,
            };
          })
        );
        await Promise.all(
          map_array.map((wheel) => {
            store.dispatch("createCartInServerById", wheel).then(() => {
              new_array = new_array.filter(
                (_wheel) =>
                  wheel.cars.id !== _wheel.id ||
                  +wheel.cars.stock !== +_wheel.stock
              );
              localStorage.setItem("cart_user", JSON.stringify(new_array));
            });
          })
        );
      }
    },
    async changeSortCart(store, payload) {
      store.commit("changeSortCart", payload);
      const { data, isLoading } = await useFetch<
        TypeCart,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/shopping_carts/?ordering=" + payload,
        "GET",
        true
      );
      if (data.value && !isLoading.value) {
        data.value.results.forEach((element) => {
          store.commit("updateUserShoppingCartInUpdate", element);
        });
      }
    },
    async getShoppingCart(store) {
      const { data, isLoading } = await useFetch<
        TypeCart,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/shopping_carts/", "GET", true);
      if (data.value && !isLoading.value) {
        data.value.results.forEach((element) => {
          store.commit("updateUserShoppingCartInUpdate", element);
        });
      }
    },
  },
};
