import { isValidPhoneNumber } from 'libphonenumber-js'
import { store } from '@/store'

export function phoneValidation(message: string, validateRequred = false) {
    return this.test("isValidCountry", message, function (value: string) {
      if(validateRequred && !store.getters.getSchedulesInSelectedLocation) {

        const { path, createError } = this
        if(!isValidPhoneNumber(value ?? '', 'FI')) return createError({ path, message: message })
    
          return true
      }

      const { path, createError } = this
  
      if(!isValidPhoneNumber(value ?? '', 'FI')) return createError({ path, message: message })
  
        return true

    })
}

export function phoneValidationWithoutPluse(message: string, validateRequred = false) {
  return this.test("isValidCountry", message, function (value: string) {
    if(validateRequred && !store.getters.getSchedulesInSelectedLocation) {

      const { path, createError } = this
      if(!isValidPhoneNumber((value && value) || '')) return createError({ path, message: message })
  
        return true
    }

    const { path, createError } = this
    if(!isValidPhoneNumber((value && value)|| '')) return createError({ path, message: message })

      return true

  })
}