import { store } from '../../store/index'
import jwt_decode from 'jwt-decode'
import { TypeToken } from './type'

export const GetUserType = () => {

    const decodeToken = jwt_decode(store.getters.accessTokenGet) as TypeToken | null

    if(decodeToken) return decodeToken.access_groups
    
    return null
}