import { IUseFetchDetailError } from "@/hook/fetch/interface"
import { CommitType, GeoPlaceData, GeoPlaceType, GeoPlaceUniversal } from "./type"
import { useFetch } from "@/hook/fetch/fetch"
import { store } from "@/store"
import { ref } from "vue"
import { BankType } from "../Bank/type"

export const actionsGeoPlace = {
    async getGeoPlaceCountry({ commit, state }: CommitType) {

        store.state.GeoPlaceModuleOperation.country.isLoading = true

        const {data, isError, errorMessage, isLoading } = await useFetch<GeoPlaceType, IUseFetchDetailError>(state.country.next || (process.env.VUE_APP_SERVER + 'api/geo_place/?level=1&?page=1'), 'GET', true)

        if(!isError.value && !errorMessage.value) await commit('updateGeoPlace',  {...data.value, isLoading: isLoading.value})

    },
    async getGeoPlaceCity({ commit, state }: CommitType) {

        store.state.GeoPlaceModuleOperation.city.isLoading = true

        const {data, isError, errorMessage, isLoading } = await useFetch<GeoPlaceType, IUseFetchDetailError>(state.city.next || (process.env.VUE_APP_SERVER + 'api/geo_place/?level=2?page=1'), 'GET', true)

        if(!isError.value && !errorMessage.value) await commit('updateGeoPlace',  {...data.value, isLoading: isLoading.value})

    },
    async getGeoPlaceRegion({ commit, state }: CommitType) {

        store.state.GeoPlaceModuleOperation.region.isLoading = true

        const {data, isError, errorMessage, isLoading } = await useFetch<GeoPlaceType, IUseFetchDetailError>(state.region.next || (process.env.VUE_APP_SERVER + 'api/geo_place/?level=3?page=1'), 'GET', true)

        if(!isError.value && !errorMessage.value) await commit('updateGeoPlace',  {...data.value, isLoading: isLoading.value})

    },
    async getSearchCity({ commit }: CommitType, payload: {search: string, region: string}) {

        store.state.GeoPlaceModuleOperation.search.city.loading = true

        const { data, isError, isLoading } = await useFetch<GeoPlaceUniversal, IUseFetchDetailError>(process.env.VUE_APP_SERVER + 'api/geo_place/?level=3&name__icontains=' + payload.search + '&page_size=10&region=' + payload.region, 'GET', true)

        if(!isError.value && data.value) commit('updateGeoPlaceCitySearch', {data: data.value.results, loading: isLoading.value})
    },
    async getSearchCountry({ commit }: CommitType, payload: string) {

        store.state.GeoPlaceModuleOperation.search.country.loading = true

        const { data, isError, isLoading } = await useFetch<GeoPlaceUniversal, IUseFetchDetailError>(process.env.VUE_APP_SERVER + 'api/geo_place/?level=1&name__icontains=' + payload + '&page_size=10', 'GET', true)

        if(!isError.value && data.value) commit('updateGeoPlaceCountrySearch', {data: data.value.results, loading: isLoading.value})
    },
    async getSearchRegion({ commit }: CommitType, payload: {search: string, country: string}) {

        store.state.GeoPlaceModuleOperation.search.region.loading = true

        const { data, isError, isLoading } = await useFetch<GeoPlaceUniversal, IUseFetchDetailError>(process.env.VUE_APP_SERVER + 'api/geo_place/?level=2&name__icontains=' + payload.search + '&page_size=10&country=' + payload.country, 'GET', true)

        if(!isError.value && data.value) commit('updateGeoPlaceRegionSearch', {data: data.value.results, loading: isLoading.value})
    },
    async getCityInBank({ commit }: CommitType) {
        const banks = ref<BankType>(store.getters.getBankData).value.results
        const searchCityByCustomer = banks.filter((bank) => bank?.customer === store.getters?.getUserCustomer)

        if(searchCityByCustomer.length <= 0 || !searchCityByCustomer[0]?.bank_city) return

        const { data, isError } = await useFetch<GeoPlaceData, IUseFetchDetailError>(process.env.VUE_APP_SERVER + 'api/geo_place/' + searchCityByCustomer[0].bank_city + '/', 'GET', true)

        if(!isError.value && data.value){ 
            commit('updateLocationsBank', data.value)
            commit('updateAccountDetailsBankDataCityName', data.value.name)
        }
    },
}