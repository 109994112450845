import { IPostalcode, SearchPayload, UpdateValueType } from "./type";
import { IPostalcodeSearch, IParentElementPostalCode } from "./type";
import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { IStore } from "../../types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { store } from "@/store";
import { Module } from "vuex";
import { SearcInputType } from "../GeoPlace/type";

export const PostalCodeStockModuleOperation: Module<IPostalcode, IStore> = {
  state: {
    country: {
      count: null,
      next: null,
      previous: null,
      isLoading: true,
      isError: false,
      results: null,
      select: null,
      options: null,
    },
    region: {
      count: null,
      next: null,
      previous: null,
      isLoading: true,
      isError: false,
      results: [],
      select: null,
      options: [],
    },
    city: {
      count: null,
      next: null,
      previous: null,
      isLoading: true,
      isError: false,
      results: [],
      select: null,
      options: [],
    },
    postalcode: {
      count: null,
      next: null,
      previous: null,
      isLoading: true,
      isError: false,
      results: [],
      select: null,
      options: [],
    },
  },
  getters: {
    getCountryStockPostalcodeOptions(state) {
      return {
        data: state.country.results,
        loading: state.country.isLoading,
      };
    },
    getRegionStockPostalcodeOptions(state) {
      let filter = state.region.results || [];
      if (state.postalcode.results && state.postalcode.results?.length > 0)
        filter =
          state.region.results?.filter((region) =>
            state.postalcode.results?.some(
              (postalcode) => postalcode.geo_region === region.name
            )
          ) || [];
      if (state.postalcode.select?.data?.geo_region)
        filter =
          state.region.results?.filter(
            (region) =>
              region.name === state.postalcode.select?.data?.geo_region
          ) || [];

      return {
        data: filter.length <= 0 ? state.region.results : filter,
        loading: state.region.isLoading,
      };
    },
    getCityStockPostalcodeOptions(state): {
      data: SearcInputType[];
      loading: boolean;
    } {
      if (!state.postalcode?.results || state.postalcode?.results?.length <= 0)
        return { data: [], loading: false };

      if (state.postalcode?.results.length === 1)
        return {
          data: state.postalcode?.results.map((_) => {
            return {
              id: _.id,
              value: _.geo_name,
              data: _,
            };
          }),
          loading: state.postalcode.isLoading,
        };
      let data = state.postalcode?.results
        .map((_) => {
          return {
            id: _.id,
            value: _.geo_name,
            data: _,
          };
        })
        .filter(
          (v, i, a) =>
            a.findIndex((v2) => v2.data.geo_name === v.data.geo_name) === i
        );

      if (state.region.select?.name)
        data = data.filter(
          (city) => city.data.geo_region === state.region.select?.name
        );
      if (state.postalcode.select)
        data = data.filter(
          (city) =>
            city.data.geo_name === state.postalcode.select?.data?.geo_name
        );
      return { data, loading: state.postalcode.isLoading };
    },
    getPostalcodeStockPostalcodeOptions(state) {
      if (!state.postalcode?.results || state.postalcode?.results?.length <= 0)
        return { data: [], loading: false };

      const data = state.postalcode?.results.map((_) => {
        return {
          id: _.id,
          value: _.postal_code,
          data: _,
        };
      });

      return { data, loading: state.postalcode.isLoading };
    },
    getStatePostalcodeStock(state) {
      return state;
    },
    getCountryStockSelectPostalCode(state) {
      return state.country.select;
    },
    getRegionStockSelectPostalCode(state) {
      return state.region.select;
    },
    getCityStockSelectPostalCode(state) {
      return state.city.select;
    },
    getPostalcodeStockSelectPostalCode(state) {
      return state.postalcode.select;
    },
  },
  mutations: {
    updateCopyPostalcodeValues(state, payload: IPostalcode) {
      if (payload.region.select)
        state.region.select = { ...payload.region.select };
      if (payload.city.select) state.city.select = { ...payload.city.select };
      if (payload.country.select)
        state.country.select = { ...payload.country.select };
      if (payload.postalcode.select)
        state.postalcode.select = { ...payload.postalcode.select };
    },
    updateCountryStockResult(
      state,
      payload: UpdateValueType<IParentElementPostalCode>
    ) {
      state.country.results = payload.data.results;
      state.country.isLoading = payload.loading;
    },
    updateRegionStockResult(
      state,
      payload: UpdateValueType<IParentElementPostalCode>
    ) {
      state.region.results = payload.data.results;
      state.region.isLoading = payload.loading;
    },
    updateCityStockResult(state, payload: UpdateValueType<IPostalcodeSearch>) {
      state.city.results = payload.data.results;
      state.city.isLoading = payload.loading;
    },
    updatePostalcodeStockResult(
      state,
      payload: UpdateValueType<IPostalcodeSearch>
    ) {
      state.postalcode.results = payload.data.results;
      state.postalcode.isLoading = payload.loading;
    },
    updateCountryStockSelectPostalCode(state, payload) {
      state.country.isLoading = false;
      state.country.select = { ...payload };

      state.city = {
        count: null,
        next: null,
        previous: null,
        isLoading: true,
        isError: false,
        results: [],
        select: null,
        options: [],
      };

      state.region = {
        count: null,
        next: null,
        previous: null,
        isLoading: true,
        isError: false,
        results: [],
        select: null,
        options: [],
      };

      state.postalcode = {
        count: null,
        next: null,
        previous: null,
        isLoading: true,
        isError: false,
        results: [],
        select: null,
        options: [],
      };
    },
    updateRegionStockSelectPostalCode(state, payload) {
      state.region.isLoading = false;
      state.region.select = { ...payload };
    },
    updateCityStockSelectPostalCode(state, payload) {
      state.city.isLoading = false;
      if (Array.isArray(payload)) state.city.select = payload[0];
      else state.city.select = { ...payload };
    },
    updatePostalcodeStockSelectPostalCode(state, payload) {
      state.postalcode.isLoading = false;
      if (Array.isArray(payload)) state.postalcode.select = { ...payload[0] };
      else state.postalcode.select = { ...payload };

      store.commit(
        "changeSelectedCountryStockPostalCode",
        state.postalcode.select
      );
    },
    changeSelectedPostalCodeStockPostalCode(state, payload) {
      if (state.postalcode?.select) state.postalcode.select.value = payload;
    },
    changeSelectedCountryStockPostalCode(
      state,
      payload: SearcInputType | null | undefined
    ) {
      state.country.select = state.country.results?.find(
        (country) => country.name === payload?.data?.geo_country
      );
      if (state.country.select && state.country.select?.name) {
        const filter = state.country.results?.find(
          (country) => country.name !== state.country.select?.name
        );
        if (filter) {
          state.country.results = [state.country.select, filter];
        }
        return;
      }

      state.country.select = state.country.results?.find(
        (country) =>
          country.name ===
          store.getters?.getPersonalDataComponenValuesAccountDetails.country
      );
      if (state.country.select) {
        const filter = state.country.results?.find(
          (country) => country.name !== state.country.select?.name
        );
        if (filter) {
          state.country.results = [state.country.select, filter];
        }
        return;
      }
    },
  },
  actions: {
    async getCountryStockPostalcode({ commit, state }) {
      state.country.isLoading = true;
      if (state.country?.results && state.country?.results?.length > 0) return;
      const { data, isError, isLoading } = await useFetch<
        IParentElementPostalCode,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/geo_place/?level=1", "GET", true);

      if (
        !isError.value &&
        data.value &&
        data.value.count &&
        data.value.count > 0
      ) {
        commit("updateCountryStockResult", {
          data: data.value,
          loading: isLoading.value,
        });
        commit("changeSelectedCountryStockPostalCode");
        store.commit("updatePersonalDataComponenValuesChedules");
      }
    },
    async getRegionStockPostalcode({ commit, state }) {
      state.region.isLoading = true;

      let url = process.env.VUE_APP_SERVER + "api/geo_place/?level=2";

      if (state.country.select?.name)
        url += "&country=" + state.country.select?.name;

      const { data, isError, isLoading } = await useFetch<
        IParentElementPostalCode,
        IUseFetchDetailError
      >(url, "GET", true);

      if (
        !isError.value &&
        data.value &&
        data.value.count &&
        data.value.count > 0
      )
        commit("updateRegionStockResult", {
          data: data.value,
          loading: isLoading.value,
        });
    },
    async getCityStockPostalcode({ commit, state }) {
      state.region.isLoading = true;

      let url =
        process.env.VUE_APP_SERVER + "api/postal_codes/?geo_place__level=3";

      if (state.region.select?.name)
        url += "&geo_region=" + state.region.select?.name;
      if (state.country.select?.name)
        url += "&geo_country=" + state.country.select?.name;

      const { data, isError, isLoading } = await useFetch<
        IPostalcodeSearch,
        IUseFetchDetailError
      >(url, "GET", true);

      if (
        !isError.value &&
        data.value &&
        data.value.count &&
        data.value.count > 0
      )
        commit("updatePostalcodeStockResult", {
          data: data.value,
          loading: isLoading.value,
        });
    },
    async getPostalcodeStockPostalcode(
      { commit, dispatch, state },
      payload: SearchPayload
    ) {
      state.region.isLoading = true;

      let url =
        process.env.VUE_APP_SERVER + "api/postal_codes/?geo_place__level=3";
      if (state.country.select?.name)
        url += "&geo_country=" + state.country.select?.name;
      if (payload?.value && payload?.value?.length > 0)
        url += "&postal_code__startswith=" + payload.value;

      const { data, isError, isLoading } = await useFetch<
        IPostalcodeSearch,
        IUseFetchDetailError
      >(url, "GET", true);

      if (
        !isError.value &&
        data.value &&
        data.value.count &&
        data.value.count > 0
      ) {
        commit("updatePostalcodeStockResult", {
          data: data.value,
          loading: isLoading.value,
        });
        commit("changeSelectedPostalCodeStockPostalCode", payload?.value);
        await dispatch("getRegionStockPostalcode");
      }
    },
  },
};
