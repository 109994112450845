import { IStore } from "@/store/types/StoreType";
import { CallbackType } from "./type";
import { Module } from "vuex";

export const CallbackModuleOperation: Module<CallbackType, IStore> = {
  state: {
    first_name: "",
    email: "",
    message: "",
  },
  getters: {
    getInitialValuesCallbackModuleOperation(state) {
      return state;
    },
  },
  actions: {},
};
