<template>
  <div
    class="wrapper btn-order position-sticky w-100 d-flex a-i-end j-content-end"
  >
    <router-link
      class="btn btn-default-sm btn-make_order text-nowrap btn-order position-sticky text-decoration-none"
      type="button"
      :to="{ name: 'cart' }"
      >{{ $t("Make order") }}</router-link
    >
  </div>
</template>

<style lang="scss">
@import "./makeOrder.scss";
</style>
