import jwt_decode from "jwt-decode"
import { TypeToken } from './type'
import { store } from '@/store'

export const getUserId = () => {
    
    const decodeToken = jwt_decode(store.getters.accessTokenGet) as TypeToken | null

    if(decodeToken) return decodeToken.user_id
    
    return null
}