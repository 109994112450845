import { postalcodeType } from "@/store/modules/Postalcode/type";
import { Locations } from "@/store/modules/Locations/type";
import { phoneValidation } from "./universalMethods";
import { computed, ref } from "vue";
import { store } from "@/store";
import * as yup from "yup";
import { GeoPlaceData } from "@/store/modules/GeoPlace/type";

yup.addMethod(yup.mixed, "phoneValidation", phoneValidation);

const selectedId = ref(
  computed<number>(() => store.getters?.getSelectedSchedulesInLocation)
);
const locationSelected = ref(
  computed<Locations[]>(
    () => store?.getters?.getStockLocationsInSelectedAddress
  )
);

// const validateNumberInt = (inputString: string | null | undefined) => {
//     if(!inputString) return false
//     const integer = parseInt(inputString, 10)
//     if (!isNaN(integer) && integer >= -999 && integer <= 999) return true
//     else return false
// }

// const validateNumberFloat = (inputString: string | null | undefined) => {
//     if(!inputString) return false
//     const float = parseFloat(inputString)
//     if (!isNaN(float) && (float.toString().length <= inputString.length) && inputString.length <= (float < 0 ? 12 : 11)) return true
//     else return false
// }

const searchCountryStockPostalCode = (value: string | null | undefined) => {
  if (!value && selectedId.value !== -1) return false;
  return true;
};

const searchRegionStockPostalCode = (value: string | null | undefined) => {
  if (!value || (value && value?.length <= 0 && selectedId.value !== -1))
    return false;
  return true;
};

const searchCityStockPostalCode = (value: string | null | undefined) => {
  if (!value && selectedId.value !== -1) return false;
  return true;
};

const checkAll = (
  buyer_name_stock: string,
  buyer_phone_stock: string,
  country_stock: string,
  region_stock: string,
  city_stock: string,
  buyer_address_stock: string
) => {
  if (
    country_stock?.length > 0 ||
    buyer_name_stock?.length > 0 ||
    buyer_phone_stock?.length > 0 ||
    region_stock?.length > 0 ||
    city_stock?.length > 0 ||
    buyer_address_stock?.length > 0
  ) {
    return true;
  }
};

export const DeliveryDataComponentValidation = yup.object().shape(
  {
    buyer_name_stock: yup
      .string()
      .notRequired()
      .when(
        [
          "buyer_name_stock",
          "buyer_phone_stock",
          "country_stock",
          "region_stock",
          "city_stock",
          "buyer_address_stock",
        ],
        {
          is: checkAll,
          then: (rule) => rule.min(2).max(55).label("Contact person"),
        }
      ),
    buyer_phone_stock: yup
      .string()
      .notRequired()
      .when(
        [
          "buyer_name_stock",
          "buyer_phone_stock",
          "country_stock",
          "region_stock",
          "city_stock",
          "buyer_address_stock",
        ],
        {
          is: checkAll,
          then: () =>
            (yup.mixed() as any).phoneValidation(
              "Phone number is invalid",
              true
            ),
        }
      ),
    country_stock: yup
      .string()
      .notRequired()
      .when(
        [
          "buyer_name_stock",
          "buyer_phone_stock",
          "country_stock",
          "region_stock",
          "city_stock",
          "buyer_address_stock",
        ],
        {
          is: checkAll,
          then: (rule) =>
            rule.test("country_stock", (value) =>
              searchCountryStockPostalCode(value)
            ),
        }
      ),
    region_stock: yup
      .string()
      .notRequired()
      .when(
        [
          "buyer_name_stock",
          "buyer_phone_stock",
          "country_stock",
          "region_stock",
          "city_stock",
          "buyer_address_stock",
        ],
        {
          is: checkAll,
          then: (_rule) => _rule.test("region_stock", () => true),
        }
      )
      .when("country_stock", {
        is: (value: string) => searchCountryStockPostalCode(value),
        then: (_rule) =>
          _rule.test("region_stock", (value) =>
            searchRegionStockPostalCode(value)
          ),
      }),
    city_stock: yup
      .string()
      .notRequired()
      .when(
        [
          "buyer_name_stock",
          "buyer_phone_stock",
          "country_stock",
          "region_stock",
          "city_stock",
          "buyer_address_stock",
        ],
        {
          is: checkAll,
          then: (_rule) => _rule.test("city_stock", () => true),
        }
      )
      .when("region_stock", {
        is: (value: string) =>
          searchRegionStockPostalCode(value) &&
          searchCountryStockPostalCode(value),
        then: (rule) =>
          rule.test("city_stock", (value) => searchCityStockPostalCode(value)),
      }),
    buyer_address_stock: yup
      .string()
      .notRequired()
      .when(
        [
          "buyer_name_stock",
          "buyer_phone_stock",
          "country_stock",
          "region_stock",
          "city_stock",
          "buyer_address_stock",
        ],
        {
          is: checkAll,
          then: (rule) => rule.min(2).max(55).label("Address"),
        }
      ),
    // longitude: yup.string().when('longitude', {
    //     is: (value: string) => value?.length > 0,
    //     then: (rule) => rule.test('longitude', (value) => validateNumberInt(value))
    //     .label('Integer number length must be between -999 and 999. Longitude'),
    // })
    // .when('longitude', {
    //     is: (value: string) => value?.length > 0 && validateNumberInt(value),
    //     then: (rule) => rule.test('longitude', (value) => validateNumberFloat(value))
    //     .label('The maximum length after the higher one must not exceed 7 characters. Longitude'),
    // }),
    // latitude: yup.string().when('latitude', {
    //     is: (value: string) => value?.length > 0,
    //     then: (rule) => rule.test('latitude', (value) => validateNumberInt(value))
    //     .label('Integer number length must be between -999 and 999. Latitude'),
    // })
    // .when('latitude', {
    //     is: (value: string) => value?.length > 0 && validateNumberInt(value),
    //     then: (rule) => rule.test('latitude', (value) => validateNumberFloat(value))
    //     .label('The maximum length after the higher one must not exceed 7 characters. Latitude'),
    // }),
  },
  [
    ["buyer_address_stock", "buyer_address_stock"],
    ["buyer_address_stock", "buyer_phone_stock"],
    ["buyer_address_stock", "buyer_name_stock"],
    ["buyer_address_stock", "country_stock"],
    ["buyer_address_stock", "region_stock"],
    ["buyer_address_stock", "city_stock"],
    // ['buyer_address_stock', 'longitude'],
    // ['buyer_address_stock', 'latitude'],

    ["buyer_phone_stock", "buyer_address_stock"],
    ["buyer_phone_stock", "buyer_phone_stock"],
    ["buyer_phone_stock", "buyer_name_stock"],
    ["buyer_phone_stock", "country_stock"],
    ["buyer_phone_stock", "region_stock"],
    ["buyer_phone_stock", "city_stock"],
    // ['buyer_phone_stock', 'longitude'],
    // ['buyer_phone_stock', 'latitude'],

    ["buyer_name_stock", "buyer_address_stock"],
    ["buyer_name_stock", "buyer_phone_stock"],
    ["buyer_name_stock", "buyer_name_stock"],
    ["buyer_name_stock", "country_stock"],
    ["buyer_name_stock", "region_stock"],
    ["buyer_name_stock", "city_stock"],
    // ['buyer_name_stock', 'longitude'],
    // ['buyer_name_stock', 'latitude'],

    ["country_stock", "buyer_address_stock"],
    ["country_stock", "buyer_phone_stock"],
    ["country_stock", "buyer_name_stock"],
    ["country_stock", "country_stock"],
    ["country_stock", "region_stock"],
    ["country_stock", "city_stock"],
    // ['country_stock', 'longitude'],
    // ['country_stock', 'latitude'],

    ["region_stock", "buyer_address_stock"],
    ["region_stock", "buyer_phone_stock"],
    ["region_stock", "buyer_name_stock"],
    ["region_stock", "country_stock"],
    ["region_stock", "region_stock"],
    ["region_stock", "city_stock"],
    // ['region_stock', 'longitude'],
    // ['region_stock', 'latitude'],

    ["city_stock", "buyer_address_stock"],
    ["city_stock", "buyer_phone_stock"],
    ["city_stock", "buyer_name_stock"],
    ["city_stock", "country_stock"],
    ["city_stock", "region_stock"],
    ["city_stock", "city_stock"],
    // ['city_stock', 'longitude'],
    // ['city_stock', 'latitude'],

    ["longitude", "buyer_address_stock"],
    ["longitude", "buyer_phone_stock"],
    ["longitude", "buyer_name_stock"],
    ["longitude", "country_stock"],
    ["longitude", "region_stock"],
    ["longitude", "city_stock"],
    // ['longitude', 'longitude'],
    // ['longitude', 'latitude'],

    ["latitude", "buyer_address_stock"],
    ["latitude", "buyer_phone_stock"],
    ["latitude", "buyer_name_stock"],
    ["latitude", "country_stock"],
    ["latitude", "region_stock"],
    ["latitude", "city_stock"],
    // ['latitude', 'longitude'],
    // ['latitude', 'latitude'],
  ]
);
