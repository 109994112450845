
import NavigationComponent from "./components/navigation/NavigationComponent.vue";
import PreloaderComponent from "./components/preloader/preloaderComponent.vue";
import WaitComponent from "./components/waitComponent/waitComponent.vue";
import FooterComponent from "./components/footer/FooterComponent.vue";
import HeaderComponent from "./components/header/HeaderComponent.vue";
import { computed, defineComponent, ref } from "vue";
import { IStore } from "./store/types/StoreType";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import ErrorMessageComponent from "@/components/errorMessage/errorMessageView.vue";
import ErrorGlobalView from "@/components/errorGlobal/errorGlobalView.vue";
import TreeItemsComponent from "@/components/tree/treeItemsComponent.vue";
import { popupAccountType } from "./store/modules/User/type";

export default defineComponent({
  name: "App",
  components: {
    ErrorMessageComponent,
    ErrorGlobalView,
    NavigationComponent,
    FooterComponent,
    HeaderComponent,
    PreloaderComponent,
    WaitComponent,
    TreeItemsComponent,
  },
  setup() {
    const store = useStore<IStore>();
    const loading = ref(computed(() => store.getters.loadingGet));
    const userLoading = ref(
      computed<boolean>(() => store.getters.getUserLoading)
    );
    const locationLoading = ref(
      computed<boolean>(() => store.getters.getCustomerLocationLoading)
    );
    const popup = ref(computed<boolean>(() => store.getters.getPopup));
    const route = useRoute();
    const router = useRouter();
    const currentRouteName = computed(() => route.name);
    const popupAccount = ref<popupAccountType>(store.getters.getAccountPopup);
    const closePopup = () => {
      if (typeof store.getters.getRedirectPath === "string") {
        store.getters.getRedirectPath === "cart"
          ? router.push({
              name: store.getters.getRedirectPath,
              query: {
                create: "true",
              },
            })
          : router.push(store.getters.getRedirectPath);
        store.commit("setRedirectPath", null);
      }
      store.commit("deletePopupAccountMessage");
      return true;
    };
    return {
      loading,
      currentRouteName,
      userLoading,
      locationLoading,
      popup,
      closePopup,
      popupAccount: popupAccount.value,
    };
  },
});
