export const closestNumber = (n: number, m: number) => {
 
    const q = parseInt((n / m).toString());
     
    const n1 = m * q;
     
    const n2 = (n * m) > 0 ? (m * (q + 1)) : (m * (q - 1))

    if (Math.abs(n - n1) < Math.abs(n - n2))
        return n1

    return n2
}