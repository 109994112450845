import { NavigationGuardNext } from 'vue-router'
import jwt_decode from "jwt-decode"
import { TypeToken } from './type'
import { store } from '@/store'

export const checkToken = async (next?: NavigationGuardNext) => {
    
    const decodeToken = await jwt_decode(store.getters.accessTokenGet) as TypeToken | null

    if(decodeToken){
        if(new Date() < new Date((decodeToken.exp * 1000) - 20000)){
            return next ? next() : true
        }else
            return new Promise((resolve, reject) => store.dispatch('updateRefreshTokenAndAccessToken')
            .then((data: string) => {
                console.log(data)
                next ?
                resolve(next()) :
                resolve((data))
            }))
    }
    
    return next ? next() : false
}