import { AccountDetailsAllType } from "@/store/modules/accountDetails/type";
import { UserTypeOperation } from "@/store/modules/User/type";
import { GeoPlaceData } from "@/store/modules/GeoPlace/type";
import { Costumers } from "@/store/modules/Customers/type";
import { Locations } from "@/store/modules/Locations/type";
import { ValuesLocationsCostumers } from "./type";
import { Bank } from "@/store/modules/Bank/type";
import { ShedulesFetch } from "./shedulesFetch";
import { computed, ref } from "vue";
import { store } from "@/store";

export const fetchAll = async (
  values: AccountDetailsAllType,
  initValue: AccountDetailsAllType
) => {
  const select = ref(computed<number>(() => store?.getters?.getSelect));
  const bankData = ref(
    computed<Bank[]>(() => store.getters.getBankDataByUserId)
  );
  const userData = ref(
    computed<UserTypeOperation>(() => store.getters.getUserData)
  );
  const bankLocation = ref(
    computed<GeoPlaceData>(() => store.getters.getBankLocations)
  );
  const customerDataUser = ref(
    computed<Costumers[]>(() => store?.getters?.getUserCustomerData)
  );
  const locationCustomer = ref(
    computed<Locations[]>(() => store.getters.getLocationsCustomerById)
  );
  store.commit("setPopup", true);
  console.log(
    (initValue.city !== values.city && values.city.length > 0) ||
      (initValue.full_address !== values.full_address &&
        values.full_address.length > 0) ||
      (initValue.postal_code !== values.postal_code &&
        values.postal_code.length > 0)
  );
  new Promise<Locations>((resolve) => {
    if (
      (initValue.city !== values.city && values.city.length > 0) ||
      (initValue.full_address !== values.full_address &&
        values.full_address.length > 0) ||
      (initValue.postal_code !== values.postal_code &&
        values.postal_code.length > 0)
    )
      if (locationCustomer.value.length > 0)
        store
          .dispatch("updateLocation", {
            ...locationCustomer.value[0],
            ...values,
            customerLocationId: locationCustomer.value[0].id,
          })
          .then(async (data: Locations) => {
            resolve(data);
          });
      else
        store
          .dispatch("createLocation", {
            ...store.getters.getPostalcodeSelectPostalCode,
            ...values,
          })
          .then(async (data: Locations) => {
            resolve({ ...data });
          });
    else resolve({ ...locationCustomer.value[0] });
  })
    .then((data) => {
      return new Promise<ValuesLocationsCostumers>((resolve) => {
        if (
          (initValue.firm_name !== values.firm_name &&
            values.firm_name.length > 0) ||
          (initValue.buyer_phone !== values.buyer_phone &&
            values.buyer_phone.length > 0) ||
          (initValue.buyer_name !== values.buyer_name &&
            values.buyer_name.length > 0) ||
          initValue.buyer_vat !== values.buyer_vat ||
          (initValue.buyer_email !== values.buyer_email &&
            values.buyer_email.length > 0) ||
          (initValue.buyer_address !== values.buyer_address &&
            values.buyer_address.length > 0) ||
          (initValue.fax?.replace(/[^\w+]/g, "") !==
            values.fax.replace(/[^\w+]/g, "") &&
            values.fax.length > 0)
        )
          if (customerDataUser.value?.length > 0)
            store
              .dispatch("updateCustomer", {
                ...customerDataUser?.value[0],
                ...values,
                locationId: data?.id,
                customerId: customerDataUser?.value[0]?.id,
              })
              .then(async (_data: Costumers) => {
                resolve({
                  ..._data,
                  ...data,
                  customerId: _data?.id,
                  locationId: data?.id,
                  ...userData?.value,
                  user_phone: values.user_phone,
                });
              });
          else
            store
              .dispatch("createCustomer", { ...values, ...data })
              .then(async (_data: Costumers) => {
                resolve({
                  ..._data,
                  ...data,
                  customerId: _data?.id,
                  locationId: data?.id,
                  ...userData?.value,
                  user_phone: values?.user_phone,
                });
              });
        else
          resolve({
            ...customerDataUser?.value[0],
            ...data,
            customerId: customerDataUser.value[0]?.id,
            locationId: locationCustomer?.value[0]?.id,
            user_phone: values.user_phone,
            ...userData?.value,
          });
      });
    })
    .then((data) => {
      return new Promise<ValuesLocationsCostumers>((resolve) => {
        if (
          (initValue?.first_name !== values?.first_name &&
            values?.first_name?.length > 0) ||
          !data.customerId ||
          !store.getters.getUserCustomer ||
          (initValue?.user_phone.replace(/[^\w+]/g, "") !==
            values?.user_phone?.replace(/[^\w+]/g, "") &&
            values?.user_phone?.length > 0) ||
          (initValue?.email !== values?.email && values?.email?.length > 0) ||
          (initValue?.last_name !== values?.last_name &&
            values?.last_name?.length > 0) ||
          (initValue?.title !== values?.title && values?.title?.length > 0) ||
          (initValue?.job_title !== values?.job_title &&
            values?.job_title?.length > 0)
        )
          if (select.value !== -1)
            store
              .dispatch("updateDataUser", {
                ...values,
                customer: data.customerId || store.getters.getUserCustomer,
              })
              .then(() => {
                return resolve(data);
              });
          else
            store
              .dispatch("createDataUser", {
                ...values,
                customer: data.customerId || store.getters.getUserCustomer,
              })
              .then(() => {
                return resolve(data);
              });
        else resolve(data);
      });
    })
    .then((data) => {
      return new Promise<ValuesLocationsCostumers>((resolve) => {
        if (
          initValue?.bank_city.trim() !== values.bank_city.trim() ||
          initValue?.correspond_account.trim() !==
            values.correspond_account.trim() ||
          initValue?.bank_name.trim() !== values.bank_name.trim() ||
          initValue?.number.trim() !== values.number.trim()
        )
          if (customerDataUser.value?.length > 0 && bankData.value[0]?.id)
            store
              .dispatch("updateBank", {
                ...bankData.value[0],
                ...values,
                bankId: bankData.value[0]?.id,
                bankLocation: bankLocation?.value?.id,
              })
              .then(() => {
                resolve(data);
              });
          else
            store.dispatch("createBank", { ...values, ...data }).then(() => {
              resolve(data);
            });
        else resolve(data);
      });
    })
    .then((data) => {
      store.dispatch("createOrUpdateCustomerInNavision", data);
      if (!userData.value?.customer) ShedulesFetch(data, initValue, values);
    });
};
