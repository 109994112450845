import {
  phoneValidation,
  phoneValidationWithoutPluse,
} from "./universalMethods";
import { postalcodeType } from "@/store/modules/Postalcode/type";
import { Locations } from "@/store/modules/Locations/type";
import { store } from "@/store";
import * as yup from "yup";
import { computed, ref } from "vue";

const searchPostalCode = (
  field: string,
  value: string,
  locations: Locations[]
) => {
  const postalCodeOptions = (store.getters[
    `get${field?.charAt(0)?.toUpperCase() + field?.slice(1)}PostalcodeOptions`
  ] as { data: postalcodeType[]; loading: boolean }) || {
    data: [],
    loading: false,
  };
  postalCodeOptions.data = postalCodeOptions?.data?.filter(
    (_) => _[`geo_${field}` as keyof postalcodeType] === value
  );

  return true;
};
const serverType = ref(computed<string>(() => store.getters.getServerType));

const validateVat = (value: string | undefined | null) => {
  console.log(store.getters.getUserCustomerTypeData)
  if (store.getters.getUserCustomerTypeData === "Private" && value?.length === 0) return true;
  const start =
    store.getters?.getPersonalDataComponenValuesAccountDetails.buyer_vat_start;
  if (start === "SE") {
    if (value?.length === 12) return true;
    else false;
  } else {
    if (value?.length === 8) return true;
    else false;
  }
};

const validateCountry = (value: string, locations: Locations[]) => {
  if (!value) return false;
  return searchPostalCode("country", value, locations);
};

const validateRegion = (value: string, locations: Locations[]) => {
  if (!value) return false;
  return searchPostalCode("region", value, locations);
};

const validateCity = (value: string, locations: Locations[]) => {
  if (!value) return false;
  return searchPostalCode("name", value, locations);
};

yup.addMethod(yup.mixed, "phoneValidation", phoneValidation);
yup.addMethod(
  yup.mixed,
  "phoneValidationWithoutPluse",
  phoneValidationWithoutPluse
);

export const PersonalDataComponentValidation = yup.object().shape(
  {
    firm_name: yup
      .string()
      .nullable()
      .required()
      .min(2)
      .label("Company name from site"),
    buyer_phone: (yup.mixed() as any).phoneValidation(
      "Phone number is invalid"
    ),
    buyer_email: yup
      .string()
      .required()
      .email()
      .label("Please change email address, "),
    country: yup
      .string()
      .required()
      .when("country", {
        is: (value: string) => value?.length > 0,
        then: (rule) =>
          rule.test("country", (value) =>
            validateCountry(value, store?.getters?.getLocationsCustomerById)
          ),
      }),
    region: yup
      .string()
      .required()
      .when("country", {
        is: (value: string) =>
          validateCountry(value, store?.getters?.getLocationsCustomerById),
        then: (rule) =>
          rule
            .test("region", (value) =>
              validateRegion(value, store?.getters?.getLocationsCustomerById)
            )
            .typeError("Please, fill in the fields Region and City"),
      }),
    city: yup
      .string()
      .required()
      .when("region", {
        is: (value: string) =>
          validateRegion(value, store?.getters?.getLocationsCustomerById),
        then: (rule) =>
          rule
            .test("city", (value) =>
              validateCity(value, store?.getters?.getLocationsCustomerById)
            )
            .typeError("Please, fill in the fields City"),
      }),
    buyer_vat: yup
      .string()
      .notRequired()
      .when("buyer_vat", {
        is: (value: string) => value?.length >= 0,
        then: (rule) =>
          rule
            .test("buyer_vat", (value) => validateVat(value))
            
            .typeError("vat field is required"),
      }),
    full_address: yup.string().required().nullable().min(4).label("Address"),
  },
  [
    ["firm_name", "firm_name"],
    ["buyer_vat", "buyer_vat"],
    ["country", "country"],
    ["region", "region"],
    ["city", "city"],
    ["fax", "fax"],
  ]
);
