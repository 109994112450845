import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main pt-2 mb-5 pl-3 pr-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreloaderComponent = _resolveComponent("PreloaderComponent")!
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_NavigationComponent = _resolveComponent("NavigationComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_WaitComponent = _resolveComponent("WaitComponent")!
  const _component_TreeItemsComponent = _resolveComponent("TreeItemsComponent")!
  const _component_ErrorMessageComponent = _resolveComponent("ErrorMessageComponent")!
  const _component_ErrorGlobalView = _resolveComponent("ErrorGlobalView")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_PreloaderComponent, null, null, 512), [
      [_vShow, _ctx.userLoading || _ctx.loading || !_ctx.currentRouteName]
    ]),
    _withDirectives(_createVNode(_component_HeaderComponent, null, null, 512), [
      [_vShow, 
      _ctx.currentRouteName !== 'ordersDetailsPcr' &&
      _ctx.currentRouteName !== 'ordersDetailsTbr' &&
      _ctx.currentRouteName
    ]
    ]),
    _createVNode(_component_NavigationComponent),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    (_ctx.popup)
      ? (_openBlock(), _createBlock(_component_WaitComponent, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.popupAccount.open)
      ? (_openBlock(), _createBlock(_component_ErrorGlobalView, {
          key: 1,
          error: _ctx.popupAccount.open,
          "change-error": _ctx.closePopup
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ErrorMessageComponent, {
              class: _normalizeClass('direction-column')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TreeItemsComponent, {
                  items: _ctx.popupAccount.message
                }, null, 8, ["items"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["error", "change-error"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_FooterComponent, null, null, 512), [
      [_vShow, 
      _ctx.currentRouteName !== 'ordersDetailsPcr' &&
      _ctx.currentRouteName !== 'ordersDetailsTbr' &&
      _ctx.currentRouteName
    ]
    ])
  ], 64))
}