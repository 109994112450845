import { ChangePasswordErrorType, ChangePasswordType, UserType } from '@/views/UpdatePasswordView/type'
import { IUseFetchDetailError } from '@/hook/fetch/interface'
import { useFetch } from '../../hook/fetch/fetch'
import { Ref } from 'vue'

export const validationUpdatePassword = async(dataInput: Ref<ChangePasswordType>,errorPassword: Ref<ChangePasswordErrorType>, userId: number | null) => {
    if(!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,55}$/.test(dataInput.value.password1)) errorPassword.value.password1 = true
    else errorPassword.value.password1 = false
    if(!dataInput.value.old_password) errorPassword.value.old_password = true
    else errorPassword.value.old_password = false
    if(dataInput.value.password1 !== dataInput.value.password2) errorPassword.value.coincidence = true
    else errorPassword.value.coincidence = false

    if(!errorPassword.value.password1 && !errorPassword.value.password2 && !errorPassword.value.old_password && !errorPassword.value.coincidence){
        const { isLoading, isError, errorMessage, data } = await useFetch<UserType, IUseFetchDetailError>(process.env.VUE_APP_SERVER + `api/users/${userId}/change_password/`, 'PUT', true, dataInput)

        console.log(isLoading, isError, errorMessage, data)
    }
}