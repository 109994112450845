import {
  ValuesLocationsCostumers,
  ValuesLocationsCostumersSchedules,
  ValuesLocationsCostumersSchedulesStockId,
} from "./type";
import {
  AccountDetailsAllType,
  ChedulesDetails,
  PersonalDataComponent,
} from "@/store/modules/accountDetails/type";
import { Locations } from "@/store/modules/Locations/type";
import { Schedules } from "@/store/modules/Schedules/type";
import { StockType } from "@/store/modules/Stock/type";
import { computed, ref } from "vue";
import { store } from "@/store";

export const ShedulesFetch = (
  data: ValuesLocationsCostumers,
  _initValue: AccountDetailsAllType | ChedulesDetails,
  _values: AccountDetailsAllType | ChedulesDetails
) => {
  const values = { ..._values };
  const initValue = { ..._initValue };
  const stock = ref(
    computed<StockType[]>(
      () => store.getters?.getStockResultsInSelectedSheldules
    )
  );
  const locationSelected = ref(
    computed<Locations[]>(
      () => store.getters.getStockLocationsInSelectedAddress
    )
  );
  const _locationSelected = ref(
    computed<PersonalDataComponent>(
      () => store.getters.getPersonalDataComponentValues
    )
  );
  const locationCustomer = ref(
    computed<Locations[]>(() => store.getters.getLocationsCustomerById)
  );
  const schedulesSelected = ref(
    computed<Schedules | null>(
      () => store.getters.getSchedulesInSelectedLocation
    )
  );
  // const accountDetailsData = ref(
  //   computed<PersonalDataComponent>(
  //     () => store.getters.getPersonalDataComponenValuesAccountDetails
  //   )
  // );
  const stockSave = { ...stock.value[0] };
  store.state.UserModuleOperation.customer = data.customerId;
  return new Promise<ValuesLocationsCostumersSchedules>((resolve) => {
    if (schedulesSelected.value?.id)
      store
        .dispatch("createSchedulesOrUpdate", {
          values,
          url:
            process.env.VUE_APP_SERVER +
            "api/schedules/" +
            schedulesSelected.value.id +
            "/",
          type: "PATCH",
          shedulesData: schedulesSelected.value,
        })
        .then((_data: Schedules) => {
          resolve({ ..._data, ...data, schedulesId: _data?.id });
        });
    else
      store
        .dispatch("createSchedulesOrUpdate", {
          values,
          url: process.env.VUE_APP_SERVER + "api/schedules/",
          type: "POST",
          shedulesData: schedulesSelected.value,
        })
        .then((_data: Schedules) => {
          resolve({ ..._data, ...data, schedulesId: _data?.id });
        });
  })
    .then((data) => {
      return new Promise<ValuesLocationsCostumersSchedulesStockId>(
        (resolve) => {
          const _locationGeoPlace =
            locationCustomer.value[0]?.geo_place_name === values?.city_stock
              ? locationCustomer.value[0]
              : locationSelected.value[0]?.geo_place_name ===
                _locationSelected.value?.city
              ? locationSelected.value[0]
              : _locationSelected.value;
          const _locationGeoPlaceId =
            locationCustomer.value[0]?.geo_place_name === values?.city_stock
              ? locationCustomer.value[0]
              : locationSelected.value[0];
          if (
            initValue.city_stock !== values.city_stock ||
            initValue.postal_code_stock !== values.postal_code_stock ||
            initValue.buyer_address_stock !== values.buyer_address_stock ||
            !locationSelected.value[0]?.id
          ) {
            if (
              schedulesSelected.value?.id &&
              _locationGeoPlaceId?.geo_place_name
            )
              store
                .dispatch("updateLocationSchedules", {
                  ..._locationGeoPlace,
                  ...values,
                  locationStockId: _locationGeoPlaceId.id,
                  id: locationSelected.value[0]?.id,
                })
                .then((_data: Locations) => {
                  resolve({
                    ..._data,
                    ...data,
                    locationStockId: _locationGeoPlaceId?.id,
                  });
                });
            else
              store
                .dispatch("createLocationSchedules", {
                  ...values,
                  geo_place:
                    (values as any)?.geo_place ||
                    _locationGeoPlaceId?.geo_place,
                })
                .then((_data: Locations) => {
                  resolve({
                    ..._data,
                    ...data,
                    locationStockId: _data?.id || _locationGeoPlaceId?.id,
                  });
                });
          } else
            resolve({
              ...data,
              locationStockId: _locationGeoPlaceId?.id,
            });
        }
      );
    })
    .then((data) => {
      console.log(stockSave?.id && !schedulesSelected.value?.id);
      if (
        (initValue.buyer_phone_stock.replace(/[^\w+]/g, "") !==
          values.buyer_phone_stock.replace(/[^\w+]/g, "") &&
          values.buyer_phone_stock.length > 0) ||
        (initValue.buyer_name_stock !== values.buyer_name_stock &&
          values.buyer_name_stock.length > 0) ||
        initValue.comment !== values.comment ||
        (stockSave?.id && !schedulesSelected.value?.id)
      ) {
        if (schedulesSelected.value?.id || stockSave?.id)
          store.dispatch("updateStock", {
            ...data,
            ...values,
            stockId: stock.value[0]?.id || stockSave?.id,
          });
        else store.dispatch("createStock", { ...data, ...values });
      }
    });
};
