import { KeySringValueString } from './type'

export const deepEqual: any = (x: KeySringValueString, y: KeySringValueString) => {
    return (x && y && typeof x === 'object' && typeof y === 'object') 
    ?
    (Object.keys(x).length === Object.keys(y).length) 
    && Object.keys(x).reduce((isEqual, key) => {
        if(key.includes('phone')) return isEqual && deepEqual(x[key]?.replace(/[^\w+]/g, ''), y[key]?.replace(/[^\w+]/g, ''))
        if(key.includes('fax')) return isEqual && deepEqual(x[key]?.replace(/[^\w+]/g, ''), y[key]?.replace(/[^\w+]/g, ''))

        return isEqual && deepEqual(x[key]?.trim(), y[key]?.trim())
    } , true) 
    : (x === y)
}