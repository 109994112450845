<template>
  <div class="popup">
    <div class="d-flex j-content-center direction-column a-i-center">
      <div class="spiner mt-5 mb-5"></div>
      <div class="uppercase text-motserrat-15-bold text-center">
        {{ $tc("please wait a bit") }}
      </div>
    </div>
  </div>
</template>

<style type="scss">
@import "./wait.scss";
</style>
