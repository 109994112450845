import { deleteAllValuesInObject } from "@/utils/validation/deleteAllValuesInObject";
import { IStore } from "../../types/StoreType";
import { CarsTypeFilter, NewOrders, TruckTypeFilter } from "./type";
import { Module } from "vuex";

export const NewOrdersModuleOperation: Module<NewOrders, IStore> = {
  state: {
    cars: {
      width: "",
      height: "",
      diameter: "",
      season: "",
      product__brand__id: "",
      runflat: "",
      product__external_product_id__startswith: "",
      stock_amount: "",
    },
    truck: {
      width__icontains: "",
      height: "",
      diameter: "",
      season: "",
      product__brand__id: "",
      product__external_product_id__startswith: "",
      tube_or_tubeless: "",
      product__tbr__type_of_operation: "",
      load_index__icontains: "",
      axis: "",
    },
    carsValues: {
      width: "",
      height: "",
      diameter: "",
      season: "",
      product__brand__id: "",
      runflat: "",
      product__external_product_id__startswith: "",
      stock_amount: "",
    },
    truckValues: {
      width__icontains: "",
      height: "",
      diameter: "",
      season: "",
      product__brand__id: "",
      product__external_product_id__startswith: "",
      tube_or_tubeless: "",
      product__tbr__type_of_operation: "",
      load_index__icontains: "",
      axis: "",
    },
  },
  getters: {
    getInitialValuesCarsNewOrders(state) {
      return state.cars;
    },
    getInitialValuesTruckNewOrders(state) {
      return state.truck;
    },
    getValuesCarsNewOrders(state) {
      return state.carsValues;
    },
    getValuesTruckNewOrders(state) {
      return state.truckValues;
    },
  },
  mutations: {
    updateInitialValuesCarsNewOrders(state, payload: CarsTypeFilter) {
      state.cars = { ...state.cars, ...payload };
      state.carsValues = { ...state.carsValues, ...payload };
      console.log(payload.season);
      state.carsValues.season = [
        "All",
        "Winter",
        "Summer",
        "All weather",
      ][0];
    },
    updateInitialValuesTruckNewOrders(state, payload: TruckTypeFilter) {
      state.truck = { ...state.truck, ...payload };
      state.truckValues = { ...state.truckValues, ...payload };
      state.truckValues.season = [
        "All",
        "Winter",
        "Summer",
        "All weather",
      ][0];
    },
    clearInitialValuesCarsNewOrders(state) {
      state.carsValues = deleteAllValuesInObject<CarsTypeFilter>(
        state.carsValues
      );
      state.cars = deleteAllValuesInObject<CarsTypeFilter>(state.cars);
    },
    clearInitialValuesTruckNewOrders(state) {
      state.truckValues = deleteAllValuesInObject<TruckTypeFilter>(
        state.truckValues
      );
      state.truck = deleteAllValuesInObject<TruckTypeFilter>(state.truck);
    },
  },
};
