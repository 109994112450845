import { formatDate } from "@/utils/normileze/date";
import { OrderSearchType } from "../type";
import { store } from "@/store";

let urlOld = process.env.VUE_APP_SERVER + "api/orders/" + "?";

const doSomethingAsync = (value: string) =>
  new Promise((resolve: (value: string) => void) => resolve(value));

export const getOrders = async (
  values: OrderSearchType,
  updateAuto?: boolean
) => {
  let newUrl = process.env.VUE_APP_SERVER + "api/orders/" + "?";

  const conditions = ["date", "update"];

  const promises: string[] = [];

  let key: keyof typeof values;
  for (key in values) {
    const include = conditions.some((condition) => key.includes(condition));
    const value = values[key];
    if (key === "order_btn") continue;
    if (value.length > 0 && !include) {
      if (key !== "ordering" && key !== "position") {
        const newValue = key + "=" + value + "&";
        newUrl += newValue;
        promises.push(await doSomethingAsync(newUrl));
      }
      if (key === "ordering") {
        const newValue = key + "=" + values.position + value + "&";
        newUrl += newValue;
        promises.push(await doSomethingAsync(newUrl));
      }
    }
    if (value && include && (key === "update__gte" || key === "update__lte")) {
      const dateIso = `${new Date(
        new Date(value).getTime() - new Date(value).getTimezoneOffset() * 60000
      ).toISOString()}`;
      const newValue = key + "=" + dateIso + "&";
      newUrl += newValue;
      promises.push(await doSomethingAsync(newUrl));
    }
    if (value && include && (key === "date__gte" || key === "date__lte")) {
      const date = formatDate(value, "-", true);
      const newValue = key + "=" + date + "&";
      newUrl += newValue;
      promises.push(await doSomethingAsync(newUrl));
    }
  }

  Promise.all(promises).then(() => {
    if (newUrl === urlOld && !updateAuto) return;
    urlOld = newUrl;

    store.dispatch("getOrders", {
      updateStatus: false,
      url: newUrl,
      updateOrdersDetails: false,
    });
  });
};
