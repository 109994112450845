import { OptionsType, SearcInputType } from "@/store/modules/GeoPlace/type"

export const searchTextFunction = (value: string, arrayProperties: SearcInputType[]) => {
    // console.log(value, arrayProperties)
    if(value?.length === 0) return arrayProperties
    if(!value) return arrayProperties
    const inputValue = value?.toLocaleLowerCase().trim()
    console.log(arrayProperties)
    return arrayProperties.filter((properties) => {
        const propertyLower = properties.value.toLocaleLowerCase()
        return propertyLower.indexOf(inputValue) > -1
    })
}