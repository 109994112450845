import { convertToPhoneMask } from "../../../utils/phone/phone";
import { AccountDetails, ChedulesDetails, PersonalDataComponent } from "./type";
import { UserTypeOperation } from "../User/type";
import { IStore } from "../../types/StoreType";
import { Locations } from "../Locations/type";
import { Schedules } from "../Schedules/type";
import { store } from "../../../store/index";
import { StockType } from "../Stock/type";
import { Bank } from "../Bank/type";
import { Module } from "vuex";
import { convertToVatMask } from "@/utils/vat/vat";

export const AccountDetailsModuleOperation: Module<AccountDetails, IStore> = {
  state: {
    globalErrorsPersonalDataComponent: [],
    globalErrorsLocationsDataComponent: [],
    globalErrorsBankDataComponent: [],
    globalErrorsAccountDetails: [],
    globalErrorsChedulesComponent: [],
    personalDataComponentValues: {
      buyer_phone: "",
      buyer_vat: "",
      buyer_vat_start: "FI",
      city: "",
      country: "",
      email_sing: "",
      fax: "",
      firm_name: "",
      firm_name2: "",
      full_address: "",
      postal_code: "",
      region: "",
      user_phonel_sing: "",
      buyer_name: "",
      buyer_email: "",
      buyer_address: "",
      sales_person_name: "",
      buyer_phone_start: "",
    },
    personalDataComponent: {
      buyer_phone: "",
      buyer_vat: "",
      buyer_vat_start: "FI",
      city: "",
      country: "",
      email_sing: "",
      fax: "",
      firm_name: "",
      firm_name2: "",
      full_address: "",
      postal_code: "",
      region: "",
      user_phonel_sing: "",
      buyer_name: "",
      buyer_email: "",
      buyer_address: "",
      sales_person_name: "",
      buyer_phone_start: "",
    },
    RegistrationComponents: {
      email: "",
      first_name: "",
      first_name_sing: "",
      job_title: "",
      last_name: "",
      last_name_sing: "",
      title: "",
      title_sing: "",
      user_phone: "",
      sales_person_name: "",
      user_phone_start: "",
      password: "",
      password_confirm: "",
      private: false,
    },
    locationsDataComponent: {
      email: "",
      first_name: "",
      first_name_sing: "",
      job_title: "",
      last_name: "",
      last_name_sing: "",
      title: "",
      title_sing: "",
      user_phone: "",
      sales_person_name: "",
      user_phone_start: "",
    },
    bankDataComponent: {
      bank_city: "",
      bank_name: "",
      correspond_account: "",
      number: "",
    },
    accountDetails: {
      creation_time: "",
      id: "",
      login: "",
      manager: "",
      payment_type: "",
    },
    chedulesComponent: {
      postal_code_stock: "",
      buyer_address_stock: "",
      buyer_location_stock: "",
      buyer_name_stock: "",
      buyer_phone_stock: "",
      buyer_phone_stock_start: "",
      check_day_1: "",
      check_day_2: "",
      check_day_3: "",
      check_day_4: "",
      check_day_5: "",
      check_day_6: "",
      check_day_7: "",
      city_stock: "",
      comment: "",
      country_stock: "",
      day_end0: "",
      day_end1: "",
      day_end2: "",
      day_end3: "",
      day_end4: "",
      day_end5: "",
      day_end6: "",
      day_start0: "",
      day_start1: "",
      day_start2: "",
      day_start3: "",
      day_start4: "",
      day_start5: "",
      day_start6: "",
      region_stock: "",
    },
  },
  getters: {
    getPersonalDataComponentValues(state) {
      return state.personalDataComponentValues;
    },
    getGlobalErrorsPersonalDataComponent(state) {
      return state.globalErrorsPersonalDataComponent;
    },
    getGlobalErrorsLocationsDataComponent(state) {
      return state.globalErrorsLocationsDataComponent;
    },
    getGlobalErrorsBankDataComponent(state) {
      return state.globalErrorsBankDataComponent;
    },
    getGlobalErrorsAccountDetails(state) {
      return state.globalErrorsAccountDetails;
    },
    getGlobalErrorsChedulesComponent(state) {
      return state.globalErrorsChedulesComponent;
    },
    getPersonalDataComponenValuesAccountDetails(state: AccountDetails) {
      if (!state.personalDataComponent.postal_code) {
        switch (store.state?.LanguageTypeOperation?.ui_language) {
          case "English":
            state.personalDataComponent.country = "";
            break;
          case "Swedish":
            state.personalDataComponent.country = "Sweden";
            break;
          case "Finland":
            state.personalDataComponent.country = "Finland";
            break;
          default:
            state.personalDataComponent.country = "Finland";
            break;
        }
      }
      return state.personalDataComponent;
    },
    getRegistrationDataComponentValues(state: AccountDetails) {
      return state.RegistrationComponents;
    },
    getLocationsDataComponentValuesAccountDetails(state: AccountDetails) {
      return state.locationsDataComponent;
    },
    getBankDataComponentValuesAccountDetails(state: AccountDetails) {
      return state.bankDataComponent;
    },
    getChedulesComponentValuesAccountDetails(state: AccountDetails) {
      if (
        !state.chedulesComponent.postal_code_stock &&
        !store.getters.getLocationLoading
      ) {
        switch (store.state?.LanguageTypeOperation?.ui_language) {
          case "English":
            state.chedulesComponent.country_stock = "";
            break;
          case "Swedish":
            state.chedulesComponent.country_stock = "Sweden";
            break;
          case "Finland":
            state.chedulesComponent.country_stock = "Finland";
            break;
          default:
            state.chedulesComponent.country_stock = "Finland";
            break;
        }
      }
      return state.chedulesComponent;
    },
    getAccountComponentValuesAccountDetails(state: AccountDetails) {
      return state.accountDetails;
    },
    getAccountDetails(state: AccountDetails) {
      return state;
    },
    getInitialValuesAccountDetails(state: AccountDetails) {
      return {
        ...state.accountDetails,
        ...state.bankDataComponent,
        ...state.chedulesComponent,
        ...state.locationsDataComponent,
        ...state.personalDataComponent,
      };
    },
  },
  mutations: {
    updateChedulesLocationAddress(state) {
      if (!state.chedulesComponent.buyer_address_stock)
        state.chedulesComponent.buyer_address_stock =
          state.personalDataComponentValues.full_address;
    },
    updatePersonalDataComponentValues(state, payload: PersonalDataComponent) {
      state.personalDataComponentValues = { ...payload };
    },
    updateChedulesLocationValues(state) {
      state.chedulesComponent.country_stock =
        state.personalDataComponentValues.country;
      state.chedulesComponent.region_stock =
        state.personalDataComponentValues.region;
      state.chedulesComponent.buyer_address_stock =
        state.personalDataComponentValues.full_address;
      state.chedulesComponent.postal_code_stock =
        state.personalDataComponentValues.postal_code;
      state.chedulesComponent.city_stock =
        state.personalDataComponentValues.city;
    },
    updateRegistrationValues(state: AccountDetails, payload) {
      state.RegistrationComponents = {
        ...state.RegistrationComponents,
        ...payload,
      };
    },
    updateRegistrationStartPhone(state: AccountDetails) {
      switch (store.state?.LanguageTypeOperation?.ui_language) {
        case "English":
          state.RegistrationComponents.user_phone_start = "+358";
          break;
        case "Swedish":
          state.RegistrationComponents.user_phone_start = "+46";
          break;
        case "Finland":
          state.RegistrationComponents.user_phone_start = "+358";
          break;
        default:
          state.RegistrationComponents.user_phone_start = "+358";
          break;
      }
    },
    updatePersonalDataComponenValuesAccountDetails(state: AccountDetails) {
      if (!state.personalDataComponent.postal_code) {
        switch (store.state?.LanguageTypeOperation?.ui_language) {
          case "English":
            state.personalDataComponent.country = "";
            break;
          case "Swedish":
            state.personalDataComponent.country = "Sweden";
            break;
          case "Finland":
            state.personalDataComponent.country = "Finland";
            break;
          default:
            state.personalDataComponent.country = "Finland";
            break;
        }
      }
    },
    updatePersonalDataComponenValuesChedules(state: AccountDetails) {
      if (!state.chedulesComponent.postal_code_stock) {
        switch (store.state?.LanguageTypeOperation?.ui_language) {
          case "English":
            state.chedulesComponent.country_stock = "";
            break;
          case "Swedish":
            state.chedulesComponent.country_stock = "Sweden";
            break;
          case "Finland":
            state.chedulesComponent.country_stock = "Finland";
            break;
          default:
            state.chedulesComponent.country_stock = "Finland";
            break;
        }
      }
    },
    changeStartValue_user_phone_start_registration(state, payload) {
      state.RegistrationComponents.user_phone_start = payload;
    },
    changeStartValue_vat_phone_start(state, payload) {
      state.personalDataComponent.buyer_vat_start = payload;
    },
    changeStartValue_buyer_phone_start(state, payload) {
      state.personalDataComponent.buyer_phone_start = payload;
    },
    changeStartValue_user_phone_start(state, payload) {
      state.locationsDataComponent.user_phone_start = payload;
    },
    changeStartValue_buyer_phone_stock_start(state, payload) {
      state.chedulesComponent.buyer_phone_stock_start = payload;
    },
    updateGlobalErrorsPersonalDataComponent(state, payload) {
      if (payload === "") {
        state.globalErrorsPersonalDataComponent = null;
      } else {
        if (!Array.isArray(state.globalErrorsPersonalDataComponent))
          state.globalErrorsPersonalDataComponent = [];
        else state.globalErrorsPersonalDataComponent.push(payload);
      }
    },
    updateGlobalErrorsLocationsDataComponent(state, payload: string) {
      console.log(payload === "");
      if (payload === "") {
        state.globalErrorsLocationsDataComponent = null;
      } else {
        if (!Array.isArray(state.globalErrorsLocationsDataComponent)) {
          state.globalErrorsLocationsDataComponent = [];
          state.globalErrorsLocationsDataComponent.push(payload);
        } else state.globalErrorsLocationsDataComponent.push(payload);
      }
    },
    updateGlobalErrorsBankDataComponent(state, payload: string) {
      if (payload === "") {
        state.globalErrorsBankDataComponent = null;
      } else {
        if (!Array.isArray(state.globalErrorsBankDataComponent)) {
          state.globalErrorsBankDataComponent = [];
          state.globalErrorsBankDataComponent.push(payload);
        } else state.globalErrorsBankDataComponent.push(payload);
      }
    },
    updateGlobalErrorsAccountDetails(state, payload: string) {
      if (payload === "") {
        state.globalErrorsAccountDetails = null;
      } else {
        if (!Array.isArray(state.globalErrorsAccountDetails)) {
          state.globalErrorsAccountDetails = [];
          state.globalErrorsAccountDetails.push(payload);
        } else state.globalErrorsAccountDetails.push(payload);
      }
    },
    updateGlobalErrorsChedulesComponent(state, payload: string) {
      if (payload === "") {
        state.globalErrorsChedulesComponent = null;
      } else {
        if (!Array.isArray(state.globalErrorsChedulesComponent)) {
          state.globalErrorsChedulesComponent = [];
          state.globalErrorsChedulesComponent.push(payload);
        } else state.globalErrorsChedulesComponent.push(payload);
      }
    },
    updateAllPhone(state) {
      state.personalDataComponent.buyer_phone = convertToPhoneMask(
        state.personalDataComponent.buyer_phone || "",
        state.personalDataComponent,
        "buyer_phone_start"
      );
      state.locationsDataComponent.user_phone = convertToPhoneMask(
        state.locationsDataComponent.user_phone || "",
        state.locationsDataComponent,
        "user_phone_start"
      );
      state.chedulesComponent.buyer_phone_stock = convertToPhoneMask(
        state.chedulesComponent.buyer_phone_stock || "",
        state.chedulesComponent,
        "buyer_phone_stock_start"
      );
    },
    updateAccountDetailsUserData(
      state: AccountDetails,
      payload: UserTypeOperation
    ) {
      state.locationsDataComponent.user_phone = convertToPhoneMask(
        payload?.phone || "",
        state.locationsDataComponent,
        "user_phone_start"
      );
      state.locationsDataComponent.email = payload?.email || "";
      state.locationsDataComponent.job_title = payload?.job_title || "";
      state.locationsDataComponent.title = payload?.title?.toString() || "";
      state.locationsDataComponent.first_name = payload?.first_name || "";
      state.locationsDataComponent.last_name = payload?.last_name || "";
    },
    updateAccountDetailsUserCustomer(state: AccountDetails, payload) {
      if (payload?.buyer_vat_start && payload?.buyer_vat)
        payload.buyer_vat = payload?.buyer_vat_start + payload?.buyer_vat;
      state.personalDataComponent.firm_name = payload?.firm_name || "";
      state.personalDataComponent.buyer_name = payload?.buyer_name || "";
      state.personalDataComponent.buyer_vat = convertToVatMask(
        payload?.buyer_vat || "",
        state.personalDataComponent,
        "buyer_vat_start"
      );
      state.personalDataComponent.buyer_email = payload?.email || "";
      state.personalDataComponent.buyer_phone = convertToPhoneMask(
        payload?.buyer_phone || "",
        state.personalDataComponent,
        "buyer_phone_start"
      );
      state.personalDataComponent.sales_person_name =
        payload?.sales_person_name || "";
    },
    updateAccountDetailsCustomerLocations(
      state: AccountDetails,
      payload: Locations
    ) {
      state.personalDataComponent.city = payload?.geo_place_name || "";
      state.personalDataComponent.region = payload?.geo_place_region || "";
      state.personalDataComponent.country = payload?.geo_place_country || "";
      state.personalDataComponent.full_address = payload?.full_address || "";
      state.personalDataComponent.postal_code = payload?.postal_code || "";
    },
    updateAccountDetailsBankData(state: AccountDetails, payload: Bank) {
      state.bankDataComponent.bank_name = payload?.bank_name || "";
      state.bankDataComponent.correspond_account =
        payload?.correspond_account || "";
      state.bankDataComponent.number = payload?.number || "";
      if (!state.bankDataComponent.bank_city) store.dispatch("getCityInBank");
    },
    updateAccountDetailsBankDataCityName(
      state: AccountDetails,
      payload: string
    ) {
      state.bankDataComponent.bank_city = payload;
    },
    updateAccountDetailsStock(state: AccountDetails, payload: StockType) {
      state.chedulesComponent.comment = payload?.comment || "";
      state.chedulesComponent.buyer_name_stock =
        payload?.responsible_name || "";
      state.chedulesComponent.buyer_phone_stock = convertToPhoneMask(
        payload?.phone || "",
        state.chedulesComponent,
        "buyer_phone_stock_start"
      );
    },
    updateAccountLocationStock(state: AccountDetails, payload: Locations) {
      state.chedulesComponent.buyer_address_stock = payload?.full_address || "";
    },
    updateAccountSchedulesLocation(state: AccountDetails, payload: Locations) {
      // state.chedulesComponent.latitude = payload?.geo_latitude || "";
      // state.chedulesComponent.longitude = payload?.geo_longitude || "";
      state.chedulesComponent.city_stock = payload?.geo_place_name || "";
      state.chedulesComponent.region_stock = payload?.geo_place_region || "";
      state.chedulesComponent.country_stock = payload?.geo_place_country || "";
      state.chedulesComponent.postal_code_stock = payload?.postal_code || "";
    },
    updateAccountDetailsStockSchedules(
      state: AccountDetails,
      payload: StockType
    ) {
      state.chedulesComponent.comment = payload?.comment || "";
      state.chedulesComponent.buyer_name_stock =
        payload?.responsible_name || "";
      state.chedulesComponent.buyer_phone_stock = convertToPhoneMask(
        payload?.phone || "",
        state.chedulesComponent,
        "buyer_phone_stock_start"
      );
    },
    updateAccountLocationStockSchedules(
      state: AccountDetails,
      payload: Locations
    ) {
      state.chedulesComponent.buyer_address_stock = payload?.full_address || "";
    },
    updateAccountSchedulesLocationSchedules(
      state: AccountDetails,
      payload: Locations
    ) {
      state.chedulesComponent.city_stock = payload?.geo_place_name || "";
      state.chedulesComponent.region_stock = payload?.geo_place_region || "";
      state.chedulesComponent.country_stock = payload?.geo_place_country || "";
    },
    updateAccountSchedules(state: AccountDetails, payload: Schedules) {
      if (
        !payload?.details ||
        (payload?.details &&
          payload?.details?.length === 1 &&
          payload?.details[0]?.day === null)
      )
        store.commit("updateAccountSchedulesDrop");
      else {
        [1, 2, 3, 4, 5, 6, 7].forEach((element) => {
          const findSchedules = payload.details.find(
            (schedules) => schedules.day === element
          );
          if (findSchedules) {
            const day = findSchedules.day - 1;
            state.chedulesComponent[
              `check_day_${findSchedules.day}` as keyof ChedulesDetails
            ] = "init";
            state.chedulesComponent[
              `day_start${day}` as keyof ChedulesDetails
            ] = findSchedules.work_time || "";
            state.chedulesComponent[`day_end${day}` as keyof ChedulesDetails] =
              findSchedules.work_end || "";
          } else {
            const day = element - 1;
            state.chedulesComponent[
              `check_day_${element}` as keyof ChedulesDetails
            ] = "";
            state.chedulesComponent[
              `day_start${day}` as keyof ChedulesDetails
            ] = "";
            state.chedulesComponent[`day_end${day}` as keyof ChedulesDetails] =
              "";
          }
        });
      }
    },
    updateAccountSchedulesDrop(state: AccountDetails) {
      [1, 2, 3, 4, 5, 6, 7].forEach((element) => {
        state.chedulesComponent[
          `check_day_${element}` as keyof ChedulesDetails
        ] = "";
        state.chedulesComponent[
          `day_start${element - 1}` as keyof ChedulesDetails
        ] = "";
        state.chedulesComponent[
          `day_end${element - 1}` as keyof ChedulesDetails
        ] = "";
      });
    },
  },
};
