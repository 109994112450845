import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { routerArray } from "./routerArray";
import { store } from "@/store";

let check = false;

/// routes array
const routes: Array<RouteRecordRaw> = routerArray;
/// create router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!check)
    store.dispatch("updateRefreshTokenAndAccessToken").finally(() => {
      check = true;
      next();
    });
  else next();
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.TokenModuleOperation.accessToken) {
      next();
      return;
    }

    next("/auth");
    return;
  }

  if (to.matched.some((record) => record.meta.guest)) {
    if (store.state.TokenModuleOperation.accessToken) {
      next("/orders");
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);

    if (
      !urlParams.get("uidb64") &&
      !urlParams.get("token") &&
      to.name === "reset"
    ) {
      next("/");
      return;
    }

    next();
    return;
  }

  return next();
});

export default router;
