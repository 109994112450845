<template>
  <div
    class="mt-5 pt-3 pb-5 d-flex a-i-center j-content-center direction-column"
  >
    <slot></slot>
    <div
      class="d-flex w-100 j-content-center btn-details-wrapper"
      v-show="!deepEqual(allInitialValues, allValues)"
    >
      <button
        class="btn btn-default m-1 submit"
        type="submit"
        @click.stop="submitAll"
      >
        {{ $tc("save_changes") }}
      </button>
      <button class="btn btn-default m-1" type="button" @click.stop="resetAll">
        {{ $tc("cancel") }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { deepEqual } from "@/utils/validation/equile";
import { computed, provide, ref } from "vue";

const forms = ref([]);
const finaly = ref([]);

provide("FINALY", (_finaly) => finaly.value.push(_finaly));
provide("ADD_FORM", (form) => forms.value.push(form));

const resetAll = () => forms.value.map((f) => f?.resetForm && f?.resetForm());

const allInitialValues = ref(
  computed(() =>
    forms.value.reduce((acc, form) => {
      if (form?.skip !== true) return { ...acc, ...form?.initialValues };
      else return acc;
    }, {})
  )
);

const allValues = ref(
  computed(() =>
    forms.value.reduce((acc, form) => {
      if (form?.skip !== true) return { ...acc, ...form?.values };
      else return acc;
    }, {})
  )
);

const submitAll = async () => {
  let first = Infinity;
  await Promise.all(
    forms.value.map((f, i) => {
      if (f?.skip !== true)
        return f?.validate()?.then((result) => {
          if (!result?.valid) if (first > i) first = i;
          result?.valid;
        });
      return true;
    })
  );

  const scroll = (selector) => {
    document?.querySelector(selector)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  switch (first) {
    case 0:
      scroll(".title-section.company-details");
      break;
    case 1:
      scroll(".title-section.bank-details");
      break;
    case 2:
      scroll(".title-section.contact-details");
      break;
    case 3:
      scroll(".delivery-addresses");
      break;
    default:
      await Promise.all(
        finaly.value.map((_f) => {
          if (_f?.finaly) _f?.finaly(allValues.value, allInitialValues.value);
        })
      );
  }
};
</script>
