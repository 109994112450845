import { IUseFetchDetailError } from "@/hook/fetch/interface";
import { IStore } from "../../types/StoreType";
import { useFetch } from "@/hook/fetch/fetch";
import { ISchedules, Schedules } from "./type";
import { Module } from "vuex";
import { store } from "@/store";
import { StockType } from "../Stock/type";
import { ChedulesDetails } from "../accountDetails/type";
import { ScheldulesOmit } from "./type";
import { computed, ref } from "vue";

export const SchedulesTypeOperation: Module<ISchedules, IStore> = {
  state: {
    count: 0,
    next: null,
    previous: null,
    results: [],
    isLoading: true,
    isError: false,
    create: false,
    select: null,
    saveSelect: null,
  },
  getters: {
    getScheludesLoading(state) {
      return state.isLoading;
    },
    getSchedulesToDay(state) {
      const day = [1, 2, 3, 4, 5, 6, 7];
      const schedulesFilter = state.results.filter((schedule) => {
        return schedule.details.filter((detail) => {
          if (day.some((element) => element === detail.day)) return detail;
        });
      });
      return schedulesFilter.flat();
    },
    getSchedulesInSelectedLocation(state) {
      const stock = store?.getters?.getStockResults as StockType[];
      if (stock.length <= 0) return;
      const schedules = state?.results?.filter((shel) =>
        stock?.some(
          (el) =>
            el?.schedule === shel?.id &&
            el?.location === store?.getters?.getSelectedSchedulesInLocation
        )
      );
      if (schedules?.length > 0) return schedules[0];
      return [];
    },
    getSchedules(state) {
      return state;
    },
    getSaveSelectAddress(state) {
      return state.saveSelect;
    },
    getSelectedSchedulesInLocation(state) {
      return state.select;
    },
    getSchedulesResult(state) {
      return state.results;
    },
  },
  mutations: {
    updateSchedulesInUpdate(
      state,
      payload: { data: Schedules; loading: boolean }
    ) {
      if (!store.getters.getSchedulesInSelectedLocation)
        store.commit("updateSelectedSchedulesInLocation", {
          id: payload.data?.id,
        });

      const filrer = state.results.filter((sh) => sh.id !== payload.data.id);
      state.results = [...filrer, payload.data];
    },
    updateSchedules(state, payload: { data: ISchedules; loading: boolean }) {
      state.count = payload?.data?.count || state.count;
      state.next = payload?.data?.next || state.next;
      state.previous = payload?.data?.previous || state.previous;
      state.isLoading = payload?.loading || state.isLoading;
      state.results = payload?.data?.results;

      if (store.getters.getStockLocationsLength > 0)
        store.commit("updateSelectedSchedulesInLocation", {
          id: store.getters.getStockLocations[0]?.id,
        });
    },
    updateSelectedSchedulesInLocation(state, payload: { id: number }) {
      if (payload.id !== -1) state.saveSelect = payload?.id;
      state.select = payload?.id;
      store.commit(
        "updateAccountSchedulesDrop",
        store.getters.getSchedulesInSelectedLocation
      );
      store.commit(
        "updateAccountSchedules",
        store.getters.getSchedulesInSelectedLocation
      );
    },
    deleteSchedulesSelected(state, payload: number) {
      const newResults = state?.results?.filter(
        (chedules) => chedules?.id !== payload
      );
      state.results = [...newResults];
    },
  },
  actions: {
    async getSchedules({ commit }) {
      const { data, isError, isLoading } = await useFetch<
        ISchedules,
        IUseFetchDetailError
      >(process.env.VUE_APP_SERVER + "api/schedules/", "GET", true);

      if (!isError.value && data.value)
        commit("updateSchedules", {
          data: data.value,
          loading: isLoading.value,
        });
    },
    async deleteSelectSchedule({ commit }, payload: number) {
      const { data, isError } = await useFetch<
        ISchedules,
        IUseFetchDetailError
      >(
        process.env.VUE_APP_SERVER + "api/schedules/" + payload + "/",
        "PATCH",
        true,
        {
          activity_status: 2,
          details: [{}],
        }
      );

      if (!isError.value && data.value)
        commit("deleteSchedulesSelected", payload);
    },
    async createSchedulesOrUpdate(
      { commit, state },
      payload: {
        values: ChedulesDetails;
        url: string;
        type: string;
        shedulesData: Schedules | undefined;
      }
    ) {
      const promiseLoop = (
        newValues: ChedulesDetails,
        shedulesData: Schedules | undefined,
        day: number
      ) => {
        return new Promise<ScheldulesOmit>((resolve) => {
          const dayStart = (shedulesData as Schedules)?.details?.filter(
            (d) => d.day === +day + 1
          )[0]?.work_time;
          const dayEnd = (shedulesData as Schedules)?.details?.filter(
            (d) => d.day === +day + 1
          )[0]?.work_end;
          resolve({
            day: day + 1,
            work_time:
              (newValues[
                `day_start${day}` as keyof ChedulesDetails
              ] as string) ||
              dayStart ||
              "0.00",
            work_end:
              (newValues[`day_end${day}` as keyof ChedulesDetails] as string) ||
              dayEnd ||
              "0.00",
          });
        });
      };
      const details: Promise<ScheldulesOmit>[] = [];
      const shedules = ref(
        computed<Schedules>(() => store.getters.getSchedulesInSelectedLocation)
      );

      for (let day = 0; day <= 6; day++) {
        if (
          ((payload.values[
            `check_day_${day + 1}` as keyof ChedulesDetails
          ] as string) &&
            (payload.values[
              `check_day_${day + 1}` as keyof ChedulesDetails
            ] as string) === "create") ||
          (((payload.values[
            `check_day_${day + 1}` as keyof ChedulesDetails
          ] as string) &&
            (payload.values[
              `check_day_${day + 1}` as keyof ChedulesDetails
            ] as string)) !== "delete" &&
            (((payload.values[
              `day_end${day}` as keyof ChedulesDetails
            ] as string) &&
              payload.values[`day_end${day}` as keyof ChedulesDetails] &&
              (
                payload.values[
                  `day_end${day}` as keyof ChedulesDetails
                ] as string
              ).length > 0) ||
              ((payload.values[
                `day_start${day}` as keyof ChedulesDetails
              ] as string) &&
                payload.values[`day_start${day}` as keyof ChedulesDetails] &&
                (
                  payload.values[
                    `day_start${day}` as keyof ChedulesDetails
                  ] as string
                ).length > 0) ||
              shedules.value?.details?.some(
                (detail) => detail?.day === day + 1
              )))
        )
          details.push(promiseLoop(payload.values, payload?.shedulesData, day));
      }

      const detail = await Promise.all(details);

      const newChedulesMap = shedules.value?.details?.map((chedules) => {
        return {
          day: chedules.day,
          work_time: chedules.work_time,
          work_end: chedules.work_end,
        };
      });

      return new Promise<Schedules>((resolve) => {
        if (JSON.stringify(newChedulesMap) === JSON.stringify(detail))
          return resolve(shedules.value);

        commit("updateGlobalErrorsChedulesComponent", "");
        useFetch<Schedules, IUseFetchDetailError>(
          payload.url,
          payload.type,
          true,
          {
            details: detail.length > 0 ? detail : [{}],
            name: "",
            description: "",
          }
        ).then(({ isError, data, isLoading, errorMessage }) => {
          if (!isError.value && data.value) {
            commit("updateAccountSchedules", data.value);
            commit("updateSchedulesInUpdate", { data: data.value, isLoading });
            resolve(data.value);
          }
          if (errorMessage.value?.detail)
            commit(
              "updateGlobalErrorsChedulesComponent",
              errorMessage.value.detail
            );
        });
      });
    },
  },
};
