import { CARS, TRUCK } from "@/store/modules/Search/type"
import { OrderDetails } from "@/store/modules/Orders/type"

export const newWindow = (data: CARS | TRUCK | OrderDetails) => {
    let length = 20
    if((data as OrderDetails)?.tyre_pcr_details)
        length = Object.keys((data as OrderDetails)?.tyre_pcr_details).length
    else if((data as OrderDetails)?.tyre_tbr_details)
        length = Object.keys((data as OrderDetails)?.tyre_tbr_details).length
    else {
            length = Object.keys(data).length
            window.open('/web/orders/' + (data as TRUCK)
                ?.classificator
                .toString()
                .toLocaleLowerCase() + '/' + 
                (data as  TRUCK)?.id,
                '_blank',
                `location=no,height=${length * 30},width=800,scrollbars=yes,status=no,toolbar=no,menubar=no`)
    }
}