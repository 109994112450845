export const debounce = (
  fn: any,
  ms = 300,
  callback?: (target: HTMLInputElement) => void
) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: any[]) {
    if(args[0]?.target?.type === 'number')     
    if(args[0]?.target?.value?.length > 5)
        args[0].target.value = args[0]?.target?.value?.substring(0, 5);

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => fn.apply(this, [...args, callback]), ms);

    return;
  };
};
