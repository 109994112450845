import {
  CarsTypeFilter,
  TruckTypeFilter,
} from "@/store/modules/newOrders/type";
import { store } from "@/store";

let urlCarsOld = process.env.VUE_APP_SERVER + "api/" + "?";
let urlTrucksOld = process.env.VUE_APP_SERVER + "api/" + "?";

const asyncLoop = (key: string, value: string) =>
  new Promise((resolve: (value: string) => void) => {
    if (!value || value === "-1") resolve("");
    if (value === "No") resolve(key + "=" + "False");
    if (key === "product__tbr__season") resolve("");
    if (key === "product__pcr__season")
      resolve(value ? `product__pcr__season=${Number(value) - 1}` : "");
    if (value === "Yes") resolve(key + "=" + "True");
    value = value.toString();
    if (value.length > 0) resolve(key + "=" + value);
    else resolve("");
  });

export const getDataSearch = async (
  values: CarsTypeFilter | TruckTypeFilter | any,
  update = false
) => {
  let urlCarsNew =
    process.env.VUE_APP_SERVER + "/api/search?classificator=pcr&";
  let urlTrucksNew =
    process.env.VUE_APP_SERVER + "/api/search?classificator=tbr&";

  const promisesFilter: string[] = [];
  const getValue = store.getters.getSelectFilter;

  let key: keyof typeof values;

  for (key in values) {
    promisesFilter.push(await asyncLoop(key, values[key]));
  }
  Promise.all(promisesFilter).then(async (result) => {
    result = result.filter((str) => str !== "");
    if (getValue === "Cars") {
      urlCarsNew =
        process.env.VUE_APP_SERVER +
        "api/search?classificator=pcr&" +
        result.join("&");

      if (urlCarsOld !== urlCarsNew || update) {
        await store.dispatch("GetCars", { url: urlCarsNew });
        urlCarsOld = urlCarsNew;
      }
    }

    if (getValue === "Truck") {
      urlTrucksNew =
        process.env.VUE_APP_SERVER +
        "api/search?classificator=tbr&" +
        result.join("&");

      if (urlTrucksOld !== urlTrucksNew || update) {
        await store.dispatch("GetTruck", { url: urlTrucksNew });
        urlTrucksOld = urlTrucksNew;
      }
    }
  });
};
