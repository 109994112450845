import { ComponentTypeOperation } from './type'
import { IStore } from '../../types/StoreType'
import { Module } from "vuex"

export const ComponentsTypeOperation: Module<ComponentTypeOperation, IStore> = {
    state: {
        component: 'login_component'
    },
    mutations: {
        updateComponent(state, payload: string) {
            state.component = payload
        },
    }
}
